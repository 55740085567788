import React from 'react';
import { useEffectOnce } from '@fdha/common-hooks';
import { Stack } from '@mui/material';
import { DefaultBasePage } from '@components';
import { useNotifications } from '@hooks';

import CourseSection from './CourseSection';
import ArticleSection from './ArticleSection';
import RecipeSection from './RecipeSection';

const Learning = () => {
  const { clearNotification } = useNotifications();
  useEffectOnce(() => {
    clearNotification('learning');
  });

  return (
    <DefaultBasePage
      i18nKeyTitle="learning:title"
      title="Learning"
      contentSize="large"
    >
      <Stack spacing={5}>
        <CourseSection />
        <ArticleSection />
        <RecipeSection />
      </Stack>
    </DefaultBasePage>
  );
};

export default Learning;
