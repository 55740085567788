import {
  DetailedLearningModule,
  LearningItem,
  LearningQuiz,
} from '@fdha/graphql-api-patient';
import { uniqBy } from 'lodash';

type itemType = 'content' | 'quiz';

interface BaseProps {
  id: string;
  type: itemType;
  canGoBack: boolean;
}

interface ContentItem extends BaseProps {
  type: 'content';
  data: LearningItem;
}

interface QuizItem extends BaseProps {
  type: 'quiz';
  data: LearningQuiz;
  itemId?: string;
}

export type CourseItem = QuizItem | ContentItem;

export const buildCourseItems = (data: DetailedLearningModule) => {
  const items: CourseItem[] = [];

  data.learningItems.forEach((item: LearningItem) => {
    // Content
    items.push({
      type: 'content',
      id: item.id,
      data: item,
      canGoBack: true,
    });

    // Intermediate quiz
    if (item.quiz) {
      items.push({
        type: 'quiz',
        id: item.quiz.id,
        data: item.quiz,
        itemId: item.id,
        canGoBack: false,
      });
    }
  });

  // Final quiz
  if (data.quiz) {
    items.push({
      type: 'quiz',
      id: data.quiz.id,
      data: data.quiz,
      canGoBack: false,
    });
  }

  return uniqBy(items, 'id');
};
