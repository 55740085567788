import { UserType } from '@fdha/web-ui-library';
import { useFeatureFlag } from '@fdha/common-hooks';
import {
  useGetCommunityUserQuery,
  useGetProfileQuery,
} from '@fdha/graphql-api-patient';

import { useNotifications } from './useNotifications';

interface MainRoute {
  value: string;
  label: string;
  i18nKeyLabel?: string;
  to: string;
  activeIcon: string;
  inactiveIcon: string;
  'data-testid': string;
  notificationCount?: number;
}

export const useGetMainRoutes = () => {
  const { isFeatureEnabled } = useFeatureFlag();
  const { count } = useNotifications();

  const { data: userData } = useGetProfileQuery();
  const { data: communityUserData } = useGetCommunityUserQuery();

  const showCommunity = isFeatureEnabled('show_community');
  const showCommunityWebPatient = isFeatureEnabled(
    'show_community_web_patient'
  );

  const shouldShowCommunity = showCommunity && showCommunityWebPatient;

  const showFoodOps = isFeatureEnabled('show_food_ops');
  const showExplore = isFeatureEnabled('show_explore');
  const showIntercom = isFeatureEnabled('show_intercom');

  const mealsType =
    userData?.me.type === UserType.ClinicalTrialPatient && showFoodOps
      ? 'CT-Meals'
      : userData?.me.type === UserType.ClinicalTrialPatient && !showFoodOps
      ? undefined
      : 'DH-Meals';

  const mainRoutes: MainRoute[] = [
    {
      value: 'surveys',
      label: 'Surveys',
      i18nKeyLabel: 'home:card.surveys.label',
      to: '/surveys',
      activeIcon: 'pie-chart',
      inactiveIcon: 'pie-chart-outline',
      'data-testid': 'SURVEYS_PAGE',
    },
    ...(!showIntercom
      ? [
          {
            value: 'chat',
            label: 'Chat',
            i18nKeyLabel: 'home:card.chat.label',
            to: '/chat',
            activeIcon: 'message-circle',
            inactiveIcon: 'message-circle-outline',
            'data-testid': 'CHAT_PAGE',
            notificationCount: count.chat,
          },
        ]
      : []),
    {
      value: 'activities',
      label: 'Activities',
      i18nKeyLabel: 'home:card.activities.label',
      to: '/activities',
      activeIcon: 'calendar',
      inactiveIcon: 'calendar-outline',
      'data-testid': 'ACTIVITIES_PAGE',
    },
    ...(showCommunity
      ? [
          ...(shouldShowCommunity
            ? [
                {
                  value: 'community',
                  label: 'Community',
                  i18nKeyLabel: 'home:card.community.label',
                  to: communityUserData?.getCommunityUser
                    ? '/community'
                    : '/community-agreement',
                  activeIcon: 'people',
                  inactiveIcon: 'people-outline',
                  'data-testid': 'COMMUNITY_PAGE',
                  notificationCount: count.community,
                },
              ]
            : [
                {
                  value: 'community',
                  label: 'Community',
                  i18nKeyLabel: 'home:card.community.label',
                  to: '/community-unavailable',
                  activeIcon: 'people',
                  inactiveIcon: 'people-outline',
                  'data-testid': 'COMMUNITY_PAGE',
                },
              ]),
        ]
      : []),
    {
      value: 'learning',
      label: 'Learning',
      i18nKeyLabel: 'home:card.learning.label',
      to: showExplore ? '/learning' : '/courses',
      activeIcon: 'book-open',
      inactiveIcon: 'book-open-outline',
      'data-testid': 'LEARNING_PAGE',
      notificationCount: count.learning,
    },
    ...(mealsType === 'CT-Meals'
      ? [
          {
            value: 'meals',
            label: 'Meals',
            i18nKeyLabel: 'home:card.meals.label',
            to: '/meals',
            activeIcon: 'clipboard',
            inactiveIcon: 'clipboard-outline',
            'data-testid': 'MEALS_PAGE',
          },
        ]
      : mealsType === 'DH-Meals'
      ? [
          {
            value: 'meals',
            label: 'Meals',
            i18nKeyLabel: 'home:card.meals.label',
            to: '/advertising-meals',
            activeIcon: 'clipboard',
            inactiveIcon: 'clipboard-outline',
            'data-testid': 'ADVERTISING_MEALS_PAGE',
          },
        ]
      : []),
  ];

  return { mainRoutes };
};
