import React, { useEffect, useMemo, useState } from 'react';
import { DataEmptyState, DefaultBasePage } from '@components';
import {
  DataCard,
  Graph,
  Icon,
  SkeletonWrapper,
  ToggleButton,
  Typography,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { Box, Card, Divider, Stack, useTheme } from '@mui/material';
import { useLocation } from 'react-router';
import {
  useGetLastAssayResultsQuery,
  useListAminoAcidsAssaysResultsQuery,
} from '@fdha/graphql-api-patient';
import { useApolloClient } from '@apollo/client';
import { getTooltipColor } from '@utils';
import { useTranslation } from '@fdha/common-hooks';

interface StateProps {
  type: string;
}

const options = [
  {
    label: 'Glycine',
    value: 'glycine',
    i18nKey: 'dataVisualization:aa.aminoAcids.glycine',
  },
  {
    label: 'Proline',
    value: 'proline',
    i18nKey: 'dataVisualization:aa.aminoAcids.proline',
  },
  {
    label: 'Serine',
    value: 'serine',
    i18nKey: 'dataVisualization:aa.aminoAcids.serine',
  },
];

const GLYCINE_INDICATOR_ID = '2';

const TrialData = () => {
  const theme = useTheme();
  const location = useLocation();
  const client = useApolloClient();
  const { translate } = useTranslation();

  const state = location.state as StateProps;

  const [selectedAcid, setSelectedAcid] = useState(state?.type ?? 'glycine');

  const { data: lastAssayResultData, loading: loadingLastAssaysResult } =
    useGetLastAssayResultsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const aminoacidsResults = useMemo(
    () => lastAssayResultData?.lastAssayResults || [],
    [lastAssayResultData?.lastAssayResults]
  );

  const currentData = aminoacidsResults.find(
    (assay) => assay.name.toLowerCase() === selectedAcid
  );

  const { data: listAssaysData, loading: loadingListAssays } =
    useListAminoAcidsAssaysResultsQuery({
      variables: {
        indicatorId: currentData?.indicatorId || GLYCINE_INDICATOR_ID,
      },
    });

  const assaysList = useMemo(
    () => listAssaysData?.getAssays.edges.map((item) => item.node) || [],
    [listAssaysData]
  );

  const isLoading = loadingListAssays || loadingLastAssaysResult;
  const showEmptyState =
    (assaysList.length === 0 || !currentData) && !isLoading;
  const baselineDate = assaysList[0]?.eventDate;
  const targetValue = currentData
    ? currentData.baseline - (currentData.baseline * currentData.target) / 100
    : 0;

  const graphData = useMemo(
    () =>
      assaysList.map((item) => {
        const date = formatUTCDate(item.eventDate, 'formalMonthDay') || '';
        return {
          x: translate('dataVisualization:graphDate', date, {
            date: item.eventDate,
          }),
          y: item.value.toFixed(2),
          color: getTooltipColor(item.value, targetValue),
        };
      }),
    [assaysList, targetValue, translate]
  );

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'getAssays',
      });
    };
  }, [client.cache]);

  const renderSubtitle = (
    text: string,
    color: string,
    i18nKey?: string,
    i18nParams?: { [key: string]: string }
  ) => (
    <Stack spacing={1} direction="row" alignItems="center">
      <SkeletonWrapper isLoading={isLoading}>
        <Box
          width={theme.spacing(4)}
          height="5px"
          borderRadius="5px"
          sx={{ backgroundColor: color }}
        />
        <Typography
          variant="caption"
          color={theme.palette.text.secondary}
          i18nKey={i18nKey}
          i18nParams={i18nParams}
        >
          {text}
        </Typography>
      </SkeletonWrapper>
    </Stack>
  );

  return (
    <DefaultBasePage
      i18nKeyTitle="home:trial.title"
      title="Trial data"
      contentSize="small"
    >
      <Stack spacing={3}>
        <Typography variant="body1" i18nKey="dataVisualization:aa.subtitle">
          The purpose of this study is to see whether lowering glycine, proline,
          and serine levels can slow tumor growth.
        </Typography>
        {showEmptyState ? (
          <DataEmptyState />
        ) : (
          <>
            <ToggleButton
              selectedValue={selectedAcid}
              setValue={setSelectedAcid}
              options={options}
            />
            <Stack spacing={2}>
              <Card
                sx={{
                  py: 1.5,
                  px: 2.5,
                  backgroundColor: theme.palette.primary.background,
                  ':hover': {
                    backgroundColor: theme.palette.primary.background,
                  },
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  paddingY={0.75}
                  paddingX={1.25}
                >
                  <Icon
                    name="radio-button-on-outline"
                    size="xlarge"
                    fill={theme.palette.primary.main}
                  />
                  <Typography
                    variant="h6"
                    i18nKey={`dataVisualization:aa.targetDescription.trial.${selectedAcid}`}
                    i18nParams={{ percentage: currentData?.target || 0 }}
                  >
                    {`The target is to get your <strong>${selectedAcid}</strong> level to
                    <strong>at least {{ percentage }}% below</strong> your baseline
                    levels.`}
                  </Typography>
                </Stack>
              </Card>
              {!isLoading && assaysList.length === 0 ? (
                <DataEmptyState type="partial" />
              ) : (
                <>
                  <DataCard
                    data={currentData}
                    baselineDate={baselineDate}
                    targetValue={targetValue}
                    showSkeleton={isLoading}
                  />
                  <Graph
                    showCard
                    data={graphData}
                    baseline={currentData?.baseline || 0}
                    target={targetValue}
                    showSkeleton={isLoading}
                  />
                </>
              )}
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center">
              {renderSubtitle(
                `Target ({{ number }})`,
                '#3CA130',
                'dataVisualization:aa.targetNumber',
                { number: targetValue.toFixed(2) }
              )}
              {renderSubtitle(
                `Baseline ({{ number }})`,
                '#B72D3B',
                'dataVisualization:aa.baselineNumber',
                { number: currentData?.baseline?.toFixed(2) || '' }
              )}
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <Typography
                color={theme.palette.text.secondary}
                fontWeight="bold"
                i18nKey="dataVisualization:aa.notes.title"
              >
                Notes:
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                lineHeight={2}
                i18nKey={`dataVisualization:aa.notes.noteText.${selectedAcid}`}
              >
                {`Speak to your dietitian about your ${selectedAcid} levels and how
                to keep these low. Let us know any questions! Thanks for your
                patience with us.`}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </DefaultBasePage>
  );
};

export default TrialData;
