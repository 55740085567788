import React from 'react';
import { DefaultBasePage } from '@components';
import { useLocation } from 'react-router-dom';
import { useGetRecipeQuery } from '@fdha/graphql-api-patient';
import {
  RTEContentRender,
  Typography,
  Video,
  RecipeAdditionalInformationV2,
} from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import { Document } from '@contentful/rich-text-types';

import LoadingState from './LoadingState';

const Recipe = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const recipeId = params.get('id') || '';

  const { data, loading } = useGetRecipeQuery({
    variables: { recipeId },
  });

  const recipe = data?.recipe;
  const description =
    recipe?.recipeDescription && JSON.parse(recipe.recipeDescription);
  const ingredients = recipe?.ingredients && JSON.parse(recipe.ingredients);
  const instructions = recipe?.instructions && JSON.parse(recipe.instructions);

  const renderSection = (title: string, content: Document) => {
    return (
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          i18nKey={`learning:recipe.${title.toLowerCase()}Title`}
        >
          {title}
        </Typography>
        <RTEContentRender richTextDocument={content} />
      </Box>
    );
  };

  return (
    <DefaultBasePage
      contentSize="small"
      currentRouteName={{
        text: 'Recipe',
        i18nKey: 'learning:recipe.pageTitle',
      }}
      title={recipe?.recipeTitle}
      isLoading={loading}
    >
      {!loading ? (
        <Stack spacing={4}>
          {recipe?.video ? (
            <Video
              url={recipe.video}
              poster={recipe.coverImage}
              style={{ maxHeight: '360px' }}
            />
          ) : (
            <img
              src={recipe?.coverImage}
              alt={recipe?.recipeTitle || 'Recipe cover'}
              width="100%"
              style={{ objectFit: 'cover', maxHeight: '360px' }}
            />
          )}
          <RTEContentRender richTextDocument={description} />
          <RecipeAdditionalInformationV2
            type={recipe?.recipeType}
            servings={recipe?.servings}
            i18nKeyType={`learning:recipe.type.${recipe?.recipeType?.toLowerCase()}`}
          />
          {renderSection('Ingredients', ingredients)}
          {renderSection('Instructions', instructions)}
        </Stack>
      ) : (
        <LoadingState />
      )}
    </DefaultBasePage>
  );
};

export default Recipe;
