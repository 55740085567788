import React from 'react';
import { Avatar } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { AvatarProps } from 'stream-chat-react';
import { displayProtectedImage } from '@fdha/web-auth';

const MessageAvatar = (props: AvatarProps) => {
  const { image, name, size = 32 } = props;
  const sx = { flexBasis: size, height: size, width: size };

  return (
    <Box className="str-chat__avatar" sx={sx}>
      <Avatar
        data-testid="MESSAGE_AVATAR"
        name={name}
        picture={image}
        size={size}
        downloadPicture={displayProtectedImage}
      />
    </Box>
  );
};

export default MessageAvatar;
