import {
  ContentExplanation,
  ContentExplanationType,
  Maybe,
  ScheduledItemType,
  SurveyFrequency,
  SurveyInstance,
  SurveyQuestionType,
  WeekDay,
} from '@fdha/graphql-api-patient';
import { QuestionProps } from '@models';
import { getWelcomeSurveyQuestions } from '@utils';
import { format, utcToZonedTime } from 'date-fns-tz';

import { getBHBQuestion } from './dataSurveyUtils';

interface RecurrenceOpts {
  frequency?: SurveyFrequency;
  dueAt?: string;
  weekDays?: WeekDay[];
}

type SurveyViewType = 'explanation' | 'question' | 'confirmation';

export type ExplanationViewProps = {
  id: string;
  title: string;
  i18nKeyTitle?: string;
  headerAsset: string;
  content?: Maybe<ContentExplanation>[];
  i18nKeyContent?: string[];
};

type QuestionViewProps = QuestionProps;

type ConfirmationViewProps = { id: string };

interface SurveyViewBase {
  type: SurveyViewType;
  showTitle: boolean;
  showDate: boolean;
  showProgress: boolean;
  hideProgressLabel?: boolean;
}

interface SurveyViewExplanation extends SurveyViewBase {
  type: 'explanation';
  viewProps: ExplanationViewProps;
}

export interface SurveyViewQuestion extends SurveyViewBase {
  type: 'question';
  viewProps: QuestionViewProps;
}

interface SurveyViewConfirmation extends SurveyViewBase {
  type: 'confirmation';
  viewProps: ConfirmationViewProps;
}

export type SurveyView =
  | SurveyViewExplanation
  | SurveyViewQuestion
  | SurveyViewConfirmation;

export const getRecurrenceText = (
  { frequency, dueAt = '', weekDays = [] }: RecurrenceOpts,
  translate: (
    key: string,
    defaultValue: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  if (!frequency) {
    return { i18nKey: '', i18nParams: undefined, formattedString: '' };
  }

  const timestamp = new Date(Number(dueAt));

  const date = format(utcToZonedTime(timestamp, 'UTC'), 'MMM dd');
  const day = format(utcToZonedTime(timestamp, 'UTC'), 'd');
  const hour = format(utcToZonedTime(timestamp, 'UTC'), 'hh:mm a');

  const getFormattedWeekDays = () => {
    return weekDays
      .map((d) =>
        translate(
          `common:weekDays.${d.toLocaleLowerCase()}`,
          d.toLocaleLowerCase()
        )
      )
      .join(', ');
  };

  switch (frequency) {
    case SurveyFrequency.Once:
      if (dueAt) {
        return {
          i18nKey: 'surveys:recurrence.onceWithDate',
          i18nParams: { date: timestamp, hour },
          formattedString: `${frequency} on ${date} at ${hour}`,
        };
      }
      // Welcome survey must not have a due_at value
      return {
        i18nKey: 'surveys:recurrence.once',
        i18nParams: undefined,
        formattedString: frequency,
      };
    case SurveyFrequency.Daily:
      return {
        i18nKey: 'surveys:recurrence.daily',
        i18nParams: { hour },
        formattedString: `${frequency} at ${hour}`,
      };
    case SurveyFrequency.Weekly:
    case SurveyFrequency.Biweekly:
    case SurveyFrequency.Triweekly:
      return {
        i18nKey: 'surveys:recurrence.' + frequency.toLowerCase(),
        i18nParams: { weekDays: getFormattedWeekDays(), hour },
        formattedString: `${frequency} on ${getFormattedWeekDays()} at ${hour}`,
      };
    case SurveyFrequency.Monthly:
      return {
        i18nKey: 'surveys:recurrence.monthly',
        i18nParams: { day: timestamp, hour },
        formattedString: `${frequency} on ${day} at ${hour}`,
      };
    default:
      return { i18nKey: '', i18nParams: undefined, formattedString: '' };
  }
};

const mapPossibleAnswers = (possibleAnswers?: string[] | null) => {
  return possibleAnswers?.map((option) => ({
    value: option,
    label: option,
  }));
};

export const buildWelcomeSurveyViews = () => {
  const views: SurveyView[] = [];

  const explanationView: SurveyViewExplanation = {
    type: 'explanation',
    showTitle: false,
    showDate: false,
    showProgress: false,
    viewProps: {
      id: 'welcome',
      title: 'Welcome to the Faeth Community',
      i18nKeyTitle: 'surveys:welcomeSurvey.title',
      headerAsset:
        'https://images.ctfassets.net/c7plqsk7h3c4/60uhzahCp7jbwvAMQqs7V3/921c074bf2d7fa416c7ddbb54b92545a/Welcome_Survey_Cover.png',
      content: [
        {
          type: ContentExplanationType.Text,
          data: 'You will be assigned to one of Faeth’s registered dietitians who will be your coach on your cancer journey. They will guide and empower you. You can think of them as your first stop when you have a question about your care.',
          i18nkey: 'surveys:welcomeSurvey.content1',
        },
        {
          type: ContentExplanationType.Text,
          data: 'Let’s get to know each other! We want to schedule a meeting so you and your coach can meet, but before that we need to know a few things about you!',
          i18nkey: 'surveys:welcomeSurvey.content2',
        },
        {
          type: ContentExplanationType.Text,
          data: 'Please answer the next questions.',
          i18nkey: 'surveys:welcomeSurvey.content3',
        },
      ],
    },
  };

  views.push(explanationView);

  const questions = getWelcomeSurveyQuestions();
  questions.forEach((question) => {
    const view: SurveyView = {
      type: 'question',
      showTitle: true,
      showDate: false,
      showProgress: true,
      viewProps: question,
    };

    views.push(view);
  });

  views.push({
    type: 'confirmation',
    showTitle: true,
    showDate: true,
    showProgress: true,
    viewProps: { id: 'confirmation' },
  });

  return views;
};

export const buildSurveyViews = (
  survey: SurveyInstance
): SurveyView[] | undefined => {
  let views: SurveyView[] = [];

  if (survey.instanceType === ScheduledItemType.Bhb) {
    views.push({
      type: 'question',
      showTitle: true,
      showDate: true,
      showProgress: true,
      hideProgressLabel: true,
      viewProps: getBHBQuestion(survey.due_at),
    });
  }

  survey.questions?.forEach((question) => {
    if (question.type === SurveyQuestionType.Explanation) {
      views.push({
        type: 'explanation',
        showTitle: false,
        showDate: false,
        showProgress: false,
        viewProps: {
          id: question.id,
          title: question.title,
          headerAsset:
            (question?.customPropsOfType as ExplanationViewProps)
              ?.headerAsset || '',
          content:
            (question?.customPropsOfType as ExplanationViewProps)?.content ||
            [],
        },
      });
    } else {
      views.push({
        type: 'question',
        showTitle: true,
        showDate: true,
        showProgress: true,
        viewProps: {
          ...question,
          subtitle: question.helpText,
          placeholder: question.placeholderText || '',
          options: mapPossibleAnswers(question.possibleAnswers),
        } as any,
      });
    }
  });

  if (survey.requires_confirmation) {
    views.push({
      type: 'confirmation',
      showTitle: true,
      showDate: true,
      showProgress: true,
      viewProps: { id: 'confirmation' },
    });
  }

  return views;
};
