import React, { useState } from 'react';
import { ImageBasePage } from '@components';
import { Button, Typography, useSnackbar } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  MainGoalType,
  useUpdateProfileMutation,
} from '@fdha/graphql-api-patient';

import GoalCardGroup from './GoalCardGroup';

const MainGoal = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const [radioValue, setRadioValue] = useState('');
  const [updateProfileMutation, { loading }] = useUpdateProfileMutation();
  const handleClick = (value: string) => {
    setRadioValue(value);
  };

  const options = [
    {
      label: '1-on-1 support from a Coach',
      i18nKey: 'mission:mainGoal.options.1on1',
      icon: 'message-circle-outline',
      value: 'support',
    },
    {
      label: 'Learn about my condition',
      i18nKey: 'mission:mainGoal.options.learn',
      icon: 'book-open-outline',
      value: 'learn',
    },
    {
      label: 'Connect with other patients',
      i18nKey: 'mission:mainGoal.options.connect',
      icon: 'people-outline',
      value: 'connect',
    },
    {
      label: 'Track my health routine',
      i18nKey: 'mission:mainGoal.options.track',
      icon: 'calendar-outline',
      value: 'track',
    },
  ];

  const handleGetStarted = async () => {
    await updateGoal();
    navigate('/', { replace: true });
  };

  const updateGoal = async () => {
    try {
      await updateProfileMutation({
        variables: { props: { main_goal: radioValue as MainGoalType } },
      });
    } catch (e) {
      showSnackbarV2({
        severity: 'error',
        message: 'Error to save main goal',
        i18nKey: 'mission:mainGoal.snackbar.error',
      });
    }
  };

  return (
    <ImageBasePage
      image={{ url: '/images/food_08.png', altText: 'Food' }}
      title={
        <Typography
          variant="h5"
          fontWeight="bold"
          mb={1}
          color={theme.palette.text.secondary}
          i18nKey="mission:mainGoal.title"
        >
          We are committed to your fight.
        </Typography>
      }
      subtitle={
        <Stack spacing={1}>
          <Typography i18nKey="mission:mainGoal.subtitle" variant="h4">
            What do you want to achieve?
          </Typography>
          <Typography i18nKey="mission:mainGoal.description" variant="h5">
            Select your main goal so that we can support you as best as
            possible.
          </Typography>
        </Stack>
      }
      alignHeader="flex-start"
      imagePosition="right"
      contentSize="small"
    >
      <Stack direction="column" spacing={4}>
        <GoalCardGroup
          options={options}
          selectedValue={radioValue}
          onClick={handleClick}
        />
        <Box alignSelf="center">
          <Button
            i18nKey="mission:mainGoal.getStarted"
            variant="contained"
            sx={{ px: '48px' }}
            onClick={handleGetStarted}
            disabled={!radioValue || loading}
          >
            Get Started
          </Button>
        </Box>
      </Stack>
    </ImageBasePage>
  );
};

export default MainGoal;
