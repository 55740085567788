import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { apolloClientConfig } from '@config';
import fdhaConfig from '@fdha/aws-config-patient';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import {
  DialogProvider,
  ErrorFallback,
  SnackbarProvider,
  themeV2,
} from '@fdha/web-ui-library';
import { BrowserRouter } from 'react-router-dom';
import {
  TranslationProvider,
  SystemSettingsProvider,
} from '@fdha/common-hooks';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import i18n from './i18n';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: fdhaConfig.cognito.patientUserPoolId,
      userPoolClientId: fdhaConfig.cognito.patientUserPoolClientId,
    },
  },
});

const apolloClient = new ApolloClient(apolloClientConfig);

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container);

root.render(
  <SystemSettingsProvider>
    <TranslationProvider i18n={i18n}>
      <ThemeProvider theme={themeV2}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ApolloProvider client={apolloClient}>
            <SnackbarProvider>
              <DialogProvider>
                <CssBaseline />
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </DialogProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </TranslationProvider>
  </SystemSettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
