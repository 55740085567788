import React from 'react';
import {
  useGetProfileQuery,
  useGetArticleTokenLazyQuery,
} from '@fdha/graphql-api-patient';
import fdhaConfig from '@fdha/aws-config-patient';
import { useAnalytics } from '@fdha/common-hooks';

import LearningCard from './LearningCard';

interface ArticleCardProps {
  title: string;
  createdBy: string;
  coverImage: string;
  id: string;
  url?: string;
}

const baseUrl = fdhaConfig.api.commonApi;

const ArticleCard: React.FC<ArticleCardProps> = ({
  title,
  createdBy,
  coverImage,
  id,
  url,
}) => {
  const { data: patientData } = useGetProfileQuery();
  const { analyticsClient } = useAnalytics();

  const [getArticleToken] = useGetArticleTokenLazyQuery({
    onCompleted: (data) => redirectToUrl(data.articleToken),
    fetchPolicy: 'no-cache',
  });

  const redirectToUrl = (articletoken?: string) => {
    const articleUrl = `${baseUrl}/article?id=${id}&patientId=${patientData?.me.id}&token=${articletoken}&source=Web`;
    window.open(articleUrl, '_blank');
    handleTrackEvents();
  };

  const handleClick = () => {
    if (url) {
      window.open(url, '_blank');
      handleTrackEvents();
    } else {
      getArticleToken();
    }
  };

  const handleTrackEvents = () => {
    analyticsClient?.track('Explore Article Opened', {
      id: id,
      name: title,
    });
  };

  return (
    <LearningCard
      type="article"
      title={title}
      subtitle={`by ${createdBy}`}
      coverImage={coverImage}
      i18nKey="learning:article.byCoach"
      i18nParams={{ coach: createdBy }}
      onClick={handleClick}
    />
  );
};

export default ArticleCard;
