import React from 'react';
import {
  ChannelList as ChannelListStreamChat,
  ChannelListProps,
} from 'stream-chat-react';

import ChannelPreview from '../channelPreview/ChannelPreview';

import ChannelList from './ChannelList';

const ChannelListContainer: React.FC<ChannelListProps> = (props) => {
  return (
    <ChannelListStreamChat
      {...props}
      List={ChannelList}
      Preview={(previewProps) => (
        <ChannelPreview
          {...previewProps}
          customActiveChannel={props.customActiveChannel}
        />
      )}
    />
  );
};

export default ChannelListContainer;
