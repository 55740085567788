import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// NOTE: When removing it, please also remove
// the if "if (!pathname.match('/.*/$'))" on private.routes.tsx
export const useRemoveTrailingSlash = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.match('/.*/$')) {
      navigate(pathname.slice(0, -1));
    }
  }, [pathname, navigate]);
};
