import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { Icon, Typography } from '..';
import { VariantType } from '../..';

export interface DataVariationProps {
  variation: number;
  target: number;
  variant?: VariantType;
}

export const DataVariation: FC<DataVariationProps> = ({
  variation,
  target,
  variant = 'body1',
}) => {
  const getIcon = () => {
    return {
      color: variation <= -target ? '#3CA130' : '#B72D3B',
      name: variation >= 0 ? 'trending-up-outline' : 'trending-down-outline',
    };
  };

  const getVariationSignal = () => {
    return variation >= 0 ? '+' : '';
  };

  const icon = getIcon();

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Icon align-self="auto" size="large" name={icon.name} fill={icon.color} />
      <Typography variant={variant} color={icon.color}>
        {getVariationSignal()}
        {variation.toFixed(0)}%
      </Typography>
    </Stack>
  );
};
