import { Button, Typography, MenuOptions } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  getQuantitySelected,
  isFull,
  getSectionTypeLabel,
} from '@fdha/common-utils';

interface FooterButtonsProps {
  amount?: number;
  snackAmount?: number;
  values?: MenuOptions;
  disabled: boolean;
  hasSides?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
}

export const FooterButtons: FC<FooterButtonsProps> = ({
  amount = 0,
  snackAmount = 0,
  values,
  disabled,
  hasSides = false,
  onCancel,
  onSubmit,
}) => {
  const theme = useTheme();

  const entreeDishes = values?.entreeDishes || [];
  const snacks = values?.snacks || [];

  const [isBottomScrolled, setIsBottomScrolled] = useState(false);

  const isFullDishes = isFull(entreeDishes, amount);
  const isFullSnacks = isFull(snacks, snackAmount);

  const formattedQuantityText = `<strong>${getQuantitySelected(
    entreeDishes
  )}/${amount}</strong> ${getSectionTypeLabel('entreeDishes', hasSides)}${
    snackAmount > 0 &&
    `, <strong>${getQuantitySelected(
      snacks
    )}/${snackAmount}</strong> ${getSectionTypeLabel('snacks', hasSides)}`
  }`;

  const i18nKeyQuantityText = hasSides
    ? 'meals:editDelivery.footer.amounts.snacksSides'
    : 'meals:editDelivery.footer.amounts.snacks';

  useEffect(() => {
    const element = document.getElementById('basePage');

    const handleScroll = (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      setIsBottomScrolled(scrollHeight - scrollTop - clientHeight === 0);
    };

    element?.addEventListener('scroll', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      p={3}
      sx={{
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: isBottomScrolled ? 'unset' : theme.shadows[4],
      }}
      position="sticky"
      bottom="0"
    >
      {!(isFullDishes && isFullSnacks) ? (
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          i18nKey={i18nKeyQuantityText}
          i18nParams={{
            selectedEntrees: getQuantitySelected(entreeDishes),
            totalEntrees: amount,
            selectedSnacks: getQuantitySelected(snacks),
            totalSnacks: snackAmount,
          }}
        >
          {formattedQuantityText}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          i18nKey="meals:editDelivery.footer.total"
        >
          You have selected all of your options
        </Typography>
      )}
      <Stack spacing={4} direction="row">
        <Button onClick={onCancel} i18nKey="common:button.cancel">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disabled}
          onClick={onSubmit}
          i18nKey="meals:editDelivery.footer.button"
        >
          Save Selection
        </Button>
      </Stack>
    </Box>
  );
};
