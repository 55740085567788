import { Typography, formatUTCDate } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface BHBSurveyDialogProps {
  values: { bhb: { value: string; time: string; dayPeriod: 'am' | 'pm' } };
  date: string;
}

const BHBSurveyDialog: FC<BHBSurveyDialogProps> = ({ values, date }) => {
  const theme = useTheme();

  const { value, time, dayPeriod } = values.bhb;
  const color = theme.palette.text.secondary;
  const isoDate = new Date(Number(date)).toISOString();

  return (
    <Stack spacing={3}>
      <Box>
        <Typography
          data-testid="BHB_LABEL_DIALOG"
          color={color}
          i18nKey="surveys:bhbSurvey.confirmationDialog.value"
        >
          BHB value:
        </Typography>
        <Typography
          data-testid="BHB_VALUE_DIALOG"
          color={color}
          fontWeight="bold"
        >
          {`${value} mmol/L`}
        </Typography>
      </Box>
      <Box>
        <Typography
          data-testid="TAKEN_LABEL"
          color={color}
          i18nKey="surveys:bhbSurvey.confirmationDialog.takenAt"
        >
          Taken at:
        </Typography>
        <Typography
          data-testid="TAKEN_VALUE"
          color={color}
          fontWeight="bold"
          i18nKey="surveys:bhbSurvey.confirmationDialog.takenAtDate"
          i18nParams={{
            hour: `${time} ${dayPeriod.toUpperCase()}`,
            date: isoDate,
          }}
        >
          {`${time} ${dayPeriod.toUpperCase()},
          ${formatUTCDate(isoDate, 'monthDayYear')}`}
        </Typography>
      </Box>
    </Stack>
  );
};

export default BHBSurveyDialog;
