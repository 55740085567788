import React from 'react';
import { Box, Card, CardActionArea, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Avatar, Icon, Typography } from '@fdha/web-ui-library';
import { displayProtectedImage } from '@fdha/web-auth';

interface ProfileCardProps {
  id: string;
  text: string;
  handleClick: () => void;
  rightIcon?: string;
  leftIcon?: string;
  picture?: string | null;
  userName?: string;
  showLeftPicture?: boolean;
  i18nKey?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  id,
  text,
  handleClick,
  leftIcon,
  picture,
  userName,
  showLeftPicture,
  rightIcon = 'arrow-ios-forward-outline',
  i18nKey,
}) => {
  const theme = useTheme();

  const isLogout = id === 'logout';

  return (
    <Card
      sx={{
        border: `border: 0.5px solid ${grey[700]}`,
        boxShadow: theme.shadows[2],
      }}
    >
      <CardActionArea
        data-testid={`${text.toUpperCase()}_PROFILE_CARD`}
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          px: 2,
          py: isLogout ? '14px' : picture ? '10px' : 2,
        }}
      >
        <Box display="flex">
          {showLeftPicture && (
            <Avatar
              picture={picture}
              name={userName}
              sx={{ mr: 2 }}
              downloadPicture={displayProtectedImage}
            />
          )}
          {leftIcon && (
            <Box mr={2}>
              <Icon
                name={leftIcon}
                fill={theme.palette.text.primary}
                size="large"
              />
            </Box>
          )}
          <Typography
            i18nKey={i18nKey}
            variant={isLogout ? 'body2' : 'body1'}
            color={
              isLogout ? theme.palette.error.main : theme.palette.text.primary
            }
            alignSelf="center"
          >
            {text}
          </Typography>
        </Box>
        <Icon name={rightIcon} fill={theme.palette.text.primary} size="large" />
      </CardActionArea>
    </Card>
  );
};

export default ProfileCard;
