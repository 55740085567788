import React from 'react';
import { useTheme } from '@mui/material';

export const YAxisLimitsComponent = (props: { ticks: string[] | number[] }) => {
  const theme = useTheme();
  const { ticks } = props;

  return (
    <div className="y-axis-wrapper" style={{ marginLeft: '-20px' }}>
      <svg width="70" height="200" viewBox="1 0 60 200">
        <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
          <line
            fontSize="12"
            orientation="left"
            width="60"
            height="160"
            x="5"
            y="5"
            className="recharts-cartesian-axis-line"
            stroke={theme.palette.text.hint}
            fill="none"
            x1="65"
            y1="5"
            x2="65"
            y2="165"
          />
          <g className="recharts-cartesian-axis-ticks">
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="5"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke={theme.palette.text.hint}
                fill="none"
                x1="59"
                y1="165"
                x2="65"
                y2="165"
              />
              <text
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="57"
                y="165"
                stroke="none"
                fill={theme.palette.text.hint}
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="57" dy="0.355em">
                  {ticks[0]}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="5"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke={theme.palette.text.hint}
                fill="none"
                x1="59"
                y1="152"
                x2="65"
                y2="152"
              />
              <text
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="57"
                y="152"
                stroke="none"
                fill={theme.palette.text.hint}
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="57" dy="0.355em">
                  {ticks[1]}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="5"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke={theme.palette.text.hint}
                fill="none"
                x1="59"
                y1="118"
                x2="65"
                y2="118"
              />
              <text
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="57"
                y="118"
                stroke="none"
                fill={theme.palette.text.hint}
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="57" dy="0.355em">
                  {ticks[2]}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="5"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke={theme.palette.text.hint}
                fill="none"
                x1="59"
                y1="92"
                x2="65"
                y2="92"
              />
              <text
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="57"
                y="92"
                stroke="none"
                fill={theme.palette.text.hint}
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="57" dy="0.355em">
                  {ticks[3]}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="5"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke={theme.palette.text.hint}
                fill="none"
                x1="59"
                y1="5"
                x2="65"
                y2="5"
              />
              <text
                fontSize="12"
                orientation="left"
                width="60"
                height="160"
                x="57"
                y="9"
                stroke="none"
                fill={theme.palette.text.hint}
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="57" dy="0.355em">
                  {ticks[4]}+
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
