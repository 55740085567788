import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import { useEffectOnce } from '@fdha/common-hooks';
import {
  useCommunityMarkNotificationAsReadMutation,
  useListCommunityNotificationsQuery,
} from '@fdha/graphql-api-patient';
import {
  EmptyStateCard,
  InfiniteScrollList,
  NotificationItem,
} from '@fdha/web-ui-library';
import { useNotifications } from '@hooks';
import { Paper, Skeleton, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

const CommunityNotifications = () => {
  const navigate = useNavigate();
  const { data, networkStatus, fetchMore } = useListCommunityNotificationsQuery(
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const { clearNotification } = useNotifications();
  useEffectOnce(() => {
    clearNotification('community');
  });

  const [communityMarkNotificationAsRead] =
    useCommunityMarkNotificationAsReadMutation();

  const notifications = data?.communityNotifications.notifications || [];
  type CommunityNotificationGeneric = (typeof notifications)[0];

  const hasNextPage = data?.communityNotifications.hasNextPage;
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const loadMore = async () => {
    if (isLoadingMore || !hasNextPage) {
      return;
    }

    await fetchMore({
      variables: { before: notifications[notifications.length - 1].id },
    });
  };

  const handleNotificationClick = (item: CommunityNotificationGeneric) => {
    switch (item.__typename) {
      case 'CommunityNotificationComment':
      case 'CommunityNotificationRemoveComment':
      case 'CommunityNotificationRemovePost':
        if (!item.postId) {
          return;
        }

        navigate(`/community/${item.postId}`);
        break;

      default:
        navigate('/community');
        break;
    }

    communityMarkNotificationAsRead({ variables: { notificationId: item.id } });
  };

  const renderItem = (item: CommunityNotificationGeneric) => {
    const name =
      'commenterUsers' in item ? item.commenterUsers[0].name : undefined;
    const picture =
      'commenterUsers' in item ? item.commenterUsers[0].picture : undefined;

    return (
      <NotificationItem
        name={name}
        picture={picture}
        handleClick={() => handleNotificationClick(item)}
        read={item.isRead}
        time={item.time}
        type={item.__typename}
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyStateCard
        title="No notifications yet"
        message="When you get notifications, they will show up here."
      />
    );
  };

  const renderLoadingState = () => (
    <Stack spacing={1}>
      {[...Array(7)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={96} />
      ))}
    </Stack>
  );

  return (
    <DefaultBasePage title="Notifications" contentSize="small">
      {isLoading ? (
        renderLoadingState()
      ) : (
        <Paper sx={{ padding: 'unset' }}>
          <InfiniteScrollList
            items={notifications}
            renderItem={renderItem}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={loadMore}
            renderEmptyState={renderEmptyState}
            disableRowGap
          />
        </Paper>
      )}
    </DefaultBasePage>
  );
};

export default CommunityNotifications;
