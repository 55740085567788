import { Typography } from '@fdha/web-ui-library';
import { MultipleChoiceQuestionProps } from '@models';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface MultipleChoiceViewProps {
  question: MultipleChoiceQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

export const MultipleChoiceView: FC<MultipleChoiceViewProps> = ({
  question,
  handleChange,
}) => {
  const { values } = useFormikContext<any>();

  const key = question.id;
  const array = values[key] || [];

  return (
    <Stack>
      {question.options.map((option) => (
        <FormControlLabel
          key={option.value}
          label={
            <Typography i18nKey={option?.i18nKey}>{option.label}</Typography>
          }
          checked={array.includes(option.value)}
          control={<Checkbox />}
          onChange={(_event, value) => {
            if (value) {
              handleChange([...array, option.value]);
            } else {
              array.splice(array.indexOf(option.value), 1);
              handleChange(array);
            }
          }}
        />
      ))}
    </Stack>
  );
};
