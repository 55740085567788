import { useDialog, useSnackbar } from '@fdha/web-ui-library';
import {
  DownloadDocumentQuery,
  useDeleteDocumentMutation,
  useDownloadDocumentLazyQuery,
} from '@fdha/graphql-api-patient';
import { useAnalytics } from '@fdha/common-hooks';

export const useDocuments = () => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const [deleteDocument] = useDeleteDocumentMutation();
  const { analyticsClient } = useAnalytics();

  const handleDownloadData = (downloadData?: DownloadDocumentQuery) => {
    if (downloadData?.downloadDocument?.url) {
      const link = document.createElement('a');
      link.href = downloadData.downloadDocument.url;
      link.setAttribute(
        'download',
        downloadData.downloadDocument.document.filename
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      showSnackbarV2({
        message: 'File Downloaded',
        i18nKey: 'profile:documents.snackbar.downloaded',
        severity: 'info',
      });
    }
  };

  const [downloadDocument, { data: downloadData }] =
    useDownloadDocumentLazyQuery({
      onCompleted: () => handleDownloadData(downloadData),
      fetchPolicy: 'no-cache',
    });

  const handleDownloadDocument = async (id: string, uploadedBy?: string) => {
    try {
      downloadDocument({
        variables: {
          id,
        },
      });
      if (uploadedBy) {
        analyticsClient?.track('Document Downloaded', {
          uploadedBy,
        });
      }
    } catch (e) {
      showSnackbarV2({
        message: 'Unable to download file. Try again.',
        severity: 'error',
        i18nKey: 'profile:documents.snackbar.unableDownloadFile',
      });
      console.log(e);
    }
  };

  // Delete

  const onDelete = async (id: string, refetch: () => void) => {
    try {
      await deleteDocument({
        variables: {
          id,
        },
      });
      closeDialog();
      showSnackbarV2({
        message: 'File Deleted',
        severity: 'info',
        i18nKey: 'profile:documents.snackbar.fileDeleted',
      });
      refetch();
    } catch (e) {
      showSnackbarV2({
        message: 'Unable to delete file. Try again.',
        severity: 'error',
        i18nKey: 'profile:documents.snackbar.unableDeleteFile',
      });
      console.log(e);
    }
  };

  const handleDeleteDocument = async (id: string, refetch: () => void) => {
    openDialogV2({
      title: 'Are you sure you want to delete this file?',
      content: undefined,
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Delete',
      i18nKeyTitle: 'profile:documents.dialog.deleteTitle',
      handleConfirm: () => onDelete(id, refetch),
    });
  };

  return { handleDownloadDocument, handleDeleteDocument };
};
