import { Mask } from '@fdha/web-ui-library';
import { DecimalProps, SurveyQuestionLogic } from '@fdha/graphql-api-patient';

export enum QuestionType {
  Binary = 'Binary',
  MultipleChoice = 'MultipleChoice',
  Number = 'Number',
  OpenText = 'OpenText',
  Scale = 'Scale',
  SingleChoice = 'SingleChoice',
  DateOption = 'DateOption',
  TextSelect = 'TextSelect',
  Explanation = 'Explanation',
  Autocomplete = 'Autocomplete',
  Address = 'Address',
  BHB = 'BHB',
  Meal = 'Meal',
  Snack = 'Snack',
  Time = 'Time',
  Decimal = 'Decimal',
}

type CommonProps = {
  id: string;
  title: string;
  i18nKeyTitle?: string;
  subtitle?: string | null;
  i18nKeySubtitle?: string;
  showHipaaSubtitle?: boolean;
  label?: string;
  required?: boolean | null;
  initialValue?: any;
  errorMessage?: string;
  logic?: SurveyQuestionLogic;
};

export interface Option {
  label: string;
  value: string;
  i18nKey?: string;
}

export type OpenTextQuestionProps = CommonProps & {
  type: QuestionType.OpenText;
  placeholder?: string;
  maxLength?: number;
  helperText?: string;
  i18nKeyPlaceholder?: string;
  i18nKeyHelperText?: string;
};

export enum DateOption {
  Remember = 'remember',
  DontRemember = 'dontRemember',
}

export type DateOptionQuestionProps = CommonProps & {
  type: QuestionType.DateOption;
  placeholder: string;
  i18nKeyPlaceholder?: string;
  mask: Extract<Mask, 'date' | 'monthYear'>;
  options: {
    label: string;
    value: DateOption;
    i18nKeyLabel?: string;
  }[];
};

export type TextSelectQuestionProps = CommonProps & {
  type: QuestionType.TextSelect;
  text: {
    label: string;
    i18nKeyLabel?: string;
  };
  select: {
    label: string;
    i18nKeyLabel?: string;
    options: { value: string; label: string }[];
  };
};

export type SingleChoiceQuestionProps = CommonProps & {
  type: QuestionType.SingleChoice;
  options: { label: string; value: string; i18nKey?: string }[];
  otherOption?: {
    label: string;
  };
};

export type MultipleChoiceQuestionProps = CommonProps & {
  type: QuestionType.MultipleChoice;
  subtitle?: string;
  options: {
    i18nKey?: string;
    label: string;
    value: string;
  }[];
};

export type ScaleQuestionProps = CommonProps & {
  type: QuestionType.Scale;
  options: { label: number; value: number }[];
};

export type AutocompleteQuestionProps = CommonProps & {
  type: QuestionType.Autocomplete;
  link: { label: string; route: string; i18nKey?: string };
  options: { label: string; id: string }[];
};

export type NumberQuestionProps = CommonProps & {
  type: QuestionType.Number;
  placeholder: string;
  i18nKeyPlaceholder?: string;
};

export type AddressQuestionProps = CommonProps & {
  type: QuestionType.Address;
};

export type BinaryQuestionProps = CommonProps & {
  type: QuestionType.Binary;
  options: Option[];
};

export type BHBQuestionProps = CommonProps & {
  type: QuestionType.BHB;
};

export type MealQuestionProps = CommonProps & {
  type: QuestionType.Meal;
  options: Option[];
};

export type SnackQuestionProps = CommonProps & {
  type: QuestionType.Snack;
  options?: Option[];
};

export type TimeQuestionProps = CommonProps & {
  type: QuestionType.Time;
};

export type DecimalQuestionProps = CommonProps & {
  type: QuestionType.Decimal;
  placeholder?: string;
  i18nKeyPlaceholder?: string;
  maxLength: number;
  customPropsOfType: DecimalProps;
};

export type QuestionProps =
  | OpenTextQuestionProps
  | DateOptionQuestionProps
  | SingleChoiceQuestionProps
  | MultipleChoiceQuestionProps
  | ScaleQuestionProps
  | AutocompleteQuestionProps
  | NumberQuestionProps
  | AddressQuestionProps
  | BinaryQuestionProps
  | TextSelectQuestionProps
  | BHBQuestionProps
  | MealQuestionProps
  | SnackQuestionProps
  | TimeQuestionProps
  | DecimalQuestionProps;
