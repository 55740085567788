import { useGetCommunityUserQuery } from '@fdha/graphql-api-patient';
import { useEffect, useState } from 'react';

export const useCommunity = () => {
  const [isBanned, setIsBanned] = useState<boolean | undefined>(false);

  const { data } = useGetCommunityUserQuery();

  useEffect(() => {
    if (data?.getCommunityUser?.isBanned !== isBanned) {
      setIsBanned(data?.getCommunityUser?.isBanned);
    }
  }, [data, isBanned]);

  return { isBanned };
};
