import { MaskedTextFieldV2, Typography } from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import { CreateAccountResponse } from './createAccountScreens';

const PhoneNumber = () => {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<CreateAccountResponse>();

  const phoneNumberError = touched.phoneNumber ? errors.phoneNumber : null;
  const confirmPhoneNumberError = touched.confirmPhoneNumber
    ? errors.confirmPhoneNumber
    : null;

  return (
    <Stack spacing={2}>
      <Box>
        <Typography
          i18nKey="login:createAccount.step3to7.input.mobileNumber.label"
          variant="h5"
        >
          What is your <b>mobile number</b>?
        </Typography>
        <MaskedTextFieldV2
          name="phoneNumber"
          mask="phoneNumber"
          i18nKeyPlaceholder="login:createAccount.step3to7.input.mobileNumber.placeholder"
          placeholder="xxx-xxx-xxxx"
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!phoneNumberError}
          helperText={phoneNumberError}
        />
        <Typography
          i18nKey="login:createAccount.step3to7.input.mobileNumber.helperText"
          variant="caption"
        >
          Ensure that the mobile phone number provided can receive text messages
        </Typography>
      </Box>
      <Box>
        <Typography
          i18nKey="login:createAccount.step3to7.input.confirmMobileNumber.label"
          variant="h5"
        >
          Confirm your <b>mobile number</b>
        </Typography>
        <MaskedTextFieldV2
          name="confirmPhoneNumber"
          mask="phoneNumber"
          i18nKeyPlaceholder="login:createAccount.step3to7.input.confirmMobileNumber.placeholder"
          placeholder="xxx-xxx-xxxx"
          value={values.confirmPhoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!confirmPhoneNumberError}
          helperText={confirmPhoneNumberError}
        />
      </Box>
    </Stack>
  );
};

export default PhoneNumber;
