import { DefaultBasePage } from '@components';
import { Icon, OverflowText, Typography } from '@fdha/web-ui-library';
import { useGetLearningModuleQuery } from '@fdha/graphql-api-patient';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { uniqBy } from 'lodash';

interface Item {
  id: string;
  title: string;
}

const CourseHome = () => {
  const { moduleId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data, loading } = useGetLearningModuleQuery({
    variables: { learningModuleId: moduleId || '' },
  });

  const { learningModule } = data || {};

  const courseItems = useMemo(() => {
    const items: Item[] = [
      ...(learningModule?.learningItems.map(({ id, title }) => ({
        id,
        title,
      })) || []),
      ...(learningModule?.quiz
        ? [
            {
              id: learningModule.quiz.id,
              title: learningModule.quiz.title,
            },
          ]
        : []),
    ];

    return uniqBy(items, 'id');
  }, [learningModule?.learningItems, learningModule?.quiz]);

  const completedCourseItems = useMemo(() => {
    return courseItems.filter(({ id }) => {
      const { completed } =
        learningModule?.itemsStatus?.find((item) => item?.itemId === id) || {};
      return completed;
    });
  }, [courseItems, learningModule?.itemsStatus]);

  const firstUnlockedItemIndex = useMemo(() => {
    if (!learningModule?.lockLearningItems) {
      return null;
    }
    return courseItems.findIndex(({ id }) => {
      const { completed } =
        learningModule.itemsStatus?.find((item) => item?.itemId === id) || {};

      return !completed;
    });
  }, [
    learningModule?.lockLearningItems,
    courseItems,
    learningModule?.itemsStatus,
  ]);

  const renderItemCard = (item: Item, index: number) => {
    const { id, title } = item;

    const route = `${pathname}/${id}`;

    const { completed } =
      learningModule?.itemsStatus?.find((item) => item?.itemId === id) || {};

    const isLocked =
      firstUnlockedItemIndex != null &&
      index > firstUnlockedItemIndex &&
      !completed;

    const iconName = completed
      ? 'checkmark-circle-2'
      : isLocked
      ? 'lock-outline'
      : 'arrow-ios-forward-outline';

    const iconFill = completed
      ? theme.palette.success.main
      : isLocked
      ? theme.palette.action.disabled
      : grey[900];

    return (
      <Card key={id}>
        <CardActionArea
          disabled={isLocked}
          onClick={() => navigate(route)}
          sx={{
            '&:hover': { backgroundColor: theme.palette.action.background },
            '&.Mui-disabled': {
              '&:hover': { backgroundColor: 'transparent' },
              // Workaround to make tooltip work when button is disabled
              pointerEvents: 'auto',
            },
          }}
        >
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <OverflowText label={title} variant="h6">
                {title}
              </OverflowText>
              <Icon
                name={iconName}
                fill={iconFill}
                size="large"
                sx={{ ml: 1 }}
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Stack spacing={3}>
        <Skeleton variant="rectangular" height="200px" />
        <Skeleton variant="rectangular" height="4px" />
        <Stack spacing={1}>
          {[...Array(3)].map((_value: any, index: number) => (
            <Skeleton
              variant="rectangular"
              height="20px"
              key={`skeleton1_${index}`}
            />
          ))}
          <Skeleton variant="rectangular" height="20px" width="90%" />
          <Skeleton variant="rectangular" height="20px" width="35%" />
        </Stack>
        {[...Array(5)].map((_value: any, index: number) => (
          <Skeleton
            variant="rectangular"
            height="60px"
            key={`skeleton2_${index}`}
          />
        ))}
      </Stack>
    );
  };

  return (
    <DefaultBasePage
      contentSize="large"
      currentRouteName={{
        text: 'Course',
        i18nKey: 'learning:pageTitle',
      }}
      isLoading={loading}
      title={learningModule?.title}
      progress={{
        show: true,
        progressBarProps: {
          completed: completedCourseItems.length || 0,
          total: courseItems.length || 0,
          type: 'steps',
        },
      }}
    >
      {loading ? (
        renderLoading()
      ) : (
        <Stack spacing={3}>
          <img
            src={learningModule?.image}
            alt="Course cover"
            height="200px"
            width="100%"
            style={{ objectFit: 'cover' }}
          />
          <Typography variant="body1">{learningModule?.description}</Typography>
          {courseItems.map((item, index) => renderItemCard(item, index))}
        </Stack>
      )}
    </DefaultBasePage>
  );
};

export default CourseHome;
