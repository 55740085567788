import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Typography } from '@fdha/web-ui-library';

interface QuestionHeaderProps {
  title: string;
  subtitle?: string | null;
  showHipaaSubtitle?: boolean;
  showRequired?: boolean | null;
  i18nKeyTitle?: string;
  i18nKeySubtitle?: string;
}

const QuestionHeader: React.FC<QuestionHeaderProps> = ({
  title,
  subtitle,
  showHipaaSubtitle,
  showRequired,
  i18nKeyTitle,
  i18nKeySubtitle,
}) => {
  const theme = useTheme();

  return (
    <Box mb={3} data-testid="QUESTION_HEADER">
      <Typography variant="h5" flex={1} i18nKey={i18nKeyTitle}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="subtitle1"
          color={theme.palette.text.secondary}
          i18nKey={i18nKeySubtitle}
        >
          {subtitle}
        </Typography>
      )}
      {showHipaaSubtitle && (
        <Typography
          variant="subtitle1"
          color={theme.palette.text.secondary}
          i18nKey="common:text.hippaSubtitle"
        >
          We want to know who else will support you. All data you share with us
          is confidential and protected under HIPAA.
        </Typography>
      )}
      {showRequired && (
        <Typography
          variant="caption"
          fontWeight="bold"
          color={theme.palette.error.main}
          i18nKey="common:text.required"
        >
          *Required
        </Typography>
      )}
    </Box>
  );
};

export default QuestionHeader;
