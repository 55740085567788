import React, { useState } from 'react';
import { ActivityKind } from '@fdha/graphql-api-patient';
import {
  ToggleButton,
  ToggleButtonOption,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library/';

import AddOrEditGoal from './AddGoal/AddOrEditGoal';
import AddOrEditTask from './AddTask/AddOrEditTask';
import AddOrEditAppt from './AddAppt/AddOrEditAppt';

const options: ToggleButtonOption<ActivityKind>[] = [
  {
    label: 'Goal',
    value: ActivityKind.Goal,
    i18nKey: 'activities:addOrEdit.toggleButton.goal',
  },
  {
    label: 'Task',
    value: ActivityKind.Task,
    i18nKey: 'activities:addOrEdit.toggleButton.task',
  },
  {
    label: 'Appt',
    value: ActivityKind.Appointment,
    i18nKey: 'activities:addOrEdit.toggleButton.appt',
  },
];

const getComponentByKind = (kind: ActivityKind) => {
  return kind === ActivityKind.Goal
    ? AddOrEditGoal
    : kind === ActivityKind.Task
    ? AddOrEditTask
    : AddOrEditAppt;
};

export default function AddActivity() {
  const { closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();
  const [selectedKind, setSelectedKind] = useState<ActivityKind>(
    ActivityKind.Goal
  );

  const onCancel = () => {
    closeDialog();
    showSnackbarV2({
      message: 'Changes Not Saved',
      severity: 'info',
      i18nKey: 'common:snackbar.changesNotSaved',
    });
  };

  const Component = getComponentByKind(selectedKind);

  return (
    <>
      <ToggleButton
        options={options}
        selectedValue={selectedKind}
        setValue={setSelectedKind}
      />
      <Component onCancel={onCancel} />
    </>
  );
}
