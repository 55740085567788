import {
  LogicAction,
  LogicCondition,
  SurveyQuestionType,
} from '@fdha/graphql-api-patient';
import { QuestionProps } from '@models';

export const checkLogicCondition = (
  question: QuestionProps,
  values: any,
  openedScreens: string[]
) => {
  const conditionQuestionIndex =
    question.logic?.conditionQuestion?.index != null
      ? question.logic?.conditionQuestion?.index
      : question.logic?.conditionQuestion?.value;

  if (conditionQuestionIndex == null) {
    return false;
  }

  const condition = question.logic?.condition;
  const conditionAnswer = question.logic?.conditionAnswer ?? '';
  const newConditionAnswer = conditionAnswer
    .split(',')
    .map((item) => item.trim());
  const action = question.logic?.action;

  const logicScreenOpened =
    !!question.logic?.conditionQuestion?.value &&
    openedScreens.includes(question.logic?.conditionQuestion?.value);

  if (!logicScreenOpened) {
    return action === LogicAction.Show;
  }

  const newResponse = values[conditionQuestionIndex];

  let hasConditionAnswer = false;

  switch (question.logic?.conditionQuestion?.type) {
    case SurveyQuestionType.Meal:
    case SurveyQuestionType.Snack: {
      hasConditionAnswer = newConditionAnswer.includes(newResponse?.linkOption);
      break;
    }
    case SurveyQuestionType.MultipleChoice: {
      hasConditionAnswer = !!newConditionAnswer.find((element) => {
        return newResponse.includes(element);
      });
      break;
    }
    case SurveyQuestionType.SingleChoice:
    case SurveyQuestionType.Binary: {
      hasConditionAnswer = newConditionAnswer.includes(newResponse.option);
      break;
    }
    default: {
      const answer = newConditionAnswer.map((item) =>
        item.trim().toLowerCase()
      );
      hasConditionAnswer = answer.includes(
        newResponse?.toString()?.trim()?.toLowerCase()
      );
      break;
    }
  }

  const positiveCondition =
    condition === LogicCondition.Contains ||
    condition === LogicCondition.EqualTo;
  const negativeCondition =
    condition === LogicCondition.NotContains ||
    condition === LogicCondition.NotEqualTo;
  const isRightAnswer =
    (hasConditionAnswer && positiveCondition) ||
    (!hasConditionAnswer && negativeCondition);

  if (action === LogicAction.Show) {
    return !isRightAnswer;
  } else if (action === LogicAction.Hide) {
    return isRightAnswer;
  }
};

const getAnswer = (value: any) => {
  const answer: string[] = [];
  if (Array.isArray(value)) {
    answer.push(...value);
  } else if (typeof value === 'number') {
    answer.push(value.toString());
  } else if (typeof value === 'object' && Object.hasOwn(value, 'option')) {
    if (value.otherOption) {
      answer.push(value.otherOption);
    } else if (value.option) {
      answer.push(value.option);
    } else {
      answer.push('');
    }
  } else if (typeof value === 'object' && Object.hasOwn(value, 'time')) {
    const { time, dayPeriod } = value;

    if (!time || !dayPeriod) {
      answer.push('');
    } else {
      answer.push(`${time} ${dayPeriod.toUpperCase()}`);
    }
  } else if (typeof value === 'object' && Object.hasOwn(value, 'dishOption')) {
    const { dishOption, linkOption } = value;

    answer.push(dishOption || linkOption);
  } else if (typeof value === 'object' && Object.hasOwn(value, 'decimal')) {
    const { decimal, unit } = value;

    answer.push(`${decimal} ${unit}`);
  } else {
    answer.push(value);
  }

  return answer;
};

export const getAnswers = (values: any) => {
  return Object.entries(values)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      return {
        questionId: key,
        answer: getAnswer(value),
      };
    });
};
