import { NetworkStatus, Reference } from '@apollo/client';
import { DefaultBasePage } from '@components';
import {
  CommentBoxV2,
  CommentCardV2,
  commentInitialValues,
  CommentSchema,
  commentValidationSchema,
  CommunityRole,
  DeletedCommentCard,
  InfiniteScrollList,
  PostV2,
  theme,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import {
  ListPostsDocument,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetCommunityUserQuery,
  Comment,
  useDeletePostMutation,
  useGetPostWithCommentsQuery,
  PostStatus,
} from '@fdha/graphql-api-patient';
import { Box, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import { useAnalytics } from '@fdha/common-hooks';

interface StateProps {
  focusOnInput: boolean;
  backRoute?: string;
}

// The optimistic response requires that all fields from the referenced type
// exist when adding an item, while editing required only the originally required fields
type OptimisticResponseAddComment = Required<Omit<Comment, 'removedAt'>>;
type OptimisticResponseEditComment = Required<
  Omit<Comment, 'removedBy' | 'removedAt'>
>;

const ViewPost = () => {
  const { openDialogV2, closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();
  const { analyticsClient } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as StateProps;
  const params = useParams();
  const id = params.id ?? '';
  const focusOnInput = state?.focusOnInput;
  const backRoute = state?.backRoute ?? '';
  const isFromFeed = backRoute === '/community';

  const { data: userData, loading: loadingUser } = useGetCommunityUserQuery();
  const { data, networkStatus, fetchMore } = useGetPostWithCommentsQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });
  const [deletePost] = useDeletePostMutation({
    refetchQueries: [ListPostsDocument],
  });

  const [editedComment, setEditComment] = useState<Comment | undefined>(
    undefined
  );
  const [addCommentMutation] = useAddCommentMutation();
  const [deleteCommentMutation] = useDeleteCommentMutation();
  const [editCommentMutation] = useEditCommentMutation();

  const post = data?.postWithComments?.post;
  const comments = data?.postWithComments?.comments ?? [];
  const isPostRemoved = post?.status === PostStatus.Removed;
  const user = userData?.getCommunityUser;
  const isModerator = user?.role === CommunityRole.Moderator;

  const inputRef = useRef<HTMLDivElement>(null);

  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const loadingPost = networkStatus === NetworkStatus.refetch;

  const handleDeleteComment = async (comment: Comment): Promise<void> => {
    openDialogV2({
      title: 'Are you sure you want to delete this comment?',
      content: 'This action can’t be undone.',
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        await deleteComment(comment);
        closeDialog();
      },
    });
  };

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost({
        variables: {
          postId,
        },
      });
      navigate(-1);
      showSnackbarV2({
        severity: 'success',
        message: 'Post Deleted',
      });
    } catch (e) {
      showSnackbarV2({
        severity: 'error',
        message: 'Unable to Delete Post',
      });
    } finally {
      closeDialog();
    }
  };

  const onDeleteButton = (id: string) => {
    openDialogV2({
      title: 'Are you sure you want to delete this post?',
      content: 'This action can’t be undone.',
      handleConfirm: () => handleDeletePost(id),
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
    });
  };

  const handleSendComment = async (text: string, editedComment?: Comment) => {
    if (post == null || user == null) {
      return;
    }

    const postId = post.id;

    if (editedComment) {
      const editCommentResponse: OptimisticResponseEditComment = {
        __typename: 'Comment',
        id: editedComment.id,
        postId,
        user: editedComment.user,
        text,
        time: editedComment.time,
        isEdited: true,
        isRemoved: false,
        isPersisted: false,
      };

      const editComment = editCommentMutation({
        variables: {
          edit: {
            commentId: editedComment.id,
            text,
          },
        },
        optimisticResponse: {
          editComment: editCommentResponse,
        },
        update(cache, mutationResult) {
          const comment = mutationResult.data?.editComment;

          if (comment == null || data?.postWithComments == null) {
            return;
          }

          cache.modify({
            id: cache.identify(data.postWithComments),
            fields: {
              comments(existingComments: Comment[] = []) {
                return existingComments.map((c) =>
                  c.id === comment.id ? comment : c
                );
              },
            },
          });
        },
      });
      try {
        await editComment;
        showSnackbarV2({ severity: 'success', message: 'Changes Saved' });
      } catch {
        showSnackbarV2({
          severity: 'error',
          message: 'Unable to Edit Comment',
        });
      }
    } else {
      const addCommentResponse: OptimisticResponseAddComment = {
        __typename: 'Comment',
        id: uniqueId('__ADDED_COMMENT_'),
        postId,
        removedBy: null,
        user,
        text,
        time: '',
        isEdited: false,
        isRemoved: false,
        isPersisted: false,
      };

      const addComment = addCommentMutation({
        variables: {
          comment: {
            postId,
            text,
          },
        },
        optimisticResponse: {
          addComment: addCommentResponse,
        },
        update(cache, mutationResult) {
          const comment = mutationResult.data?.addComment;

          if (comment == null || data?.postWithComments == null) {
            return;
          }

          cache.modify({
            id: cache.identify(data.postWithComments),
            fields: {
              comments() {
                return [comment, ...comments];
              },
            },
          });

          cache.modify({
            id: cache.identify(post),
            fields: {
              numComments(previousNumComments) {
                return previousNumComments + 1;
              },
            },
          });
        },
      });

      try {
        const addedComment = await addComment;
        analyticsClient?.track('Community Comment Created', {
          id: addedComment.data?.addComment.id,
        });
        showSnackbarV2({
          severity: 'success',
          message: 'New Comment Published',
        });
      } catch {
        showSnackbarV2({
          severity: 'error',
          message: 'Unable to Publish New Comment',
        });
      }
    }
  };

  const deleteComment = async (comment: Comment): Promise<void> => {
    if (post == null) {
      return;
    }

    const { id: commentId } = comment;

    try {
      await deleteCommentMutation({
        variables: {
          commentId,
        },
        optimisticResponse: {
          deleteComment: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({ __typename: 'PostWithComments' }),
            fields: {
              comments(existingCommentRefs: Reference[] = [], { readField }) {
                return existingCommentRefs.filter(
                  (cr) => commentId !== readField('id', cr)
                );
              },
            },
          });

          cache.modify({
            id: cache.identify(post),
            fields: {
              numComments(previousNumComments) {
                return previousNumComments - 1;
              },
            },
          });
        },
      });
      showSnackbarV2({ severity: 'info', message: 'Comment Deleted' });
    } catch {
      showSnackbarV2({
        severity: 'error',
        message: 'Unable to Delete Comment',
      });
    }
  };

  const loadMore = () => {
    if (!post?.numComments) return;

    const hasNextPage = comments.length < post.numComments;

    if (isLoadingMore || !hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        beforeComment: comments[comments.length - 1].id,
      },
    });
  };

  const handleEditComment = async (values: CommentSchema) => {
    const { text } = values;
    if (!text) {
      return;
    }

    resetForm({ values: { text: '' } });

    setEditComment(undefined);

    await handleSendComment(text, editedComment);
  };

  const handleEditButton = (id: string) => {
    navigate(`/community/${id}/edit-post`);
  };

  const {
    setFieldValue,
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
  } = useFormik({
    initialValues: commentInitialValues,
    validationSchema: commentValidationSchema,
    onSubmit: handleEditComment,
  });

  const renderItem = (comment: Comment) => {
    return comment.isRemoved ? (
      <DeletedCommentCard
        key={comment.id}
        comment={comment}
        isModerator={isModerator}
        isMyComment={comment.user.id === user?.id}
      />
    ) : (
      <CommentCardV2
        key={comment.id}
        comment={comment}
        showModeratorLabel={comment.user.role === CommunityRole.Moderator}
        onEdit={() => {
          setFieldValue('text', comment.text);
          setEditComment(comment);
        }}
        onDelete={handleDeleteComment}
        isMyComment={comment.user.id === user?.id}
        isEditing={comment.id === editedComment?.id}
        isModerator={isModerator}
        editError={errors.text}
        editText={values.text}
        editOnChange={handleChange}
        editOnCancel={() => setEditComment(undefined)}
        editOnSave={handleSubmit}
      />
    );
  };

  const loading =
    (isFromFeed && isLoading) || loadingUser || loadingPost || !post;

  const loadingState = () => {
    return <Skeleton variant="rectangular" height="800px" width="100%" />;
  };

  return (
    <DefaultBasePage title="Post" contentSize="small">
      {loading ? (
        loadingState()
      ) : (
        <>
          <PostV2
            post={post}
            onDelete={onDeleteButton}
            isModerator={isModerator}
            onEdit={() => handleEditButton(post?.id)}
            isMine={post?.user.id === userData?.getCommunityUser?.id}
            onClickComment={() => inputRef.current && inputRef.current.focus()}
            isPostRemoved={isPostRemoved}
            onRemovePost={() => {}}
          />
          {!isPostRemoved && (
            <Box
              sx={{
                paddingTop: 2,
                paddingX: 2,
                backgroundColor: theme.palette.background.paper,
                border: '1px solid',
                borderTop: 'none',
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                borderColor: theme.palette.divider,
              }}
            >
              <CommentBoxV2
                onSendComment={handleSendComment}
                focusOnInput={focusOnInput}
                inputRef={inputRef}
              />
              <Box mt={4}>
                <InfiniteScrollList
                  items={comments}
                  renderItem={renderItem}
                  isLoading={isLoading}
                  isLoadingMore={isLoadingMore}
                  loadMore={loadMore}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </DefaultBasePage>
  );
};

export default ViewPost;
