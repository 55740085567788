import { SortByOrder } from '@fdha/graphql-api-patient';

export const selectItems = [
  {
    label: 'Most Recent',
    value: 'updated_at',
    i18nKey: 'profile:documents.sort.mostRecent',
  },
  {
    label: 'Name (A-Z)',
    value: 'filename',
    i18nKey: 'profile:documents.sort.aZ',
  },
];

export const getSortOrder = (value: string) => {
  return value === 'updated_at' ? SortByOrder.Desc : SortByOrder.Asc;
};
