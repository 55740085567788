import { Icon, Typography } from '@fdha/web-ui-library';
import { Box, Card, CardActionArea, Stack, useTheme } from '@mui/material';
import React from 'react';

interface MealsHelpCardProps {
  title: string;
  subtitle: string;
  icon: string;
  onClick: () => void;
  i18nKeyTitle?: string;
  i18nKeySubtitle?: string;
}

const MealsHelpCard: React.FC<MealsHelpCardProps> = ({
  title,
  subtitle,
  icon,
  onClick,
  i18nKeyTitle,
  i18nKeySubtitle,
}) => {
  const theme = useTheme();

  return (
    <Card>
      <CardActionArea
        onClick={onClick}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '254px',
          py: 3,
          px: 3,
        }}
      >
        <Stack direction="row" spacing={2.5}>
          <Icon
            name={icon}
            fill={theme.palette.text.secondary}
            size="large"
            sx={{ mt: 1 }}
          />
          <Box>
            <Typography variant="h6" i18nKey={i18nKeyTitle}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              i18nKey={i18nKeySubtitle}
              color={theme.palette.text.secondary}
            >
              {subtitle}
            </Typography>
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default MealsHelpCard;
