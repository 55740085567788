import React from 'react';
import { Box, Card, CardActionArea, useTheme } from '@mui/material';
import { Icon, Typography } from '@fdha/web-ui-library';

interface CategoryCardProps {
  title: string;
  handleClick: () => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ title, handleClick }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          px: 2,
          py: '22px',
        }}
      >
        <Box display="flex" flexDirection="row">
          <Typography
            variant="h6"
            color={theme.palette.text.primary}
            alignSelf="center"
          >
            {title}
          </Typography>
        </Box>
        <Icon
          name="arrow-ios-forward-outline"
          fill={theme.palette.text.primary}
          size="large"
        />
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
