import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Icon, Typography } from '@fdha/web-ui-library';
import { ChatScreenType, setSelectedChatScreenType } from '@states';

import ChatMedia from './ChatMedia/ChatMedia';

const ChatDetails = () => {
  const theme = useTheme();

  const getTitle = () => ({
    title: 'Chat Media',
    i18nKey: 'chat:chatMedia.title',
  });

  const handleBack = () => {
    setSelectedChatScreenType(ChatScreenType.Channel);
  };

  return (
    <Box
      width="100%"
      height="100%"
      data-testid="CHAT_DETAILS_CONTAINER"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="center"
        height="88px"
        borderBottom="solid 1px"
        borderColor={grey[300]}
        data-testid="CHAT_DETAILS_HEADER"
        flexShrink={0}
      >
        <Box marginX="28px">
          <IconButton onClick={handleBack} sx={{ padding: 0 }}>
            <Icon
              name="arrow-back-outline"
              size="large"
              fill={theme.palette.primary.main}
            />
          </IconButton>
        </Box>
        <Typography i18nKey={getTitle().i18nKey} variant="h5" fontWeight="bold">
          {getTitle().title}
        </Typography>
      </Box>
      <ChatMedia />
    </Box>
  );
};

export default ChatDetails;
