import React, { FC } from 'react';
import { DefaultBasePage } from '@components';
import { Typography } from '@fdha/web-ui-library';
import { Divider, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

interface BHBRecommendationsProps {
  type: 'raise' | 'lower';
}

const content = {
  raise: [
    {
      title: 'Decrease the amount of carbohydrates that you are eating:',
      body: 'Avoid foods that are high in carbohydrate such as grains, rice, pastas, beans, peas, corn, peanuts or potatoes. Talk to your study dietitian about the right carbohydrate limit per meal for you.',
      i18nKey:
        'dataVisualization:bhb.recommendations.content.raise.decreaseCarbohydrates',
    },
    {
      title: 'Eat more fat:',
      body: 'Fat sources include oils (olive oil, canola, sesame, coconut, avocado oil, etc.), butter, heavy cream, olive, ghee, and high-fat, cream-based dressings such as Primal Kitchen. Think about how to incorporate fats creatively such as drizzling olive oil over vegetables as a finish, adding a pat of butter on top of your meat, etc. Talk to your study dietitian for more ideas.',
      i18nKey: 'dataVisualization:bhb.recommendations.content.raise.eatMoreFat',
    },
  ],
  lower: [
    {
      title: 'Avoid long periods of fasting:',
      body: '(i.e. long periods where you do not eat anything / eat very little);',
      i18nKey:
        'dataVisualization:bhb.recommendations.content.lower.avoidFasting',
    },
    {
      title: 'Increase water intake:',
      body: 'Try to drink up about 60-100 oz of water per day;',
      i18nKey:
        'dataVisualization:bhb.recommendations.content.lower.increaseWaterIntake',
    },
    {
      title: 'Try to eat more:',
      body: 'Speak with your study dietitian if you are struggling to eat;',
      i18nKey: 'dataVisualization:bhb.recommendations.content.lower.eatMore',
    },
    {
      title: 'Add ~10g of carbohydrate to your meal plan daily:',
      body: 'Examples of 10g of carbohydrate would include the following: 2 Tbsp of chopped apple, 1 oz or 23 whole almonds. Speak to your study dietitian for additional options.',
      i18nKey:
        'dataVisualization:bhb.recommendations.content.lower.addCarbohydrates',
    },
  ],
};

const BHBRecommendations: FC<BHBRecommendationsProps> = ({ type }) => {
  return (
    <DefaultBasePage
      title={`How to ${type} your BHB`}
      contentSize="small"
      i18nKeyTitle={`dataVisualization:bhb.clickRecommendations.${type}.title`}
    >
      <Stack spacing={4}>
        <Typography
          i18nKey={`dataVisualization:bhb.recommendations.title.${type}`}
        >
          {`Here are tips for how to ${type} your BHB levels.`}
        </Typography>
        <Divider sx={{ borderColor: grey[700] }} />
        {type === 'raise' && (
          <Typography i18nKey="dataVisualization:bhb.recommendations.message.raise">
            It is important for you to remain in the suggested BHB range in
            order to keep you safe on study.
          </Typography>
        )}
        <Typography
          fontWeight="bold"
          i18nKey="dataVisualization:bhb.recommendations.whatToDo"
        >
          What to do:
        </Typography>
        <ul style={{ paddingInlineStart: 25 }}>
          {content[type].map((item, index) => (
            <li key={index}>
              <Typography i18nKey={item.i18nKey}>
                {`<strong>${item.title}</strong> ${item.body}`}
              </Typography>
            </li>
          ))}
        </ul>
        {type === 'lower' && (
          <Typography i18nKey="dataVisualization:bhb.recommendations.message.lower">
            {
              'If you feel any of the following symptoms <strong> - nausea, vomiting, urinating more than usual, or experiencing fatigue or confusion - </strong> please contact your hospital center immediately.'
            }
          </Typography>
        )}
        <Typography i18nKey="dataVisualization:bhb.recommendations.report">
          Report your out-of-range BHB levels to your doctor at your next study
          visit.
        </Typography>
      </Stack>
    </DefaultBasePage>
  );
};

export default BHBRecommendations;
