import { useMemo } from 'react';
import {
  DeliveryStatus,
  useGetDeliveryV2Query,
} from '@fdha/graphql-api-patient';
import { useParams } from 'react-router';
import { DefaultBasePage } from '@components';

import EditDelivery from './editDelivery/EditDelivery';
import ViewDelivery from './viewDelivery/ViewDelivery';

const Delivery = () => {
  const params = useParams();

  const deliveryId = params.id || '';

  const { data: deliveryData, loading: deliveryLoading } =
    useGetDeliveryV2Query({
      variables: {
        deliveryId,
      },
      skip: !deliveryId,
      fetchPolicy: 'cache-and-network',
    });

  const isCanceled = deliveryData?.deliveryV2?.isCanceled;

  const isDeliveryClosed =
    deliveryData?.deliveryV2?.status === DeliveryStatus.Closed;

  const showDeliveryPreview = useMemo(() => {
    return isDeliveryClosed || isCanceled;
  }, [isDeliveryClosed, isCanceled]);

  const isoDeliveryDate = deliveryData?.deliveryV2?.deliveryDate;

  const headerTitle = useMemo(() => {
    if (!showDeliveryPreview) {
      return {
        i18nKey: `temporary:meals.editDelivery.title`,
        i18nParams: { date: isoDeliveryDate || '' },
        fallback: `Delivery expected for ${isoDeliveryDate}`,
      };
    }

    const keySuffix = isCanceled ? 'canceledTitle' : 'title';
    const action = isCanceled ? 'canceled' : 'expected';

    return {
      i18nKey: `temporary:meals.deliveryPreview.${keySuffix}`,
      i18nParams: { date: isoDeliveryDate || '' },
      fallback: `Delivery ${action} for ${isoDeliveryDate}`,
    };
  }, [isoDeliveryDate, showDeliveryPreview, isCanceled]);

  return (
    <DefaultBasePage
      title={headerTitle.fallback}
      i18nKeyTitle={headerTitle.i18nKey}
      i18nTitleParams={headerTitle.i18nParams}
      isLoading={deliveryLoading}
      contentSize={deliveryLoading || showDeliveryPreview ? 'small' : 'large'}
    >
      {showDeliveryPreview ? (
        <ViewDelivery />
      ) : (
        <EditDelivery deliveryId={deliveryId} />
      )}
    </DefaultBasePage>
  );
};

export default Delivery;
