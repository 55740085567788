import { useReactiveVar } from '@apollo/client';
import { ActivityDetailsCardV2, Typography } from '@fdha/web-ui-library';
import { Task } from '@fdha/graphql-api-patient';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { selectedActivity } from '@states';
import React, { FC } from 'react';

interface TaskDetailsProps {
  isCreatedByCoach: boolean;
  onUpdateTask: (data: Task, completed: boolean) => void;
}

export const TaskDetails: FC<TaskDetailsProps> = ({
  isCreatedByCoach,
  onUpdateTask,
}) => {
  const task = useReactiveVar(selectedActivity) as Task;

  const handleTaskStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const completed = e.target.checked;
    onUpdateTask(task, completed);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={task.completed}
            onChange={handleTaskStateChange}
            onClick={(event) => event.stopPropagation()}
          />
        }
        label={
          <Typography variant="h5" sx={{ overflowWrap: 'anywhere' }}>
            {task.title}
          </Typography>
        }
      />
      <ActivityDetailsCardV2.CreatedBy isCreatedByCoach={isCreatedByCoach} />
      <Box mt={2}>
        <ActivityDetailsCardV2.Details details={task.description} />
      </Box>
      <Grid container>
        <ActivityDetailsCardV2.Date date={task.time} />
        <ActivityDetailsCardV2.EndDate date={task.ends_at} />
        <ActivityDetailsCardV2.Time time={task.time} />
        <ActivityDetailsCardV2.Frequency
          frequency={task.frequency}
          weekDays={task.weekDays}
        />
        <ActivityDetailsCardV2.Reminder reminder={task.reminder} />
      </Grid>
    </>
  );
};
