import React, { FC } from 'react';
import {
  Task,
  TaskInput,
  useAddTaskMutation,
  useUnscheduleTaskMutation,
  useUpdateTaskMutation,
} from '@fdha/graphql-api-patient';
import { Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  ActivityFormActions,
  ActivityKind,
  ActivitySchema,
  activityValidationSchema,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { ApolloCache } from '@apollo/client';
import { useAnalytics, useTranslatedErrorMessages } from '@fdha/common-hooks';

import ActivityFormFields from '../Forms/ActivityFormFields';
import { buildInitialPayload, getSchemaInitialValues } from '../Forms/schema';

interface Props {
  data?: Task;
  onCancel: () => void;
}

const AddOrEditTask: FC<Props> = ({ data, onCancel }) => {
  const {
    requiredMessage,
    validDateMessage,
    validTimeMessage,
    endDateAfterStartMessage,
    endDateFutureMessage,
  } = useTranslatedErrorMessages();
  const isEditing = !!data;
  const [addTask] = useAddTaskMutation();
  const [unscheduleTask] = useUnscheduleTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const { showSnackbarV2 } = useSnackbar();
  const { closeDialog } = useDialog();
  const { analyticsClient } = useAnalytics();

  const initialValues = getSchemaInitialValues(data);

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const buildTaskPayload = (values: ActivitySchema): TaskInput => {
    return {
      ...buildInitialPayload(values),
      ends: values.ends,
    };
  };

  const saveTask = async (values: ActivitySchema) => {
    const payload = buildTaskPayload(values);
    if (data) {
      try {
        await updateTask({
          variables: { id: data.id, props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        showSnackbarV2({
          message: 'Changes Saved',
          severity: 'success',
          i18nKey: 'common:snackbar.changesSaved',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Update Task',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.task.errorUpdate',
        });
      }
    } else {
      try {
        const createdTask = await addTask({
          variables: { props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        analyticsClient?.track('Activity Created', {
          id: createdTask.data?.addTask.id,
          type: ActivityKind.Task,
        });
        showSnackbarV2({
          message: 'Task Created',
          severity: 'success',
          i18nKey: 'activities:addOrEdit.snackbar.task.created',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Create Task',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.task.errorCreate',
        });
      }
    }
    closeDialog();
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }

    try {
      await unscheduleTask({
        variables: { id: data.id },
        update(cache) {
          updateCache(cache);
        },
      });
      showSnackbarV2({
        message: 'Task Unscheduled',
        severity: 'success',
        i18nKey: 'activities:addOrEdit.snackbar.task.unscheduled',
      });
    } catch (error) {
      showSnackbarV2({
        message: 'Unable to Unschedule Task',
        severity: 'error',
        i18nKey: 'activities:addOrEdit.snackbar.task.unableUnschedule',
      });
    }
    closeDialog();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={activityValidationSchema({
          required: requiredMessage,
          date: validDateMessage,
          time: validTimeMessage,
          future: endDateFutureMessage,
          afterStart: endDateAfterStartMessage,
        })}
        onSubmit={saveTask}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <ActivityFormFields>
                <ActivityFormFields.Title />
                <ActivityFormFields.Details />
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={2}
                >
                  <ActivityFormFields.DateInput />
                  <ActivityFormFields.Time />
                </Stack>
                <ActivityFormFields.Frequency />
                <ActivityFormFields.Reminder />
              </ActivityFormFields>
              <ActivityFormActions
                isEditing={isEditing}
                onUnschedule={handleUnschedule}
                disableSave={isSubmitting}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOrEditTask;
