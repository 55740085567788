import React from 'react';
import {
  ScheduledItemType,
  useAnswerDataSurveyBhbMutation,
  useAnswerSurveyMutation,
  useGetSurveyInstanceLazyQuery,
  useUpdateProfileMutation,
} from '@fdha/graphql-api-patient';
import { formatDate, useDialog, useSnackbar } from '@fdha/web-ui-library';
import { Steps } from '@pages';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  buildBhbDataSurveyInputPayload,
  buildSurveyViews,
  buildWelcomeSurveyViews,
  getAnswers,
} from '@utils';
import { useAnalytics } from '@fdha/common-hooks';

import { SurveyData } from '../steps/Steps';

import BHBSurveyDialog from './BHBSurveyDialog';

const SurveySteps = () => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { analyticsClient } = useAnalytics();
  const navigate = useNavigate();
  const params = useParams();

  const instanceId = params?.id || '';

  const [answerSurvey] = useAnswerSurveyMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [answerDataSurveyBhb] = useAnswerDataSurveyBhbMutation();

  const [getSurvey, { data, loading }] = useGetSurveyInstanceLazyQuery({
    variables: { instanceId, showDataSurveys: true },
    fetchPolicy: 'cache-and-network',
  });
  const isWelcome = instanceId === 'welcome';

  const isBHB = useMemo(
    () => data?.surveyInstance?.instanceType === ScheduledItemType.Bhb,
    [data?.surveyInstance?.instanceType]
  );
  const dueAtDate = data?.surveyInstance?.due_at || '';

  useEffect(() => {
    if (!isWelcome) {
      getSurvey();
    }
  }, [isWelcome, getSurvey]);

  const handleSubmitWelcomeSurvey = async (answers: any) => {
    const isSameAsCaregiver = answers.isPrimaryCheerleader === 'yes';

    await updateProfile({
      variables: {
        props: {
          cancer_type: answers.cancerDiagnosis,
          diag_details: answers.additionalInformation,
          diag_date: formatDate(answers.diagnosisDate?.date),
          diag_date_desc: answers.diagnosisDate?.radioOption,
          current_treatment: answers.currentTreatment,
          oncologist_name: answers.oncologistName,
          facility: {
            name: answers.facilityName,
            city: answers.facility?.text,
            state: answers.facility?.select,
          },
          caregiver: {
            primary_caregiver: answers.primaryCaregiver?.option,
            primary_caregiver_desc: answers.primaryCaregiver?.otherOption,
            name: answers.caregiverName,
          },
          cheerleader: {
            primary_cheerleader: isSameAsCaregiver
              ? answers.primaryCaregiver?.option
              : answers.primaryCheerleader?.option,
            primary_cheerleader_desc: isSameAsCaregiver
              ? answers.primaryCaregiver?.otherOption
              : answers.primaryCheerleader?.otherOption,
            name: isSameAsCaregiver
              ? answers.caregiverName
              : answers.cheerleaderName,
          },
          motivation: answers.motivation,
        },
      },
    });
  };

  const handleAnswerSurvey = async (values: any) => {
    const answers = getAnswers(values);

    await answerSurvey({
      variables: {
        instanceId,
        answers,
      },
    });
  };

  const handleAnswerDataSurvey = async (values: any) => {
    const bhbValues = values.bhb;
    const patientBhbAnswer = buildBhbDataSurveyInputPayload(
      instanceId,
      bhbValues.value,
      bhbValues.time,
      bhbValues.dayPeriod,
      dueAtDate
    );
    await answerDataSurveyBhb({
      variables: {
        bhbData: patientBhbAnswer,
      },
    });
    closeDialog();
  };

  const openBHBDialog = (values: any) => {
    openDialogV2({
      title: 'Please review this information is correct before submitting',
      content: <BHBSurveyDialog values={values} date={dueAtDate} />,
      cancelButtonLabel: 'Back',
      confirmButtonLabel: 'Submit',
      handleConfirm: () => handleSubmit(values),
      i18nKeyTitle: 'surveys:bhbSurvey.confirmationDialog.title',
    });
  };

  const handleSubmit = async (values: any) => {
    try {
      let segmentTrackingLog = {};
      if (isWelcome) {
        await handleSubmitWelcomeSurvey(values);
        segmentTrackingLog = { name: 'Welcome', type: 'welcome' };
      } else if (isBHB) {
        await handleAnswerDataSurvey(values);
        segmentTrackingLog = {
          id: instanceId,
          name: 'BHB',
          type: ScheduledItemType.Bhb,
        };
      } else {
        segmentTrackingLog = {
          id: instanceId,
          name: data?.surveyInstance?.name,
          type: data?.surveyInstance?.instanceType,
        };
        await handleAnswerSurvey(values);
      }
      analyticsClient?.track('Survey Completed', segmentTrackingLog);
      navigate('/surveys', { replace: true });
      showSnackbarV2({
        severity: 'success',
        message: 'Survey completed. Great job!',
        i18nKey: 'surveys:snackbar.surveyCompleted',
      });
    } catch (error) {
      console.error(error);
      showSnackbarV2({
        severity: 'error',
        message: 'Error to answer survey',
        i18nKey: 'surveys:snackbar.errorAnswerSurvey',
      });
    }
  };

  const views = useMemo(() => {
    if (isWelcome) {
      return buildWelcomeSurveyViews();
    }

    if (!data?.surveyInstance) {
      return;
    }
    return buildSurveyViews(data.surveyInstance);
  }, [data?.surveyInstance, isWelcome]);

  const surveyData = useMemo(() => {
    let surveyData: SurveyData;

    if (isWelcome) {
      surveyData = { title: 'Welcome', i18nKeyTitle: 'surveys:welcome' };
    } else {
      surveyData = {
        title: data?.surveyInstance?.name,
        date: data?.surveyInstance?.due_at
          ? Number(data?.surveyInstance?.due_at)
          : undefined,
        i18nKeySubtitle: data?.surveyInstance?.due_at
          ? 'surveys:steps.subtitle'
          : '',
        i18nSubtitleParams: {
          date: data?.surveyInstance?.due_at || '',
        },
      };
    }

    return surveyData;
  }, [data?.surveyInstance?.due_at, data?.surveyInstance?.name, isWelcome]);

  return (
    <Steps
      surveyData={surveyData}
      views={views}
      loading={loading}
      handleSubmit={(values) =>
        isBHB ? openBHBDialog(values) : handleSubmit(values)
      }
    />
  );
};

export default SurveySteps;
