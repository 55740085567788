import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import { LoadMore } from '@fdha/web-ui-library';
import { RecipeBase, useGetRecipesV2Query } from '@fdha/graphql-api-patient';
import { Grid } from '@mui/material';

import EmptyState from '../EmptyState';
import RecipeCard from '../RecipeCard';
import LoadingState from '../LoadingState';

const Recipes = () => {
  const { data, networkStatus, fetchMore } = useGetRecipesV2Query({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const recipes = data?.recipesV2.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.recipesV2.pageInfo;
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const showEmptyState = !recipes.length && !isLoading;

  const renderItem = (item: RecipeBase) => {
    const { id, name, recipeType, coverImage, video } = item;

    return (
      <Grid item xs={6} md={4} key={item.id}>
        <RecipeCard
          id={id}
          title={name}
          type={recipeType}
          video={video}
          coverImage={coverImage}
        />
      </Grid>
    );
  };

  const loadMore = () => {
    if (!pageInfo?.hasNextPage || isLoadingMore) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    });
  };

  return (
    <DefaultBasePage
      title="Recipes"
      contentSize="large"
      i18nKeyTitle="learning:recipe.title"
    >
      {!showEmptyState ? (
        <Grid container rowSpacing={2} columnSpacing={3}>
          {isLoading ? (
            <LoadingState type="recipe" height="202px" amount={8} />
          ) : (
            <>
              {recipes.map(renderItem)}
              <LoadMore
                onLoadMore={loadMore}
                listLength={recipes.length}
                isLoadingMore={isLoadingMore}
              />
            </>
          )}
        </Grid>
      ) : (
        <EmptyState type="recipe" />
      )}
    </DefaultBasePage>
  );
};

export default Recipes;
