import { TimeInputV2 } from '@fdha/web-ui-library';
import { TimeQuestionProps } from '@models';
import { useFormikContext } from 'formik';
import React from 'react';

interface TimeViewProps {
  question: TimeQuestionProps;
  handleChange: (value: TimeValue) => void;
}

interface TimeValue {
  time: '';
  dayPeriod: 'am' | 'pm';
}

interface Context {
  [key: string]: TimeValue;
}

export const TimeView: React.FC<TimeViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, errors, touched, setFieldTouched } =
    useFormikContext<Context>();

  const key = question.id;

  const time = values[key].time;
  const dayPeriod = values[key].dayPeriod;

  const error = touched[key]?.time && errors[key]?.time;

  const handleChangeTimeOrPeriod = (field: string, value: string) => {
    handleChange({ ...values[key], [field]: value });
  };

  const handleBlur = () => {
    setFieldTouched(`${[key]}.time`, true, true);
    setFieldTouched(`${[key]}.dayPeriod`, true, true);
  };

  return (
    <TimeInputV2
      title=""
      time={time}
      onChange={(field, value) => handleChangeTimeOrPeriod(field, value)}
      onBlur={handleBlur}
      dayPeriod={dayPeriod}
      error={error}
    />
  );
};
