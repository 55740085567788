export const getPatientDeepLink = (
  environment: string,
  path: string = 'home'
): string => {
  let origin = `https://devfaethapp.page.link`;

  if (environment === 'prod') {
    origin = 'https://faeth.page.link';
  } else if (['qa', 'stage'].includes(environment)) {
    origin = `https://${environment}faethapp.page.link`;
  }
  return `${origin}/${path}`;
};
