import {
  PasswordRule,
  PasswordRuleUtils,
  PrivacyPolicyUtils,
  TermsAndConditionsUtils,
} from '@fdha/web-auth';
import {
  PasswordTextFieldV2,
  TextFieldV2,
  Typography,
} from '@fdha/web-ui-library';
import { Checkbox, FormControlLabel, Link, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { CreateAccountResponse } from './createAccountScreens';

interface LoginInfoProps {
  rulesErrorList?: string[];
}

const LoginInfo: FC<LoginInfoProps> = ({ rulesErrorList }) => {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<CreateAccountResponse>();

  const [passwordHint, setPasswordHint] = useState<PasswordRule[] | undefined>(
    undefined
  );
  const [terms, setTerms] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const emailError = touched.email ? errors.email : null;
  const passwordError = touched.password ? errors.password : null;
  const confirmPasswordError = touched.confirmPassword
    ? errors.confirmPassword
    : null;
  const showRulesError = touched.password && !!rulesErrorList?.length;

  useEffect(() => {
    let isMounted = true;

    const getTerms = async () => {
      const termsLink = await TermsAndConditionsUtils.getTermsLink();
      if (isMounted) {
        setTerms(termsLink);
      }
    };
    const getPrivacyPolicy = async () => {
      const privacyPolicyLink = await PrivacyPolicyUtils.getPolicyLink();
      if (isMounted) {
        setPrivacyPolicy(privacyPolicyLink);
      }
    };
    const getPasswordHint = async () => {
      const hintMessage = await PasswordRuleUtils.getPasswordHintMessageV2();
      if (isMounted) {
        setPasswordHint(hintMessage);
      }
    };

    if (!passwordHint) {
      getPasswordHint();
    }
    if (!privacyPolicy) {
      getPrivacyPolicy();
    }
    if (!terms) {
      getTerms();
    }

    return () => {
      isMounted = false;
    };
  }, [passwordHint, privacyPolicy, terms]);

  const getLink = (link: string) => <Link href={link} target="_blank" />;

  const termsLink = getLink(terms);
  const privacyLink = getLink(privacyPolicy);

  const getCheckBoxLabel = () => {
    return (
      <Typography
        variant="subtitle1"
        i18nKey="login:createAccount.step2.terms.agree"
        i18nComponents={{ termsLink, privacyLink }}
      >
        {`I agree to the <termsLink>terms</termsLink> and <privacyLink>privacy policy</privacyLink>.`}
      </Typography>
    );
  };

  return (
    <>
      <Stack spacing={2} mb={2}>
        <TextFieldV2
          name="email"
          title="Email"
          i18nKeyTitle="login:input.email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          type="email"
          error={!!emailError}
          helperText={emailError}
        />
        <PasswordTextFieldV2
          name="password"
          title="Password"
          i18nKeyTitle="login:input.password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!passwordError}
          helperText={passwordError}
          passwordHint={passwordHint}
          rulesErrorList={rulesErrorList}
          showRulesError={showRulesError}
        />
        <PasswordTextFieldV2
          name="confirmPassword"
          title="Confirm Password"
          i18nKeyTitle="login:createAccount.step2.input.confirmPassword.label"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
        />
      </Stack>
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        name="rememberMe"
        checked={values.rememberMe}
        onChange={handleChange}
        label={
          <Typography i18nKey="login:keepMeLoggedIn" variant="inherit">
            Keep me logged in
          </Typography>
        }
      />
      <FormControlLabel
        sx={{ alignItems: 'flex-start' }}
        control={<Checkbox color="secondary" />}
        name="acceptedSms"
        checked={values.acceptedSms}
        onChange={handleChange}
        label={
          <Typography
            i18nKey="login:createAccount.step2.agreeSms"
            variant="inherit"
          >
            I agree to receive SMS messages from Faeth. Message and data rates
            may apply.
          </Typography>
        }
      />
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        name="acceptedTos"
        checked={values.acceptedTos}
        onChange={handleChange}
        label={getCheckBoxLabel()}
      />
    </>
  );
};

export default LoginInfo;
