import React from 'react';
import { Article, useGetArticlesQuery } from '@fdha/graphql-api-patient';
import { Box, Grid } from '@mui/material';

import EmptyState from '../EmptyState';
import ArticleCard from '../ArticleCard';
import LoadingState from '../LoadingState';

import Header from './Header';

const ArticleSection = () => {
  const { data, loading } = useGetArticlesQuery({
    variables: { limit: 3 },
    fetchPolicy: 'network-only',
  });

  const articles = data?.articles || [];

  const showEmptyStateArticles = !articles.length && !loading;
  const showSeeAll = !loading && !!articles.length;

  const renderItem = (item: Article) => {
    const { title, createdBy, coverImage, id, url } = item;
    return (
      <Grid item xs={6} md={4} key={item.id}>
        <ArticleCard
          title={title}
          createdBy={createdBy}
          coverImage={coverImage}
          id={id}
          url={url ?? undefined}
        />
      </Grid>
    );
  };

  return (
    <Box>
      <Header
        title="Explore"
        route="/learning/articles"
        showSeeAll={showSeeAll}
        i18nKey="learning:article.title"
      />
      {!showEmptyStateArticles ? (
        <Grid container rowSpacing={2} columnSpacing={3}>
          {loading ? (
            <LoadingState type="article" height="202px" amount={3} />
          ) : (
            articles.map((item) => renderItem(item))
          )}
        </Grid>
      ) : (
        <EmptyState type="article" />
      )}
    </Box>
  );
};

export default ArticleSection;
