import { BhbDataSurveyInput } from '@fdha/graphql-api-patient';
import { formatUTCDate } from '@fdha/web-ui-library';
import { QuestionProps, QuestionType } from '@models';
import { startOfDay } from 'date-fns';

export const getBHBQuestion = (date?: string): QuestionProps => {
  const isoDate = new Date(Number(date)).toISOString();
  return {
    id: 'bhb',
    title: 'BHB value',
    required: true,
    type: QuestionType.BHB,
    subtitle: `Enter a BHB value collected on ${formatUTCDate(
      isoDate,
      'shortMonth'
    )}.`,
    initialValue: {
      time: '',
      value: '',
      dayPeriod: 'am',
    },
  };
};

export const buildBhbDataSurveyInputPayload = (
  instanceId: string,
  bhbValue: string,
  measurementTime: string,
  measurementPeriod: 'am' | 'pm',
  measuredDate: string
): BhbDataSurveyInput => {
  const [hours, minutes] = measurementTime.split(':');
  const shiftedHour = Number(hours) === 12 ? 0 : Number(hours);
  const formattedHour =
    measurementPeriod === 'pm' ? shiftedHour + 12 : shiftedHour;
  const measuredDateAndTime = startOfDay(Number(measuredDate));
  measuredDateAndTime.setHours(formattedHour, Number(minutes), 0);

  return {
    instance_id: instanceId,
    bhb_value: Number(bhbValue),
    measured_at: measuredDateAndTime,
  };
};
