import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Icon } from '@fdha/web-ui-library';
import {
  useChatContext,
  ChannelPreviewUIComponentProps,
} from 'stream-chat-react';
import { displayProtectedImage } from '@fdha/web-auth';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChannelType } from '@utils';
import { ChatScreenType, setSelectedChatScreenType } from '@states';
import { UserResponse } from 'stream-chat';
import {
  DefaultUserType,
  DefaultUserTypeInternal,
} from 'stream-chat-react/dist/types/types';

import {
  ChatName,
  LeftContainer,
  MessagePreview,
  MiddleContainer,
  RightContainer,
  TimeStamp,
  UnreadBadge,
  PreviewItem,
} from './styles';

interface PreviewProps extends ChannelPreviewUIComponentProps {
  customActiveChannel?: string;
}

type LastReadList = {
  last_read: Date;
  user: UserResponse<DefaultUserType<DefaultUserTypeInternal>>;
}[];

const isRead = (
  otherMembersLastRead: LastReadList,
  lastMessageAt: Date | ''
) => {
  let visualizedByAll = true;
  otherMembersLastRead.forEach((member) => {
    if (!(member?.last_read > lastMessageAt)) {
      visualizedByAll = false;
    }
  });

  return visualizedByAll;
};

const Preview: React.FC<PreviewProps> = ({
  channel,
  lastMessage,
  setActiveChannel,
  customActiveChannel,
}) => {
  const theme = useTheme();
  const { client } = useChatContext();
  const unreadCount = channel.countUnread();
  const [thisLastRead, setThisLastRead] = useState(false);

  const otherMembers = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID
  );

  const otherMembersLastRead = Object.values(channel.state.read).filter(
    ({ user }) => user?.id !== client.userID
  );

  const lastMessageAt = channel.state.last_message_at ?? '';

  const messagingDisplayName = useMemo(() => {
    return otherMembers.map((member) => member?.user?.name).join(', ');
  }, [otherMembers]);

  const displayReadIcon =
    channel.type === ChannelType.Messaging &&
    lastMessage?.user?.id === client.userID;
  const displayAvatar =
    channel.type === ChannelType.Messaging
      ? otherMembers[0]?.user?.image
      : null;
  const displayName =
    channel.type === ChannelType.Broadcast
      ? channel?.data?.name
      : messagingDisplayName;
  const displayPresence =
    channel.type === ChannelType.Messaging
      ? otherMembers[0]?.user?.online
        ? 'online'
        : 'offline'
      : undefined;
  const avatarIconName =
    channel.type === ChannelType.Broadcast
      ? displayName
      : otherMembers[0]?.user?.name;

  useEffect(() => {
    if (customActiveChannel && customActiveChannel === channel?.id) {
      setActiveChannel?.(channel);
    }
  }, [customActiveChannel, channel, setActiveChannel]);

  useEffect(() => {
    if (
      channel.type === ChannelType.Messaging &&
      lastMessage?.user?.id === client.userID
    ) {
      setThisLastRead(isRead(otherMembersLastRead, lastMessageAt));
    }
  }, [
    channel.type,
    client.userID,
    lastMessage?.user?.id,
    lastMessageAt,
    otherMembersLastRead,
  ]);

  const getTimeStamp = () => {
    let lastHours = channel.state.last_message_at?.getHours();
    let lastMinutes: string | number | undefined =
      channel.state.last_message_at?.getMinutes();
    let half = 'AM';

    if (lastHours === undefined || lastMinutes === undefined) {
      return '';
    }

    if (lastHours > 12) {
      lastHours = lastHours - 12;
      half = 'PM';
    }

    if (lastHours === 0) lastHours = 12;
    if (lastHours === 12) half = 'PM';

    if (lastMinutes.toString().length === 1) {
      lastMinutes = `0${lastMinutes}`;
    }

    return `${lastHours}:${lastMinutes} ${half}`;
  };

  return (
    <>
      <Box paddingLeft={0.5}>
        <PreviewItem
          onClick={() => {
            setActiveChannel?.(channel);
            setSelectedChatScreenType(ChatScreenType.Channel);
          }}
        >
          <LeftContainer>
            <Avatar
              picture={displayAvatar}
              name={avatarIconName}
              presence={displayPresence}
              downloadPicture={displayProtectedImage}
            />
          </LeftContainer>

          <MiddleContainer>
            <ChatName variant="h6" paddingTop={lastMessage ? 0 : 1.2}>
              {displayName}
            </ChatName>
            {lastMessage?.text && (
              <MessagePreview
                noWrap
                variant="subtitle1"
                color={theme.palette.text.secondary}
              >
                {lastMessage.text}
              </MessagePreview>
            )}
          </MiddleContainer>
          <RightContainer>
            <Stack direction="row" alignItems="center" spacing="1px">
              {displayReadIcon && (
                <Icon
                  name={thisLastRead ? 'done-all-outline' : 'checkmark-outline'}
                  size="medium"
                  fill={theme.palette.info.main}
                />
              )}
              <TimeStamp noWrap variant="body2">
                {getTimeStamp()}
              </TimeStamp>
            </Stack>
            {!!unreadCount && (
              <UnreadBadge badgeContent={unreadCount} color="error" />
            )}
          </RightContainer>
        </PreviewItem>
      </Box>
      <Divider style={{ borderColor: grey[300] }} />
    </>
  );
};

export default Preview;
