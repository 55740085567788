import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '@fdha/common-utils';
import fdhaConfig from '@fdha/aws-config-patient';
import { createUploadLink } from 'apollo-upload-client';
import { Reference, relayStylePagination } from '@apollo/client/utilities';

const httpLink = createUploadLink({
  uri: fdhaConfig.api.patientApiBaseUrl,
  headers: {
    'x-api-key': fdhaConfig.api.apiKey,
    'apollo-require-preflight': true,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

export const apolloClientConfig = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Goal: {
        keyFields: ['instanceId'],
      },
      Task: {
        keyFields: ['instanceId'],
      },
      Appointment: {
        keyFields: ['instanceId'],
      },
      GoalStep: {
        keyFields: false,
      },
      Comment: {
        fields: {
          isPersisted: {
            read(existing) {
              if (existing == null) {
                return true;
              }

              return existing;
            },
          },
        },
      },
      PostWithComments: {
        keyFields: ['__typename'],
      },
      Post: {
        fields: {
          isPersisted: {
            read(existing) {
              if (existing == null) {
                return true;
              }

              return existing;
            },
          },
        },
      },
      PostList: {
        keyFields: ['__typename'],
        fields: {
          posts: {
            merge(existing, incoming, { readField }) {
              const incomingNoDups = incoming.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing?.some((existingItem: Reference) => {
                    const existingItemId = readField<string>(
                      'id',
                      existingItem
                    );
                    return incomingItemId === existingItemId;
                  });
                }
              );

              return existing ? [...existing, ...incomingNoDups] : incoming;
            },
            read(existing) {
              return existing;
            },
          },
        },
      },
      Query: {
        fields: {
          availableSurveys: relayStylePagination(),
          learningModules: relayStylePagination(),
          recipesV2: relayStylePagination(),
          apiKeys: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing ? { ...existing, ...incoming } : incoming;
            },
          },
          articles: {
            keyArgs: false,
            merge(existing = [], incoming, { args, readField }) {
              // When offset is 0 or undefined, it means data is being
              // fetched from the beginning. So, cache is cleared to start
              // fresh again
              if (!args?.offset) {
                existing = [];
              }

              const incomingNoDups = incoming?.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing?.some((existingItem: Reference) => {
                    const existingItemId = readField<string>(
                      'id',
                      existingItem
                    );
                    return incomingItemId === existingItemId;
                  });
                }
              );

              return [...existing, ...incomingNoDups];
            },
          },
          channelAttachments: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing
                ? {
                    next: incoming.next,
                    attachments: [
                      ...existing.attachments,
                      ...incoming.attachments,
                    ],
                  }
                : incoming;
            },
          },
          conferencesHistory: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing
                ? {
                    next: incoming.next,
                    conferences: [
                      ...existing.conferences,
                      ...incoming.conferences,
                    ],
                  }
                : incoming;
            },
          },
          communityNotifications: {
            keyArgs: false,
            merge(existing, incoming, { readField }) {
              const incomingNoDups = incoming.notifications.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing?.notifications?.some(
                    (existingItem: Reference) => {
                      const existingItemId = readField<string>(
                        'id',
                        existingItem
                      );
                      return incomingItemId === existingItemId;
                    }
                  );
                }
              );

              return existing
                ? {
                    hasNextPage:
                      existing?.hasNextPage === null
                        ? null
                        : incoming.hasNextPage,
                    notifications: [
                      ...existing.notifications,
                      ...incomingNoDups,
                    ],
                  }
                : incoming;
            },
          },
        },
      },
    },
  }),
};
