import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Channel } from 'stream-chat-react';
import {
  ChatScreenType,
  selectedChatScreenType,
  setSelectedChatScreenType,
} from '@states';

import ChannelInner from '../ChannelInner';
import ChatDetails from '../chatDetails/ChatDetails';
import MessageInput from '../messageInput/MessageInput';

import MessageAvatar from './MessageAvatar';
import StyledChannelWrapper from './styles';

export type ChannelContainerProps = {
  showMessageInput: boolean;
  preDefinedMessage?: string;
};

const ChannelContainer: React.FC<ChannelContainerProps> = (props) => {
  const navigate = useNavigate();
  const selectedKind = useReactiveVar(selectedChatScreenType);

  useEffect(() => {
    return () => {
      setSelectedChatScreenType(ChatScreenType.ChannelList);

      // Clears any state that "/chat" route contain
      navigate('', {});
    };
  }, [navigate]);

  const MessageInputWrapper = () => {
    return <MessageInput preDefinedMessage={props.preDefinedMessage} />;
  };

  return (
    <StyledChannelWrapper data-testid="CHAT_GRID">
      <Channel Avatar={MessageAvatar} Input={MessageInputWrapper} {...props}>
        {selectedKind === ChatScreenType.Channel ? (
          <ChannelInner showMessageInput={props.showMessageInput} />
        ) : (
          <ChatDetails />
        )}
      </Channel>
    </StyledChannelWrapper>
  );
};

export default ChannelContainer;
