import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  useTheme,
} from '@mui/material';
import { Icon, Typography } from '@fdha/web-ui-library';

interface LearningCardProps {
  type: 'article' | 'recipe';
  title: string;
  subtitle?: string | null;
  coverImage: string;
  showVideoIcon?: boolean;
  i18nKey?: string;
  i18nParams?: { [key: string]: string };
  onClick: () => void;
}

const LearningCard: React.FC<LearningCardProps> = ({
  type,
  title,
  subtitle,
  coverImage,
  showVideoIcon,
  i18nKey,
  i18nParams,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Card
      variant="elevation"
      sx={{
        borderRadius: '6px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <CardActionArea onClick={onClick}>
        {showVideoIcon && (
          <Box
            position="absolute"
            width="100%"
            display="flex"
            justifyContent="flex-end"
            padding={2}
          >
            <Box
              display="flex"
              alignItems="center"
              borderRadius="2px"
              padding="5px"
              sx={{ background: 'rgba(38, 38, 38, 0.65)' }}
            >
              <Icon
                name="video"
                fill={theme.palette.background.paper}
                size="medium"
              />
            </Box>
          </Box>
        )}
        <CardMedia
          component="img"
          height="112"
          image={coverImage}
          alt={`${type} image`}
          sx={{ borderRadius: '6px' }}
        />
        <CardContent sx={{ padding: '8px 4px' }}>
          <Typography variant="body1">{title}</Typography>
          <Typography
            color={theme.palette.text.secondary}
            variant="body2"
            i18nKey={i18nKey}
            i18nParams={i18nParams}
          >
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default LearningCard;
