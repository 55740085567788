import React from 'react';
import { Box, Card, CardActionArea, Tooltip, useTheme } from '@mui/material';
import {
  MenuItem,
  IconMenu,
  formatDate,
  Typography,
} from '@fdha/web-ui-library';
import { DocumentInfo } from '@fdha/graphql-api-patient';
import { FileIcon } from 'react-file-utils';

interface DocumentCardProps {
  document: DocumentInfo;
  handleDownload: (id: string, uploadedBy?: string) => void;
  handleDelete?: (id: string) => Promise<void>;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  document,
  handleDownload,
  handleDelete,
}) => {
  const theme = useTheme();

  const onDownload = () => {
    const uploadedBy = document.is_patient_file ? 'Patient' : 'Coach';
    handleDownload(document.id, uploadedBy);
  };

  const menuItems: MenuItem[] = [
    {
      testId: 'DOWNLOAD_DOC_OPTION',
      label: 'Download',
      i18nKeyLabel: 'profile:documents.fileActions.download',
      icon: 'download-outline',
      handleClick: onDownload,
    },
    ...(handleDelete && document.is_patient_file
      ? [
          {
            testId: 'DELETE_DOC_OPTION',
            label: 'Delete',
            i18nKeyLabel: 'profile:documents.fileActions.delete',
            icon: 'trash-2-outline',
            handleClick: () => handleDelete(document.id),
          },
        ]
      : []),
  ];

  const updatedAt = new Date(document.updated_at);
  const creator = document.is_patient_file ? 'you' : document.creator.name;
  const caption = `${formatDate(updatedAt, 'monthDayYear')} by ${creator}`;

  return (
    <Card
      sx={{
        boxShadow: theme.shadows[2],
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <Tooltip title={document.filename}>
          <CardActionArea
            sx={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-start',
              overflow: 'hidden',
            }}
            onClick={onDownload}
          >
            <FileIcon mimeType={document.mimetype || ''} big size={46} />
            <Box ml={2} textOverflow="ellipsis" overflow="hidden" width="80%">
              <Typography variant="body1" noWrap>
                {document.filename}
              </Typography>
              <Typography
                variant="caption"
                i18nKey={
                  document.is_patient_file
                    ? 'profile:documents.uploadByYou'
                    : 'profile:documents.uploadBy'
                }
                i18nParams={{
                  date: updatedAt.toISOString(),
                  name: document.creator.name,
                }}
              >
                {caption}
              </Typography>
            </Box>
          </CardActionArea>
        </Tooltip>
        <IconMenu items={menuItems} menuIcon="more-horizontal-outline" />
      </Box>
    </Card>
  );
};

export default DocumentCard;
