import { QuizItem } from '@fdha/graphql-api-patient';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Button, Typography } from '@fdha/web-ui-library';

interface Answers {
  [key: string]: { value: string[] };
}

export type finishAction = 'continue' | 'reset';

interface CourseQuizResultsProps {
  questions: QuizItem[];
  answers: Answers;
  nextButton: { label: string; i18nKey?: string };
  onFinish: (action: finishAction) => void;
}

const CourseQuizResults: React.FC<CourseQuizResultsProps> = ({
  questions,
  answers,
  nextButton,
  onFinish,
}) => {
  const theme = useTheme();

  const [correctAnswers, setCorrectAnswers] = useState(0);

  useEffect(() => {
    let correctAnswersCount = 0;

    questions.forEach((question) => {
      const answer = answers?.[question.id]?.value;

      const answerSorted = [...answer]?.sort();
      const correctAnswersSorted = [...question.correctAnswers].sort();

      if (isEqual(correctAnswersSorted, answerSorted)) {
        correctAnswersCount++;
      }
    });

    setCorrectAnswers(correctAnswersCount);
  }, [answers, questions]);

  return (
    <Box
      display="flex"
      height="100%"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Box width="520px" marginX="auto">
        <Grid
          container
          direction="column"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexWrap="nowrap"
        >
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography
              variant="h4"
              color={theme.palette.primary.contrastText}
              textAlign="center"
              i18nKey="learning:course.quizCompleted"
            >
              Quiz completed
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.primary.contrastText}
              textAlign="center"
              i18nKey="learning:course.score"
              i18nParams={{
                correct: correctAnswers,
                questions: questions.length,
              }}
            >
              {`Score: ${correctAnswers}/${questions.length}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2} marginBottom="111px">
              <Button
                variant="contained"
                color="warning"
                size="large"
                i18nKey={nextButton.i18nKey}
                onClick={() => onFinish('continue')}
              >
                {nextButton.label}
              </Button>
              <Button
                variant="text"
                size="large"
                sx={{ color: theme.palette.primary.contrastText }}
                i18nKey="learning:course.tryAgain"
                onClick={() => onFinish('reset')}
              >
                Try Again
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CourseQuizResults;
