import {
  ListCommunityNotificationsQuery,
  useCommunityMarkNotificationAsReadMutation,
} from '@fdha/graphql-api-patient';
import {
  Button,
  EmptyStateCard,
  NotificationItem,
  Typography,
} from '@fdha/web-ui-library';
import { Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router';

interface RecentNotificationsProps {
  notificationsData?: ListCommunityNotificationsQuery;
}

const RecentNotifications = ({
  notificationsData,
}: RecentNotificationsProps) => {
  const navigate = useNavigate();

  const notifications =
    notificationsData?.communityNotifications.notifications || [];
  type CommunityNotificationGeneric = (typeof notifications)[0];

  const [communityMarkNotificationAsRead] =
    useCommunityMarkNotificationAsReadMutation();

  const handleNotificationClick = (item: CommunityNotificationGeneric) => {
    switch (item.__typename) {
      case 'CommunityNotificationComment':
      case 'CommunityNotificationRemoveComment':
      case 'CommunityNotificationRemovePost':
        if (!item.postId) {
          return;
        }

        navigate(`/community/${item.postId}`);
        break;

      default:
        navigate('/community');
        break;
    }

    communityMarkNotificationAsRead({ variables: { notificationId: item.id } });
  };

  const renderItem = (item: CommunityNotificationGeneric) => {
    const name =
      'commenterUsers' in item ? item.commenterUsers[0].name : undefined;
    const picture =
      'commenterUsers' in item ? item.commenterUsers[0].picture : undefined;

    return (
      <NotificationItem
        name={name}
        picture={picture}
        handleClick={() => handleNotificationClick(item)}
        read={item.isRead}
        time={item.time}
        type={item.__typename}
      />
    );
  };

  return (
    <Paper sx={{ padding: 'unset' }}>
      {notifications.length ? (
        <Box>
          <Box
            p={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h5">Notifications</Typography>
            <Button onClick={() => navigate('notifications')}>See All</Button>
          </Box>
          {notifications.map((item) => renderItem(item))}
        </Box>
      ) : (
        <EmptyStateCard
          title="No notifications yet"
          message="When you get notifications, they will show up here."
        />
      )}
    </Paper>
  );
};

export default RecentNotifications;
