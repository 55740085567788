import { Button, Typography, useSnackbar } from '@fdha/web-ui-library';
import { Paper, useTheme } from '@mui/material';
import { handleInviteFriends } from '@utils';

const EmptyState = () => {
  const theme = useTheme();
  const { showSnackbarV2 } = useSnackbar();

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography fontWeight="bold" color={theme.palette.text.disabled}>
        No posts to show
      </Typography>
      <Typography color={theme.palette.text.disabled}>
        Posts from you and other members will show up here. You can help our
        Community grow by sharing our app!
      </Typography>
      <Button
        variant="contained"
        startEvaIcon={{ name: 'person-add-outline' }}
        sx={{ mt: 3 }}
        onClick={() => handleInviteFriends(showSnackbarV2)}
      >
        Invite friends
      </Button>
    </Paper>
  );
};

export default EmptyState;
