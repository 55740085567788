import { DefaultBasePage } from '@components';
import { Button, RTEContentRender, Typography } from '@fdha/web-ui-library';
import {
  GetLearningModuleDocument,
  LearningItem,
  useCompleteLearningItemMutation,
} from '@fdha/graphql-api-patient';
import { Link, Stack } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

interface CourseContentProps {
  data: LearningItem;
  moduleId: string;
  isCompleted: boolean;
  nextButton: { label: string; i18nKey?: string };
  onNext: () => void;
  onPrevious: () => void;
}

const CourseContent: React.FC<CourseContentProps> = ({
  data,
  moduleId,
  isCompleted,
  nextButton,
  onNext,
  onPrevious,
}) => {
  const [completeLearningItem] = useCompleteLearningItemMutation();

  const maybeCompleteItem = useCallback(() => {
    const completeItem = async () => {
      try {
        await completeLearningItem({
          variables: { learningModuleId: moduleId, learningItemId: data.id },
          refetchQueries: [GetLearningModuleDocument],
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!data.quiz && !isCompleted) {
      completeItem();
    }
  }, [completeLearningItem, data.id, data.quiz, isCompleted, moduleId]);

  useEffect(() => {
    maybeCompleteItem();
  }, [maybeCompleteItem]);

  const content = data.content && JSON.parse(data.content);

  return (
    <DefaultBasePage title={data.title} contentSize="large">
      {data.externalContent ? (
        <Typography
          variant="body1"
          i18nKey="learning:course.externalContent"
          i18nParams={{
            url: data.externalContent,
          }}
          i18nComponents={{
            externalContentLink: (
              <Link href={data.externalContent} target="_blank">
                {data.externalContent}
              </Link>
            ),
          }}
        >
          {`Check out the learning material here: <externalContentLink>{{ url }}</externalContentLink>`}
        </Typography>
      ) : (
        content && <RTEContentRender richTextDocument={content} />
      )}
      <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={onPrevious}
          i18nKey="common:button.previous"
        >
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          i18nKey={nextButton.i18nKey}
        >
          {nextButton.label}
        </Button>
      </Stack>
    </DefaultBasePage>
  );
};

export default CourseContent;
