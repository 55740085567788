import React from 'react';
import { ImageBasePage } from '@components';
import { Link } from 'react-router-dom';
import { LoginV2 } from '@fdha/web-auth';
import { Box, Link as MuiLink } from '@mui/material';
import { useLocalStorage } from '@hooks';
import { Typography } from '@fdha/web-ui-library';
import { useSystemSetting } from '@fdha/common-hooks';

const Login = () => {
  const { saveToLocalStorage } = useLocalStorage('me', false);
  const { value: isSignupEnabled } =
    useSystemSetting<boolean>('isSignupEnabled');

  const handleRememberMe = (rememberMe: boolean, userId: string) => {
    saveToLocalStorage(
      {
        rememberMe,
        isAuthenticated: true,
        lastLogin: new Date(),
      },
      false,
      `pat_${userId}`
    );
  };

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Old Woman',
      }}
      i18nKeyTitle="login:title"
      i18nKeySubtitle="login:subtitle"
      title="Welcome back"
      titleVariant="h3"
      alignHeader="center"
      alignSubtitle="center"
      subtitle="Please enter your account information"
    >
      <LoginV2 handleRememberMe={handleRememberMe} />
      {isSignupEnabled && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            i18nKey="login:dontHaveAccount"
            variant="body1"
            marginTop={3}
          >
            Don't have an account yet?
          </Typography>
          <MuiLink component={Link} to="/create-account">
            <Typography i18nKey="login:createAccount.title">
              Create new account
            </Typography>
          </MuiLink>
        </Box>
      )}
    </ImageBasePage>
  );
};

export default Login;
