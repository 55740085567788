import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const LoadingState = () => {
  return (
    <Stack spacing={4}>
      <Skeleton variant="rectangular" height="360px" />
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height="24px" />
        <Skeleton variant="rectangular" height="24px" />
      </Stack>
      <Skeleton variant="rectangular" height="56px" />
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="191px" height="32px" />
        {[...Array(8)].map((_item, index) => (
          <Skeleton variant="rectangular" key={index} height="24px" />
        ))}
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="191px" height="32px" />
        {[...Array(8)].map((_item, index) => (
          <Skeleton variant="rectangular" key={index} height="24px" />
        ))}
      </Stack>
    </Stack>
  );
};

export default LoadingState;
