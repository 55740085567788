import { listeners } from '@utils';
import { useCallback } from 'react';
import { useGetProfileQuery } from '@fdha/graphql-api-patient';

import { useCryptography } from './useCryptography';
import { useReadLocalStorage } from './useReadLocalStorage';

export const useLocalStorage = (key: string, encrypted: boolean = true) => {
  const item = useReadLocalStorage(key, encrypted);
  const { encrypt } = useCryptography();

  const { data: profileData } = useGetProfileQuery();
  const userId = profileData?.me.id || '';

  const saveToLocalStorage = useCallback(
    async (data: Record<string, unknown>, clearData = false, id = userId) => {
      let newData = data;

      if (!clearData && item) {
        newData = {
          ...item,
          ...data,
        };
      }

      let encryptedData;
      if (encrypted) {
        encryptedData = await encrypt(JSON.stringify(newData));
      }

      localStorage.setItem(
        `${key}_${id}`,
        JSON.stringify(encrypted ? encryptedData : newData)
      );
      listeners.get(key)?.forEach((cb) => {
        cb(item);
      });
    },
    [encrypt, encrypted, item, key, userId]
  );

  const removeFromLocalStorage = useCallback(
    (deleteKey?: string) => {
      if (item) {
        if (deleteKey) {
          delete item[deleteKey];
          saveToLocalStorage(item, true);
        } else {
          saveToLocalStorage({}, true);
        }
      }
    },
    [item, saveToLocalStorage]
  );

  return {
    saveToLocalStorage,
    removeFromLocalStorage,
  };
};
