import React, { useEffect, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import {
  AddOrEditPostCardV2,
  postValidationSchema,
  useDialog,
  useSnackbar,
  WritePostSchema,
} from '@fdha/web-ui-library';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  CommunityRole,
  Post,
  PostStatus,
  useAddPostMutation,
  useGetCommunityUserQuery,
  useGetPostWithCommentsQuery,
  useUpdatePostMutation,
  useUploadCommunityPictureMutation,
} from '@fdha/graphql-api-patient';
import { DefaultBasePage } from '@components';
import { useFormik } from 'formik';
import { useAnalytics } from '@fdha/common-hooks';
import { uniqueId } from 'lodash';

import DiscardPostDialogContent from './DiscardPostDialogContent';

export interface StateProps {
  backRoute?: string;
  openImagePicker: boolean;
}

// The optimistic response requires that all fields from the referenced type
// exist when adding an item, while editing required only the originally required fields
type OptimisticResponseAddPost = Required<
  Omit<Post, 'restoredBy' | 'removedBy' | 'removedAt'>
>;
type OptimisticResponseEditPost = Omit<
  Post,
  'removedAt' | 'restoredBy' | 'removedBy'
>;

const AddOrEditPost = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as StateProps;
  const id = params.id ?? '';
  const openImagePicker = state?.openImagePicker;
  const backRoute = state?.backRoute;
  const isFromFeed = backRoute === '/community';

  const { openDialogV2, closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();

  const { analyticsClient } = useAnalytics();
  const [addPost] = useAddPostMutation();
  const [updatePost] = useUpdatePostMutation();
  const [uploadPicture] = useUploadCommunityPictureMutation();

  const { data: userData, loading: loadingUser } = useGetCommunityUserQuery();
  const user = userData?.getCommunityUser;

  const { data, loading } = useGetPostWithCommentsQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
  const postData = data?.postWithComments?.post;

  const initialValues: WritePostSchema = useMemo(() => {
    return {
      text: postData?.text || '',
      picture: postData?.pictures?.[0] || '',
    };
  }, [postData]);

  const isEditMode = !!id;
  const name = user?.name ?? '';
  const picture = user?.picture ?? undefined;
  const role = user?.role || CommunityRole.User;

  const isLoading = loading || loadingUser;

  const snackbarSuccessMsg = isEditMode
    ? 'Changes Saved'
    : 'New Post Published';

  const snackbarErrorMsg = isEditMode
    ? 'Unable to Edit Post'
    : 'Unable to Publish New Post';

  useEffect(() => {
    if (!isLoading && openImagePicker) {
      var input = document.getElementById('upload-image');
      input?.click();
    }
  }, [isLoading, openImagePicker]);

  const handleNavigationOnDiscardOrPublish = () => {
    if (isFromFeed) {
      navigate(-1);
    } else {
      navigate(`/community/${postData?.id}`, {
        state: {},
      });
    }
  };

  const handlePublish = async (values: WritePostSchema) => {
    if (user == null) {
      return;
    }

    handleNavigationOnDiscardOrPublish();

    const { text, picture } = values;
    const pictures = picture !== '' ? [picture] : null;
    const postText = text.trim();

    try {
      if (isEditMode) {
        if (postData == null) {
          return;
        }

        const editPostResponse: OptimisticResponseEditPost = {
          __typename: 'Post',
          id: postData.id,
          text: postText,
          pictures,
          isEdited: true,
          isPersisted: false,
          user,
          time: postData.time,
          numComments: postData.numComments,
          status: postData.status,
        };

        await updatePost({
          variables: {
            editPost: {
              id,
              text: postText,
              pictures,
            },
          },
          optimisticResponse: { updatePost: editPostResponse },
          update(cache, mutationResult) {
            const post = mutationResult.data?.updatePost;

            if (post == null) {
              return;
            }

            if (!isFromFeed) {
              return;
            }

            cache.modify({
              id: cache.identify({ __typename: 'PostList' }),
              fields: {
                posts(existingPosts: Post[] = []) {
                  return existingPosts.map((p: Post) =>
                    p.id === post.id ? post : p
                  );
                },
              },
            });
          },
        });
      } else {
        const addPostResponse: OptimisticResponseAddPost = {
          __typename: 'Post',
          id: uniqueId('__ADDED_POST_'),
          user,
          time: '',
          text: postText,
          pictures,
          numComments: 0,
          isEdited: false,
          isPersisted: false,
          status: PostStatus.Created,
        };

        const addedPost = await addPost({
          variables: {
            post: {
              text: postText,
              pictures,
            },
          },
          optimisticResponse: {
            addPost: addPostResponse,
          },
          update(cache, mutationResult) {
            const post = mutationResult.data?.addPost;

            if (post == null) {
              return;
            }

            cache.modify({
              id: cache.identify({ __typename: 'PostList' }),
              fields: {
                posts(existingPosts: Post[] = []) {
                  return [post, ...existingPosts];
                },
              },
            });
          },
        });
        analyticsClient?.track('Community Post Created', {
          id: addedPost.data?.addPost.id,
        });
      }

      showSnackbarV2({
        severity: 'success',
        message: snackbarSuccessMsg,
      });
    } catch (e) {
      showSnackbarV2({
        severity: 'error',
        message: snackbarErrorMsg,
      });
    }
  };

  const handleUploadPicture = async (imageFile: File) => {
    try {
      const { data } = await uploadPicture({
        variables: { picture: imageFile },
      });
      return data?.uploadCommunityPicture;
    } catch (e) {
      showSnackbarV2({
        severity: 'error',
        message: 'Unable to Upload this Picture',
      });
    }
  };

  const handleDiscard = () => {
    handleNavigationOnDiscardOrPublish();
    closeDialog();
  };

  const handleCancel = () => {
    openDialogV2({
      title: 'Are you sure you want to cancel?',
      content: (
        <DiscardPostDialogContent
          isEditMode={isEditMode}
          handleDiscard={handleDiscard}
        />
      ),
    });
  };

  const {
    handleSubmit,
    isValid,
    errors,
    handleChange,
    setFieldValue,
    isSubmitting,
    values,
  } = useFormik({
    initialValues,
    onSubmit: handlePublish,
    validationSchema: postValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const error = !!errors.text && errors.text !== 'required';
  const helperText = errors.text !== 'required' ? errors.text : undefined;

  const loadingState = () => {
    return <Skeleton variant="rectangular" height={'358px'} width="100%" />;
  };

  return (
    <DefaultBasePage
      title={isEditMode ? 'Edit post' : 'Write a post'}
      contentSize="small"
    >
      {isLoading ? (
        loadingState()
      ) : (
        <AddOrEditPostCardV2
          error={error}
          name={name}
          role={role}
          values={values}
          picture={picture}
          isValid={isValid}
          isEditMode={isEditMode}
          isSubmitting={isSubmitting}
          helperText={helperText}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleUploadPicture={handleUploadPicture}
          setFieldValue={setFieldValue}
        />
      )}
    </DefaultBasePage>
  );
};

export default AddOrEditPost;
