import { DefaultBasePage } from '@components';
import { FormStatus } from '@models';
import {
  Button,
  CheckboxGroupState,
  CheckboxGroupV2,
  IconProps,
  NavigationCloseAction,
  RadioGroupV2,
  useDialog,
} from '@fdha/web-ui-library';
import {
  GetLearningModuleDocument,
  LearningQuiz,
  QuizQuestionAnswerInput,
  useAnswerQuizMutation,
  useCompleteLearningItemMutation,
  useResetQuizMutation,
} from '@fdha/graphql-api-patient';
import { Box, Skeleton, Stack, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import CourseQuizResults, { finishAction } from './CourseQuizResults';

interface CourseQuizProps {
  data: LearningQuiz;
  itemId?: string;
  moduleId: string;
  isCompleted: boolean;
  isFinalQuiz: boolean;
  onNext: () => void;
}

type AnswerState = { [key: string]: { value: string[]; validated: boolean } };

interface AnswerSchema {
  answers: AnswerState;
}

const CourseQuiz: React.FC<CourseQuizProps> = ({
  data,
  itemId,
  moduleId,
  isCompleted,
  isFinalQuiz,
  onNext,
}) => {
  const [answerQuiz] = useAnswerQuizMutation();
  const [resetQuiz, { loading: isResetting }] = useResetQuizMutation();
  const [completeLearningItem] = useCompleteLearningItemMutation();

  const { openDialogV2, closeDialog } = useDialog();
  const theme = useTheme();

  const [index, setIndex] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const title = `${index + 1}. ${data.questions[index].question}`;
  const { id, options, correctAnswers } = data.questions[index];

  const isNotAnswered = useMemo(() => {
    return data.questions.some((question) => !question.userAnswers?.length);
  }, [data.questions]);

  const questionType =
    correctAnswers.length > 1 ? 'multipleChoice' : 'singleChoice';
  const isLoading = isResetting;

  const initialValues: AnswerSchema = useMemo(
    () => ({
      answers:
        data.questions?.reduce((acc, { id, userAnswers }) => {
          const answer: string[] = userAnswers
            ? Array.isArray(userAnswers)
              ? (userAnswers as string[])
              : [userAnswers]
            : [];

          acc[id] = { value: answer, validated: !!answer.length };
          return acc;
        }, {} as AnswerState) ?? {},
    }),
    [data.questions]
  );

  const handleSubmit = async (values: AnswerSchema) => {
    const { answers } = values;

    const answersPayload: QuizQuestionAnswerInput[] = Object.keys(answers).map(
      (key) => ({
        questionId: key,
        answer: answers[key].value,
      })
    );

    try {
      await answerQuiz({
        variables: {
          quizId: data.id,
          learningModuleId: moduleId,
          answers: answersPayload,
        },
        refetchQueries: [GetLearningModuleDocument],
      });

      if (!isCompleted) {
        await completeLearningItem({
          variables: {
            learningModuleId: moduleId,
            learningItemId: itemId || data.id,
          },
          refetchQueries: [GetLearningModuleDocument],
        });
      }
    } catch (e) {
      console.log('Unable to answer quiz', e);
    }
  };

  const handleResetQuiz = async () => {
    try {
      await resetQuiz({
        variables: { quizId: data.id, learningModuleId: moduleId },
        refetchQueries: [GetLearningModuleDocument],
        awaitRefetchQueries: true,
      });
    } catch (e) {
      console.log('Unable to reset quiz', e);
    }
  };

  const handleNext = async () => {
    if (!validated) {
      setFieldValue(`answers.${id}.validated`, true);
    } else {
      if (index + 1 <= data.questions.length - 1) {
        setSubmitting(false);
        setIndex(index + 1);
      } else {
        setShowResults(true);

        if (isNotAnswered) {
          await handleSubmit(values);
        }
      }
    }
  };

  const handleQuizFinished = async (action: finishAction) => {
    if (action === 'continue') {
      // Move to next item
      onNext();
    } else {
      resetForm({ values: { answers: {} } });
      handleResetQuiz();
    }

    setShowResults(false);
    setIndex(0);
  };

  const validation = useMemo(() => {
    return Yup.object().shape({
      answers: Yup.object().shape({
        [id]: Yup.object().shape({
          value: Yup.array().min(1),
        }),
      }),
    });
  }, [id]);

  const {
    values,
    status,
    handleSubmit: formikHandleSubmit,
    resetForm,
    setSubmitting,
    setFieldValue,
    setStatus,
  } = useFormik({
    initialValues,
    initialStatus: FormStatus.NOT_CHANGED,
    enableReinitialize: true,
    onSubmit: handleNext,
    validateOnMount: true,
    validationSchema: validation,
  });

  const currentAnswers = values.answers[id]?.value || [];
  const validated = !!values.answers[id]?.validated;

  const formattedOptions = useMemo(() => {
    const getOptionIcon = (isCorrect: boolean): IconProps => {
      const name = isCorrect ? 'checkmark-circle-2' : 'close-circle';
      const fill = isCorrect
        ? theme.palette.success.main
        : theme.palette.error.main;

      return {
        name,
        fill,
        size: 'large',
        'data-testid': isCorrect ? 'CORRECT_ANSWER' : 'INCORRECT_ANSWER',
      };
    };

    return options.map((opt) => {
      const isCorrect = correctAnswers.includes(opt);
      return {
        label: opt,
        value: opt,
        icon: validated ? getOptionIcon(isCorrect) : undefined,
      };
    });
  }, [
    correctAnswers,
    options,
    theme.palette.error.main,
    theme.palette.success.main,
    validated,
  ]);

  const handleSingleChoiceChange = (value: string) => {
    setFieldValue(`answers.${id}.value`, [value]);
    setStatus(FormStatus.CHANGED);
  };

  const handleMultipleChoiceChange = (value: CheckboxGroupState) => {
    const values = Object.entries(value)
      .filter(([_option, checked]) => checked)
      .map(([option]) => option);

    setFieldValue(`answers.${id}.value`, values);
    setStatus(FormStatus.CHANGED);
  };

  const maybeShowDialog = async (): Promise<NavigationCloseAction> => {
    return new Promise((resolve) => {
      if (status === FormStatus.CHANGED) {
        openDialogV2({
          title: 'Are you sure you want to close?',
          content: 'Quiz progress will not be saved.',
          confirmButtonLabel: 'Close',
          cancelButtonLabel: 'Stay',
          i18nKeyTitle: 'learning:course.leaveDialog.title',
          i18nKeyContent: 'learning:course.leaveDialog.text',
          handleConfirm: async () => {
            closeDialog();
            resolve('close');
          },
          handleCancel: () => {
            resolve('cancel');
          },
        });
      } else {
        resolve('close');
      }
    });
  };

  const renderSingleChoice = () => {
    const [answer] = currentAnswers;

    return (
      <RadioGroupV2
        value={answer || ''}
        options={formattedOptions}
        onChange={handleSingleChoiceChange}
      />
    );
  };

  const renderMultipleChoice = () => {
    const answers =
      currentAnswers?.reduce((acc, item) => {
        acc[item] = currentAnswers?.includes(item) ?? false;
        return acc;
      }, {} as CheckboxGroupState) ?? {};

    return (
      <CheckboxGroupV2
        options={formattedOptions}
        value={answers}
        onChange={handleMultipleChoiceChange}
      />
    );
  };

  const renderLoading = () => {
    return (
      <Stack spacing={1}>
        <Stack spacing={1}>
          {[...Array(3)].map((_value: any, index: number) => (
            <Skeleton variant="rectangular" height="24px" />
          ))}
        </Stack>
      </Stack>
    );
  };

  return showResults ? (
    <CourseQuizResults
      questions={data.questions}
      answers={values.answers}
      nextButton={
        isFinalQuiz
          ? { label: 'Finish', i18nKey: 'common:button.finish' }
          : { label: 'Next', i18nKey: 'common:button.next' }
      }
      onFinish={handleQuizFinished}
    />
  ) : (
    <DefaultBasePage
      title={title}
      contentSize="small"
      showClose
      handleActionBeforeClose={maybeShowDialog}
      isLoading={isLoading}
      showBack={false}
      showNavigation={false}
    >
      {isLoading ? (
        renderLoading()
      ) : (
        <>
          {questionType === 'singleChoice'
            ? renderSingleChoice()
            : renderMultipleChoice()}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              disabled={!validation.isValidSync(values)}
              variant="contained"
              i18nKey="common:button.next"
              onClick={() => formikHandleSubmit()}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </DefaultBasePage>
  );
};

export default CourseQuiz;
