import { useTranslation } from '@fdha/common-hooks';
import {
  DishType,
  FilterOp,
  useGetMyDietPlanDishesHistoryV2Query,
} from '@fdha/graphql-api-patient';
import { AutocompleteV2 } from '@fdha/web-ui-library';
import { MealQuestionProps, SnackQuestionProps, Option } from '@models';
import { Box, Link } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const NUMBER_OF_RESULTS = 10000;

interface DishViewPropsBase {
  dishType: DishType;
  onChange: (value: any, name?: string) => void;
  onNext: () => void;
}
interface MealViewProps extends DishViewPropsBase {
  dishType: DishType.Meal;
  question: MealQuestionProps;
}

interface SnackViewProps extends DishViewPropsBase {
  dishType: DishType.Snack;
  question: SnackQuestionProps;
}

type DishViewProps = MealViewProps | SnackViewProps;

interface DishValue {
  dishOption: string;
  linkOption: string;
}

interface Context {
  [key: string]: DishValue;
}

export const DishView: React.FC<DishViewProps> = ({
  question,
  dishType,
  onChange,
  onNext,
}) => {
  const { translate } = useTranslation();
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<Context>();

  const key = question.id;
  const dishValue = values[key].dishOption;
  const dishError = touched[key]?.dishOption && errors[key]?.dishOption;

  const placeholder =
    dishType === DishType.Meal ? 'Type to search meal' : 'Search for a snack';

  const i18nKeyPlaceholder =
    dishType === DishType.Meal
      ? 'surveys:steps.dish.mealPlaceholder'
      : 'surveys:steps.dish.snackPlaceholder';

  const onNextFunc = useRef<() => void>();
  const [shouldGoNext, setShouldGoNext] = useState(false);

  useEffect(() => {
    setFieldValue(`${[key]}.linkOption`, '', false);
  }, [key, setFieldValue]);

  useEffect(() => {
    if (shouldGoNext) {
      setShouldGoNext(false);
      let onNextFuncIsDefined = false;

      if (!onNextFunc.current) {
        onNextFunc.current = () => onNext();
        onNextFuncIsDefined = true;
      }

      if (onNextFuncIsDefined) {
        onNextFunc.current();
      }
    }
  }, [shouldGoNext, onNext]);

  const linkOptions = question.options ?? [];

  const { data, loading } = useGetMyDietPlanDishesHistoryV2Query({
    variables: {
      first: NUMBER_OF_RESULTS,
      filterBy: {
        filterBy: [{ field: 'type', op: FilterOp.Equal, value: dishType }],
      },
    },
  });

  const dishOptions = useMemo(
    () =>
      data?.dietPlanDishesHistoryV2.edges.map(({ node }) => ({
        label: node.name,
        id: node.id,
      })) ?? [],
    [data?.dietPlanDishesHistoryV2.edges]
  );

  const dishOption = useMemo(() => {
    return dishOptions.find((option) => option.label === dishValue) ?? null;
  }, [dishOptions, dishValue]);

  const handleChange = (linkOption: string, dishOption: string) => {
    onChange({ ...values[key], linkOption, dishOption });
  };

  const handleBlur = () => {
    setFieldTouched(`${[key]}.dishOption`, true, true);
  };

  const renderLink = (answer: Option) => {
    return (
      <Link
        data-testid="DISH_VIEW_LINK"
        sx={{ cursor: 'pointer' }}
        key={answer.value}
        onClick={() => {
          handleChange(answer.value, '');
          setShouldGoNext(true);
        }}
      >
        {answer.i18nKey
          ? translate(answer.i18nKey, answer.label)
          : answer.label}
      </Link>
    );
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <AutocompleteV2
        options={dishOptions}
        value={dishOption}
        loading={loading}
        onChange={(_event, value) => {
          handleChange('', value?.label || '');
        }}
        onBlur={handleBlur}
        error={!!dishError}
        helperText={dishError}
        placeholder={placeholder}
        i18nKeyPlaceholder={i18nKeyPlaceholder}
      />
      {linkOptions?.map(renderLink)}
    </Box>
  );
};
