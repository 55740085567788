import { EmptyStateCard } from '@fdha/web-ui-library';
import React from 'react';

interface EmptyStateProps {
  type: 'article' | 'course' | 'recipe';
}

const EmptyState: React.FC<EmptyStateProps> = ({ type }) => {
  const getDataByType = () => {
    switch (type) {
      case 'course':
        return {
          title: 'No courses at the moment',
          message: "Your Coach hasn't assigned a course to you yet.",
          i18nKeyTitle: 'learning:course.emptyState.title',
          i18nKeyMessage: 'learning:course.emptyState.message',
        };
      default:
        return {
          title: 'Content soon!',
          message:
            'Our specialized dietitians are preparing the best information to share with you.',
          i18nKeyTitle: 'learning:article.emptyState.title',
          i18nKeyMessage: 'learning:article.emptyState.message',
        };
    }
  };

  const { title, message, i18nKeyTitle, i18nKeyMessage } = getDataByType();

  return (
    <EmptyStateCard
      title={title}
      message={message}
      i18nKeyTitle={i18nKeyTitle}
      i18nKeyMessage={i18nKeyMessage}
    />
  );
};

export default EmptyState;
