import { ImageBasePage } from '@components';
import { useGetProfileQuery, UserType } from '@fdha/graphql-api-patient';
import { Button, Typography } from '@fdha/web-ui-library';
import {
  Box,
  MobileStepper as MuiMobileStepper,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

const MobileStepper = styled(MuiMobileStepper)({
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiMobileStepper-dot': {
    width: '10px',
    height: '10px',
  },
  '& .MuiMobileStepper-dots': {
    alignItems: 'center',
  },
  '& .MuiMobileStepper-dotActive': {
    width: '16px',
    height: '16px',
  },
});

const Mission = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const { data } = useGetProfileQuery();

  const content = [
    {
      text: 'Faeth will connect you with a registered dietitian as your personal coach.',
      i18nKey: 'mission:mission.subtitle1',
      image: { url: '/images/woman_01.png', altText: 'Woman' },
    },
    {
      text: 'Faeth will give you access to reliable wellness content from experts.',
      i18nKey: 'mission:mission.subtitle2',
      image: { url: '/images/food_03.png', altText: 'Food' },
    },
    {
      text: 'Faeth will help you manage your treatment and connect better with your Care team.',
      i18nKey: 'mission:mission.subtitle3',
      image: { url: '/images/man_03.png', altText: 'Man' },
    },
  ];

  const isLastItem = index + 1 === content.length;
  const isFirstItem = index === 0;
  const item = content[index];
  const isDh = data?.me.type !== UserType.ClinicalTrialPatient;

  const handleNext = () => {
    if (isLastItem) {
      handleSkip();
    } else {
      setIndex(index + 1);
    }
  };

  const handleBack = () => {
    setIndex(index - 1);
  };

  const handleSkip = () => {
    if (isDh) {
      navigate('/main-goal', { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  const renderSkipButton = () => (
    <Button
      fullWidth={isFirstItem}
      onClick={handleSkip}
      i18nKey="mission:mission.skipIntro"
    >
      Skip Introduction
    </Button>
  );

  return (
    <ImageBasePage
      image={item.image}
      title={
        <Typography
          variant="h5"
          fontWeight="700"
          color={theme.palette.text.secondary}
          i18nKey="mission:mission.title"
        >
          We are committed to your fight.
        </Typography>
      }
      subtitle={
        <Typography i18nKey={item.i18nKey} variant="h4">
          {item.text}
        </Typography>
      }
      alignHeader="flex-start"
      imagePosition="right"
      contentSize="small"
    >
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={index}
        nextButton={null}
        backButton={null}
      />
      <Stack direction="row" spacing={2} mt={4} mb={3}>
        {isFirstItem ? (
          renderSkipButton()
        ) : (
          <Button
            i18nKey="common:button.back"
            variant="outlined"
            fullWidth
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Button
          i18nKey={
            isLastItem && !isDh
              ? 'mission:mission.getStarted'
              : 'common:button.next'
          }
          variant="contained"
          fullWidth
          onClick={handleNext}
        >
          {isLastItem && !isDh ? 'Get Started' : 'Next'}
        </Button>
      </Stack>
      {!isFirstItem && (
        <Box display="flex" justifyContent="center">
          {renderSkipButton()}
        </Box>
      )}
    </ImageBasePage>
  );
};

export default Mission;
