import { useCallback, useEffect, useState } from 'react';
import { useGetCryptoKeyQuery } from '@fdha/graphql-api-patient';

export function useCryptography() {
  const { data } = useGetCryptoKeyQuery();
  const key = data?.cryptoKey || '';

  const [cryptoKey, setCryptoKey] = useState<CryptoKey | undefined>();

  const importKey = useCallback(async () => {
    if (key) {
      const parsedKey = JSON.parse(key);

      const result = await crypto.subtle.importKey(
        'jwk',
        parsedKey,
        {
          name: 'AES-GCM',
          length: 256,
        },
        true,
        ['encrypt', 'decrypt']
      );
      setCryptoKey(result);
    }
  }, [key]);

  useEffect(() => {
    const importCryptoKey = async () => {
      try {
        await importKey();
      } catch (e) {
        console.log('Error importing crypto key', e);
      }
    };

    importCryptoKey();
  }, [importKey]);

  const encodeData = (value: string) => {
    return new TextEncoder().encode(value);
  };

  const decodeData = (bytestream: BufferSource | undefined) => {
    return new TextDecoder().decode(bytestream);
  };

  const generateIv = () => {
    return window.crypto.getRandomValues(new Uint8Array(12));
  };

  const pack = (buffer: ArrayBuffer) => {
    const array = new Uint8Array(buffer);
    return Buffer.from(array).toString('base64');
  };

  const unpack = (packed: string) => {
    const string = window.atob(packed || '');
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);

    for (let i = 0; i < string.length; i++) {
      bufferView[i] = string.charCodeAt(i);
    }

    return buffer;
  };

  const encrypt = useCallback(
    async (data: string) => {
      if (cryptoKey) {
        const encoded = encodeData(data);
        const iv = generateIv();

        const cipher = await window.crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv,
          },
          cryptoKey,
          encoded
        );

        return {
          cipher: pack(cipher),
          iv: pack(iv),
        };
      }
    },
    [cryptoKey]
  );

  const decrypt = useCallback(
    async (data: { iv: string; cipher: string }) => {
      if (cryptoKey && data) {
        const encoded = await window.crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: unpack(data.iv),
          },
          cryptoKey,
          unpack(data.cipher)
        );

        return JSON.parse(decodeData(encoded));
      }
    },
    [cryptoKey]
  );

  return { encrypt, decrypt };
}
