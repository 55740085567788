import React from 'react';
import { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { Chat, useChatContext } from 'stream-chat-react';
import '@stream-io/stream-chat-css';
import {
  DefaultBasePage,
  ChannelListContainer,
  ChannelContainer,
} from '@components';
import { useReactiveVar } from '@apollo/client';
import { useChat } from '@fdha/common-hooks';
import { ChannelType } from '@utils';
import { Skeleton } from '@mui/material';
import {
  ChatScreenType,
  selectedChatScreenType,
  setSelectedChatScreenType,
} from '@states';
import { useLocation } from 'react-router-dom';

import { customStyles, StyledChatWrapper } from './styles';

const filters = (userId: string): ChannelFilters => ({
  members: { $in: [userId] },
});
const options: ChannelOptions = {
  state: true,
  watch: true,
  presence: true,
  limit: 8,
};
const sort: ChannelSort = {
  last_message_at: -1,
  updated_at: -1,
  cid: 1,
};

interface Props {
  streamUserId: string;
  channelId?: string;
  preDefinedMessage?: string;
}

export const ChatInner: React.FC<Props> = ({
  streamUserId,
  channelId,
  preDefinedMessage,
}) => {
  const channelType = useReactiveVar(selectedChatScreenType);
  const { client: chatClient } = useChat();
  const { channel, setActiveChannel } = useChatContext();

  if (channelId) {
    setActiveChannel(chatClient?.channel(ChannelType.Messaging, channelId));
    setSelectedChatScreenType(ChatScreenType.Channel);
  }

  return (
    <>
      {channelType === ChatScreenType.ChannelList ? (
        <ChannelListContainer
          filters={filters(streamUserId)}
          options={options}
          sort={sort}
          customActiveChannel={channelId}
        />
      ) : (
        <ChannelContainer
          showMessageInput={channel?.type === ChannelType.Messaging}
          preDefinedMessage={preDefinedMessage}
        />
      )}
    </>
  );
};

interface StateProps {
  channelId?: string;
  preDefinedMessage?: string;
}

const ChatPage = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const { client: chatClient, streamUserId } = useChat();

  const { channelId, preDefinedMessage } = state || {};

  if (!chatClient || !streamUserId) {
    return <Skeleton variant="rectangular" height={600} />;
  }

  return (
    <DefaultBasePage i18nKeyTitle="chat:title" title="Chat" contentSize="small">
      <StyledChatWrapper>
        <Chat client={chatClient} customStyles={customStyles}>
          <ChatInner
            streamUserId={streamUserId}
            channelId={channelId}
            preDefinedMessage={preDefinedMessage}
          />
        </Chat>
      </StyledChatWrapper>
    </DefaultBasePage>
  );
};

export default ChatPage;
