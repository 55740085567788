import React from 'react';
import { DefaultBasePage } from '@components';
import {
  ListPostsDocument,
  Post as PostSchema,
  useGetCommunityUserQuery,
  useListPostsQuery,
  useDeletePostMutation,
  PostStatus,
  useListCommunityNotificationsQuery,
} from '@fdha/graphql-api-patient';
import {
  InfiniteScrollList,
  PostCard,
  PostV2,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import EmptyState from './EmptyState';
import { LoadingFeed, LoadingNotifications } from './LoadingState';
import RecentNotifications from './RecentNotifications';

const Feed = () => {
  const { openDialogV2, closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();

  const navigate = useNavigate();

  const { data: userData, loading: loadingProfile } =
    useGetCommunityUserQuery();
  const { data, fetchMore, networkStatus } = useListPostsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: notificationsData, loading: loadingNotifications } =
    useListCommunityNotificationsQuery({
      variables: { limit: 3 },
      fetchPolicy: 'cache-and-network',
    });

  const [deletePost] = useDeletePostMutation({
    refetchQueries: [ListPostsDocument],
  });

  const posts = data?.posts.posts || [];
  const hasMoreResults = data?.posts.hasNextPage;
  const isLoading = networkStatus === NetworkStatus.loading && !posts.length;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const loadMore = async () => {
    if (networkStatus !== NetworkStatus.fetchMore && hasMoreResults) {
      try {
        await fetchMore({
          variables: { offset: posts.length },
        });
      } catch (e) {}
    }
  };

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost({
        variables: {
          postId,
        },
      });
      showSnackbarV2({
        severity: 'success',
        message: 'Post Deleted',
      });
    } catch (e) {
      showSnackbarV2({
        severity: 'error',
        message: 'Unable to Delete Post',
      });
    } finally {
      closeDialog();
    }
  };

  const handleDelete = (id: string) => {
    openDialogV2({
      title: 'Are you sure you want to delete this post?',
      content: 'This action can’t be undone.',
      handleConfirm: () => handleDeletePost(id),
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
    });
  };

  const onClickPost = (id: string, focusInput?: boolean) => {
    navigate(id, {
      state: { focusOnInput: !!focusInput, backRoute: '/community' },
    });
  };

  const onWriteButton = () => {
    navigate('write-post', { state: { backRoute: '/community' } });
  };

  const onEditButton = (id: string) => {
    navigate(`${id}/edit-post`, { state: { backRoute: '/community' } });
  };

  const onPhotoButton = () => {
    navigate('write-post', {
      state: { openImagePicker: true, backRoute: '/community' },
    });
  };

  const renderListItem = (post: PostSchema) => {
    return (
      <PostV2
        isOnFeed
        post={post}
        onDelete={handleDelete}
        onEdit={() => onEditButton(post.id)}
        isMine={post.user.id === userData?.getCommunityUser?.id}
        onClick={onClickPost}
        onClickComment={onClickPost}
        isPostRemoved={post.status === PostStatus.Removed}
      />
    );
  };

  return (
    <DefaultBasePage
      i18nKeyTitle="community:title"
      title="Community"
      contentSize="small"
    >
      <Stack spacing={3}>
        {loadingNotifications ? (
          <LoadingNotifications />
        ) : (
          <RecentNotifications notificationsData={notificationsData} />
        )}
        {isLoading || loadingProfile ? (
          <LoadingFeed />
        ) : (
          <Stack spacing={3}>
            <PostCard
              userName={userData?.getCommunityUser?.name}
              picture={userData?.getCommunityUser?.picture}
              handleWriteButton={onWriteButton}
              handlePhotoButton={onPhotoButton}
            />
            <InfiniteScrollList
              items={posts}
              renderItem={renderListItem}
              renderEmptyState={() => <EmptyState />}
              loadMore={loadMore}
              isLoading={isLoading}
              isLoadingMore={isLoadingMore}
            />
          </Stack>
        )}
      </Stack>
    </DefaultBasePage>
  );
};

export default Feed;
