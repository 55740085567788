import { useTranslation } from '@fdha/common-hooks';
import { Typography, formatDate } from '@fdha/web-ui-library';
import { QuestionProps } from '@models';
import { Box, useTheme } from '@mui/material';
import React from 'react';

interface ConfirmationViewProps {
  questions: QuestionProps[];
  answers: any;
}

const EMPTY_ANSWER = '-';

export const ConfirmationView: React.FC<ConfirmationViewProps> = ({
  questions,
  answers,
}) => {
  const { translate } = useTranslation();
  const theme = useTheme();

  const getAnswer = (answer: any, question: QuestionProps) => {
    let answerText: string | undefined;
    let i18nKey: string | undefined;

    switch (question.type) {
      case 'Address': {
        const { address, complement, city, zip, state } = answer;

        if (address && city && state && zip) {
          const complementText = complement ? ` ${complement},` : '';
          answerText = `${address},${complementText} ${city}, ${state}, ${zip}`;
        }
        break;
      }
      case 'Autocomplete': {
        if (!answer) {
          answerText = question.link.label;
        } else {
          answerText = question.options.find(
            (option) => option.id === answer
          )?.label;
        }
        break;
      }
      case 'DateOption': {
        if (answer.date) {
          answerText = formatDate(answer.date, 'monthYear');
        } else {
          const answerValue = question.options.find(
            (option) => option.value === answer.radioOption
          );

          answerText = answerValue?.label;
          i18nKey = answerValue?.i18nKeyLabel;
        }
        break;
      }
      case 'MultipleChoice': {
        const choices = answer.map((choice: string) => {
          return question.options.find((option) => option.value === choice);
        }) as { i18nKey?: string; label: string }[];

        if (choices.length > 0) {
          answerText = choices
            .map((choice) =>
              choice.i18nKey
                ? translate(choice.i18nKey, choice.label)
                : choice.label
            )
            .join(', ');
        }
        break;
      }
      case 'Number': {
        answerText = answer;
        break;
      }
      case 'OpenText':
        answerText = answer;
        break;
      case 'Scale': {
        answerText = answer;
        break;
      }
      case 'SingleChoice':
      case 'Binary':
        const selectedOption = question.options?.find(
          (option) => option.value === answer.option
        );

        if (selectedOption?.value === 'other' && answer.otherOption) {
          answerText = `${
            selectedOption.i18nKey
              ? translate(selectedOption.i18nKey, selectedOption.label)
              : selectedOption.label
          }: ${answer.otherOption}`;
        } else {
          answerText = selectedOption?.label;
          i18nKey = selectedOption?.i18nKey;
        }
        break;
      case 'Meal':
      case 'Snack': {
        if (answer.linkOption) {
          const answeValue = question.options?.find(
            (option) => option.value === answer.linkOption
          );

          answerText = answeValue?.label;
          i18nKey = answeValue?.i18nKey;
        } else {
          answerText = answer.dishOption;
        }
        break;
      }
      case 'TextSelect': {
        const selectedOption = question.select.options.find(
          (option) => option.value === answer.select
        );

        if (!selectedOption) {
          answerText = answer.text;
        } else {
          answerText = `${answer.text}, ${selectedOption?.label}`;
        }
        break;
      }
      case 'Time': {
        if (answer.time) {
          answerText = `${answer.time} ${answer.dayPeriod.toUpperCase()}`;
        }
        break;
      }
      case 'Decimal': {
        if (answer.decimal) {
          answerText = `${answer.decimal} ${answer.unit}`;
        }
        break;
      }
    }

    return { answerText: answerText || EMPTY_ANSWER, i18nKey };
  };

  const renderQuestion = (question: QuestionProps) => {
    const { id, title } = question;
    const answer = getAnswer(answers[id], question);

    return (
      <Box key={id}>
        <Typography
          data-testid="CONFIRMATION_VIEW_QUESTION_STATEMENT"
          variant="body1"
          i18nKey={question?.i18nKeyTitle}
        >
          {title}
        </Typography>
        <Typography
          data-testid="CONFIRMATION_VIEW_QUESTION_ANSWER"
          variant="subtitle1"
          color={theme.palette.text.secondary}
          i18nKey={answer.i18nKey}
        >
          {answer.answerText}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      data-testid="CONFIRMATION_VIEW"
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      {questions.map((question) => renderQuestion(question))}
    </Box>
  );
};
