import { DefaultBasePage } from '@components';
import { Stack } from '@mui/material';
import { useCommunity } from '@hooks';

import CommunityRestricted from './CommunityRestricted';
import Feed from './Feed/Feed';
import { LoadingFeed, LoadingNotifications } from './Feed/LoadingState';

const CommunityInitial = () => {
  const { isBanned } = useCommunity();

  if (isBanned === undefined) {
    return (
      <DefaultBasePage
        i18nKeyTitle="community:title"
        title="Community"
        contentSize="small"
      >
        <Stack spacing={3}>
          <LoadingNotifications />
          <LoadingFeed />
        </Stack>
      </DefaultBasePage>
    );
  }

  return isBanned ? <CommunityRestricted /> : <Feed />;
};

export default CommunityInitial;
