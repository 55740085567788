import { EmptyStateCard } from '@fdha/web-ui-library';

interface EmptyCardProps {
  isCoach?: boolean;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ isCoach = false }) => {
  const message = isCoach
    ? "Files added by your Coach will be shown here. Your Coach hasn't added any files so far."
    : 'Start saving health documents all in one place, where only you and your Coach will have access.';
  const i18nMessage = isCoach
    ? 'profile:documents.folders.coachesUploads.emptyState.description'
    : 'profile:documents.emptyState.description';

  return (
    <EmptyStateCard
      i18nKeyTitle="profile:documents.emptyState.title"
      i18nKeyMessage={i18nMessage}
      title="No document yet"
      message={message}
    />
  );
};

export default EmptyCard;
