import { useGetProfileQuery } from '@fdha/graphql-api-patient';
import { DatePickerV2, Typography } from '@fdha/web-ui-library';
import { DateOption, DateOptionQuestionProps } from '@models';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface DateOptionViewProps {
  question: DateOptionQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

interface DateOptionValue {
  radioOption: string;
  date: Date | null;
}

interface Context {
  [key: string]: DateOptionValue;
}

export const DateOptionView: FC<DateOptionViewProps> = ({
  question,
  handleChange,
}) => {
  const { data } = useGetProfileQuery();
  const { values, errors, touched } = useFormikContext<Context>();

  const key = question.id;
  const showRadioOption = values[key].radioOption === DateOption.Remember;
  const error = touched[key]?.date && errors[key]?.date;

  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup value={values[key].radioOption}>
        {question.options.map((option) => (
          <Box key={option.value}>
            <FormControlLabel
              label={
                <Typography i18nKey={option?.i18nKeyLabel}>
                  {option.label}
                </Typography>
              }
              value={option.value}
              control={<Radio size="small" />}
              onChange={() => {
                handleChange({ radioOption: option.value, date: '' });
              }}
            />
            {showRadioOption && option.value === DateOption.Remember && (
              <Box my={2}>
                <DatePickerV2
                  value={values[key].date}
                  i18nKeyTitle="profile:medicalInformation.input.diagnosisDate.label"
                  locale={data?.me?.language}
                  error={!!error}
                  helperText={error}
                  onChange={(date) => {
                    handleChange({
                      radioOption: option.value,
                      date: date,
                    });
                  }}
                  views={['month', 'year']}
                  format="MM/yyyy"
                />
              </Box>
            )}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
