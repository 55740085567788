import { DatePickerV2, Typography } from '@fdha/web-ui-library';
import { useFormikContext } from 'formik';
import React from 'react';

import i18n from '../../i18n';

import { CreateAccountResponse } from './createAccountScreens';

const BirthDate = () => {
  const { values, handleBlur, errors, touched, setFieldValue } =
    useFormikContext<CreateAccountResponse>();

  const error = touched.birthDate ? errors.birthDate : null;

  return (
    <>
      <Typography
        i18nKey="login:createAccount.step3to7.input.birthDate.label"
        variant="h5"
      >
        What is your date of birth?
      </Typography>
      <DatePickerV2
        value={values.birthDate}
        onBlur={handleBlur}
        locale={i18n?.language}
        error={!!error}
        helperText={error}
        onChange={(value) => {
          setFieldValue('birthDate', value);
        }}
      />
    </>
  );
};

export default BirthDate;
