import { Button, Stack, useTheme } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import { Typography } from '../Typography/Typography';

export interface ToggleButtonOption<T> {
  label: string;
  value: T;
  i18nKey?: string;
}

interface ToggleButtonProps<T> {
  options: ToggleButtonOption<T>[];
  setValue: Dispatch<SetStateAction<T>>;
  selectedValue: T;
}

export function ToggleButton<T>({
  options,
  setValue,
  selectedValue,
}: ToggleButtonProps<T>) {
  const theme = useTheme();

  return (
    <Stack flexDirection="row" display="flex">
      {options.map((option) => (
        <Button
          key={option.label}
          onClick={() => setValue(option.value)}
          sx={{
            flex: 1,
            borderRadius: 0,
            borderBottom:
              option.value === selectedValue ? '2px solid' : undefined,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Typography fontWeight="bold" i18nKey={option.i18nKey}>
            {option.label}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
}
