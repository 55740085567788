import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import {
  SelectV2,
  TextFieldV2,
  TimeInputV2,
  durationLabelByValue,
  frequencyLabelByValue,
  locationLabelByValue,
  reminderLabelByValue,
  FormItem,
  FrequencyInputV2,
  DatePickerV2,
} from '@fdha/web-ui-library';
import {
  ActivityFrequency,
  ActivityReminder,
  AppointmentDuration,
  AppointmentLocation,
  WeekDay,
  useGetProfileQuery,
} from '@fdha/graphql-api-patient';

interface ActivityFormValues {
  title: string;
  description?: string;
  date: Date | null;
  time: string;
  ends: 'never' | 'date';
  ends_at?: Date;
  dayPeriod: 'am' | 'pm';
  duration: AppointmentDuration;
  frequency: ActivityFrequency;
  reminder: ActivityReminder;
  weekDays?: WeekDay[];
  location: AppointmentLocation;
}

const Title = () => {
  const {
    handleChange,
    values: { title },
    touched,
    handleBlur,
    errors,
  } = useFormikContext<ActivityFormValues>();

  const error = touched.title && errors.title;

  return (
    <FormItem>
      <TextFieldV2
        title="Title"
        value={title}
        onChange={handleChange('title')}
        inputProps={{ maxLength: 100 }}
        name="title"
        error={!!error}
        helperText={error}
        onBlur={handleBlur}
        i18nKeyTitle="activities:fields.title.label"
      />
    </FormItem>
  );
};

const Details = () => {
  const {
    handleChange,
    values: { description },
  } = useFormikContext<ActivityFormValues>();

  return (
    <FormItem>
      <TextFieldV2
        title="Details"
        multiline={true}
        value={description}
        onChange={handleChange('description')}
        i18nKeyTitle="activities:fields.details.label"
      />
    </FormItem>
  );
};

const DateInput = () => {
  const {
    values: { date, frequency },
    errors,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormikContext<ActivityFormValues>();
  const { data } = useGetProfileQuery();
  const label = frequency === ActivityFrequency.Once ? 'Date' : 'Start Date';
  const error = touched.date && errors.date;

  return (
    <FormItem>
      <DatePickerV2
        title={label}
        value={date}
        locale={data?.me?.language}
        onBlur={handleBlur}
        error={!!error}
        helperText={error}
        onChange={(value) => {
          setFieldValue('date', value);
        }}
        i18nKeyTitle={
          frequency === ActivityFrequency.Once
            ? 'activities:fields.date.label'
            : 'activities:fields.startDate.label'
        }
      />
    </FormItem>
  );
};

const EndsAt = () => {
  const {
    values: { ends, ends_at },
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<ActivityFormValues>();
  const { data } = useGetProfileQuery();
  const options = [
    {
      value: 'never',
      label: 'Never',
      i18nKey: 'activities:fields.ends.never',
    },
    {
      value: 'date',
      label: 'On date',
      i18nKey: 'activities:fields.ends.onDate',
    },
  ];

  const error = touched.ends_at && errors.ends_at;

  return (
    <FormItem>
      <SelectV2
        options={options}
        title="Ends"
        value={ends}
        onChange={(option) => {
          if (option.target.value === 'never') {
            setFieldValue('ends_at', '');
          }
          setFieldValue('ends', option.target.value);
        }}
        i18nKeyTitle="activities:fields.ends.label"
      />
      {ends === 'date' && (
        <DatePickerV2
          value={ends_at}
          onChange={(value) => {
            setFieldValue('ends_at', value);
          }}
          locale={data?.me?.language}
          onBlur={handleBlur}
          error={!!error}
          helperText={error}
          sx={{ mt: 2 }}
        />
      )}
    </FormItem>
  );
};

const Time = () => {
  const {
    values: { time, dayPeriod },
    errors,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormikContext<ActivityFormValues>();

  return (
    <FormItem>
      <TimeInputV2
        time={time}
        dayPeriod={dayPeriod}
        error={touched.time && errors.time}
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
    </FormItem>
  );
};

const Frequency = () => {
  const {
    values: { frequency, weekDays },
    initialValues: { weekDays: initialWeekDays },
    setFieldValue,
  } = useFormikContext<ActivityFormValues>();

  const frequencyOptions = Object.entries(ActivityFrequency).map(
    ([key, value]) => ({
      label: frequencyLabelByValue[value].label,
      i18nKey: frequencyLabelByValue[value].i18nKey,
      value: key,
    })
  );

  return (
    <FormItem>
      <FrequencyInputV2
        frequency={frequency}
        weekDays={weekDays}
        initialWeekDays={initialWeekDays}
        frequencyOptions={frequencyOptions}
        setFieldValue={setFieldValue}
      />
    </FormItem>
  );
};

const Duration = () => {
  const {
    values: { duration },
    setFieldValue,
  } = useFormikContext<ActivityFormValues>();

  const durationOptions = Object.entries(AppointmentDuration).map(
    ([key, value]) => ({
      label: durationLabelByValue[value].label,
      i18nKey: durationLabelByValue[value].i18nKey,
      value: key,
    })
  );

  return (
    <FormItem>
      <SelectV2
        options={durationOptions}
        title="Duration"
        value={duration}
        onChange={(option) => setFieldValue('duration', option.target.value)}
        i18nKeyTitle="activities:fields.duration.label"
      />
    </FormItem>
  );
};

const Location = () => {
  const {
    values: { location },
    setFieldValue,
  } = useFormikContext<ActivityFormValues>();

  const locationOptions = Object.entries(AppointmentLocation).map(
    ([key, value]) => ({
      label: locationLabelByValue[value].label,
      i18nKey: locationLabelByValue[value].i18nKey,
      value: key,
    })
  );

  return (
    <FormItem>
      <SelectV2
        options={locationOptions}
        title="Location"
        value={location}
        onChange={(option) => setFieldValue('location', option.target.value)}
        i18nKeyTitle="activities:fields.location.label"
      />
    </FormItem>
  );
};

const Reminder = () => {
  const {
    values: { reminder },
    setFieldValue,
  } = useFormikContext<ActivityFormValues>();

  const reminderOptions = Object.entries(ActivityReminder).map(
    ([key, value]) => ({
      label: reminderLabelByValue[value].label,
      i18nKey: reminderLabelByValue[value].i18nKey,
      value: key,
    })
  );

  return (
    <FormItem>
      <SelectV2
        options={reminderOptions}
        title="Remind me"
        value={reminder}
        onChange={(option) => setFieldValue('reminder', option.target.value)}
        i18nKeyTitle="activities:fields.remindMe.label"
      />
    </FormItem>
  );
};

const ActivityFormFields: React.FC<PropsWithChildren> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default Object.assign(ActivityFormFields, {
  Title,
  Details,
  DateInput,
  EndsAt,
  Time,
  Frequency,
  Duration,
  Location,
  Reminder,
});
