import {
  formatUTCDate,
  Typography,
  ShipmentStatusIcon,
  Icon,
} from '@fdha/web-ui-library';
import {
  DeliveryStatus,
  ListDeliveriesV2Query,
  TrackingStatus,
} from '@fdha/graphql-api-patient';
import { Box, Card, CardActionArea, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { FC, useMemo } from 'react';
import {
  getShipmentStatusLabelByValue,
  getPriorityShipmentStatus,
  isDeliveryDelayed,
} from '@fdha/common-utils';

export type DeliveryProps = ListDeliveriesV2Query['deliveriesV2'][0];

interface DeliveryCardProps {
  delivery: DeliveryProps;
  showShipmentDetails?: boolean;
}

const DeliveryCard: FC<DeliveryCardProps> = ({
  delivery,
  showShipmentDetails,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isCanceled = delivery.isCanceled;
  const deliveryStatus = delivery.status;
  const isoDeliveryDate = delivery.deliveryDate;
  const deliveryDate = formatUTCDate(isoDeliveryDate, 'monthWeekDay') || '';

  const isoOpenForChanges = delivery.openChanges;
  const openForChangesDate = formatUTCDate(isoOpenForChanges, 'monthDay') || '';
  const shipmentOrder = delivery?.order;
  const shipmentLabels = shipmentOrder?.shipmentLabels;

  const getDeliveryStatusIconAttributes = () => {
    if (isCanceled) {
      return {
        iconName: 'close-circle-outline',
        iconColor: theme.palette.action.disabled,
      };
    }

    if (deliveryStatus === DeliveryStatus.Open) {
      return {
        iconName: 'unlock-outline',
        iconColor: theme.palette.success.main,
      };
    }

    return {
      iconName: 'lock-outline',
      iconColor: theme.palette.error.main,
    };
  };

  const handleClick = () => navigate(`/meals/${delivery.id}`);

  const showShipmentStatus = useMemo(
    () =>
      showShipmentDetails &&
      deliveryStatus === DeliveryStatus.Closed &&
      !isCanceled,
    [deliveryStatus, isCanceled, showShipmentDetails]
  );

  const shipmentStatusLabelByValue = useMemo(
    () => getShipmentStatusLabelByValue('web'),
    []
  );

  const title = useMemo(() => {
    const keySuffix = isCanceled ? 'canceledTitle' : 'title';

    return {
      i18nKey: `temporary:meals.card.${keySuffix}`,
      fallback: isCanceled ? 'Delivery canceled for' : 'Delivery expected for',
    };
  }, [isCanceled]);

  const deliveryShipmentStatus = useMemo(() => {
    if (!shipmentOrder || !shipmentLabels?.length) {
      return TrackingStatus.BeingPrepared;
    }

    const statuses = shipmentLabels.map((shipment) => shipment.trackingStatus);
    return getPriorityShipmentStatus(statuses);
  }, [shipmentLabels, shipmentOrder]);

  const statusLabel = useMemo(() => {
    if (isCanceled) {
      return {
        i18nKey: 'temporary:meals.deliveryStatus.cancelled',
        fallback: 'Your delivery has been canceled',
      };
    }

    if (deliveryStatus === DeliveryStatus.Open) {
      return {
        fallback: `Open Until ${openForChangesDate} at 9 AM ET`,
        i18nKey: 'meals:card.status.open',
        i18nParams: { date: delivery.openChanges },
      };
    }

    if (showShipmentStatus && deliveryShipmentStatus) {
      const label = shipmentStatusLabelByValue[deliveryShipmentStatus];
      return {
        fallback: label.fallback,
        i18nKey: label.key,
      };
    }

    return {
      i18nKey: 'meals:card.status.closed',
      fallback: 'Closed for Changes',
    };
  }, [
    isCanceled,
    delivery.openChanges,
    deliveryStatus,
    deliveryShipmentStatus,
    openForChangesDate,
    shipmentStatusLabelByValue,
    showShipmentStatus,
  ]);

  const renderIcon = () => {
    if (!showShipmentStatus || !deliveryShipmentStatus) {
      const { iconName, iconColor } = getDeliveryStatusIconAttributes();
      return <Icon name={iconName} size="large" fill={iconColor} />;
    }
    return <ShipmentStatusIcon status={deliveryShipmentStatus} size="large" />;
  };

  const actionText = useMemo(() => {
    if (isCanceled || deliveryStatus === DeliveryStatus.Closed) {
      return {
        fallback: 'See more details',
        i18nKey: 'temporary:common.button.seeMoreDetails',
      };
    }

    return {
      fallback: 'Edit',
      i18nKey: 'common:button.edit',
    };
  }, [isCanceled, deliveryStatus]);

  const showDelayed = useMemo(() => {
    if (!showShipmentDetails) {
      return false;
    }
    return isDeliveryDelayed(
      new Date(isoDeliveryDate),
      delivery.order?.shipmentLabels
    );
  }, [showShipmentDetails, delivery.order?.shipmentLabels, isoDeliveryDate]);

  return (
    <Card
      elevation={5}
      sx={{
        boxShadow: theme.shadows[2],
        mb: 2,
      }}
    >
      <CardActionArea
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.action.background,
          },
        }}
      >
        <Box p={1.5}>
          <Typography
            variant="subtitle1"
            color={theme.palette.text.secondary}
            i18nKey={title.i18nKey}
          >
            {title.fallback}
          </Typography>
          <Typography
            variant="h5"
            i18nKey="meals:card.date"
            i18nParams={{ date: isoDeliveryDate }}
          >
            {deliveryDate}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            {renderIcon()}
            <Typography
              variant="subtitle2"
              color={theme.palette.text.secondary}
              ml={1}
              i18nKey={statusLabel.i18nKey}
              i18nParams={statusLabel.i18nParams}
              fontWeight="bold"
            >
              {statusLabel.fallback}
            </Typography>
          </Box>
        </Box>
        {showDelayed && (
          <Box
            display="flex"
            justifyContent="center"
            sx={{ backgroundColor: theme.palette.warning.light }}
            py={0.5}
          >
            <Typography variant="caption" i18nKey="temporary:meals.delayed">
              Your delivery might be delayed.
            </Typography>
          </Box>
        )}
        <Box
          p={1.5}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid"
          borderColor={theme.palette.divider}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.info.main}
            fontWeight="bold"
            i18nKey={actionText.i18nKey}
          >
            {actionText.fallback}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default DeliveryCard;
