import { ImageBasePage } from '@components';
import { ForgotPasswordV2 } from '@fdha/web-auth';

const ForgotPassword = () => {
  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Woman',
      }}
      titleVariant="h4"
      title="Reset Password"
      i18nKeyTitle="login:forgotPassword.title"
      i18nKeySubtitle="login:forgotPassword.subtitle"
      subtitle="Please complete the field below with your email address associated to your Faeth account"
      alignHeader="flex-start"
    >
      <ForgotPasswordV2 />
    </ImageBasePage>
  );
};

export default ForgotPassword;
