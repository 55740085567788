import React from 'react';
import { DefaultBasePage } from '@components';
import { useGetCommunityUserQuery } from '@fdha/graphql-api-patient';
import { RestrictedUser } from '@fdha/web-ui-library';
import { useNavigate } from 'react-router';
import fdhaConfig from '@fdha/aws-config-patient';

const CommunityRestricted = () => {
  const { data, loading } = useGetCommunityUserQuery({
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  const reason = data?.getCommunityUser?.restrictionReason ?? '';

  return (
    <DefaultBasePage
      i18nKeyTitle="community:title"
      title="Community"
      contentSize="small"
    >
      <RestrictedUser
        reason={reason}
        guidelinesLink={`https://${fdhaConfig.cloudfront}/FDHACommunityGuidelines.html`}
        onLeave={() => navigate('/')}
        loading={loading}
      />
    </DefaultBasePage>
  );
};

export default CommunityRestricted;
