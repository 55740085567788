import { ImageProps } from '@components';
import { Typography, useDialog } from '@fdha/web-ui-library';
import { MeUserTypeInput } from '@fdha/graphql-api-patient';
import { Box, Card, CardActionArea, CardMedia, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from '@fdha/common-hooks';

import { CreateAccountResponse } from './createAccountScreens';

interface UserTypeProps {
  index: number;
  setImage: (value: ImageProps) => void;
  setIndex: (index: number) => void;
  setTitle: (title: string) => void;
}

interface CardSelectOptions {
  coverImage: string;
  image: string;
  altText: string;
  title: string;
  subtitle: string;
  value: MeUserTypeInput;
  i18nKeyTitle: string;
  i18nKeySubtitle: string;
}

const UserType: FC<UserTypeProps> = ({
  index,
  setImage,
  setIndex,
  setTitle,
}) => {
  const { openDialogV2, closeDialog } = useDialog();
  const { setFieldValue } = useFormikContext<CreateAccountResponse>();
  const theme = useTheme();
  const { translate } = useTranslation();

  const cardSelectOptions: CardSelectOptions[] = [
    {
      coverImage: '/images/man_02_small.png',
      image: '/images/man_02.png',
      altText: 'Old man',
      title: 'I am a Patient.',
      i18nKeyTitle: 'login:createAccount.step1.patient.title',
      subtitle: 'I have been diagnosed with cancer.',
      i18nKeySubtitle: 'login:createAccount.step1.patient.subtitle',
      value: MeUserTypeInput.Patient,
    },
    {
      coverImage: '/images/woman_06_small.png',
      image: '/images/woman_06.png',
      altText: 'Old woman',
      title: 'I am a Caretaker.',
      i18nKeyTitle: 'login:createAccount.step1.caretaker.title',
      subtitle:
        'I am a family member, friend or professional helping a patient.',
      i18nKeySubtitle: 'login:createAccount.step1.caretaker.subtitle',
      value: MeUserTypeInput.Caretaker,
    },
  ];

  const caretakerDialogText =
    'If you are a Caretaker and would still like to join Faeth, we are ' +
    'happy to have you and we value your feedback!\n\n' +
    'We will assign you a Coach, but please keep in mind that there will ' +
    'be a few features in the app that do not apply to you!';

  const handleClick = (item: CardSelectOptions) => {
    if (item.value === MeUserTypeInput.Caretaker) {
      openDialogV2({
        title: 'Faeth is intended for Patients, for now. But...',
        i18nKeyTitle: 'login:createAccount.step1.caretaker.dialog.title',
        content: (
          <Typography
            variant="body1"
            i18nKey="login:createAccount.step1.caretaker.dialog.subtitle"
            color={theme.palette.text.secondary}
            whiteSpace="break-spaces"
          >
            {caretakerDialogText}
          </Typography>
        ),
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Continue',
        handleConfirm: () => handleNext(item),
      });
    } else {
      handleNext(item);
    }
  };

  const handleNext = async (item: CardSelectOptions) => {
    const title = `Create your ${item.value} account`;
    closeDialog();
    setImage({
      url: item.image,
      altText: item.altText,
    });
    setTitle(
      translate(`login:createAccount.step2.${item.value}Title`, title, {
        userType: item.value,
      })
    );
    setFieldValue('userType', item.value);
    setIndex(index + 1);
  };

  return (
    <>
      {cardSelectOptions.map((item) => (
        <Card
          sx={{
            mb: 2,
            border: `1px solid ${grey[700]}`,
            '&:hover': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
          }}
          key={item.value}
        >
          <CardActionArea onClick={() => handleClick(item)}>
            <CardMedia
              image={item.coverImage}
              sx={{
                height: '118px',
              }}
            />
            <Box padding={2}>
              <Typography variant="h5" i18nKey={item.i18nKeyTitle}>
                {item.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color={theme.palette.text.secondary}
                i18nKey={item.i18nKeySubtitle}
              >
                {item.subtitle}
              </Typography>
            </Box>
          </CardActionArea>
        </Card>
      ))}
    </>
  );
};

export default UserType;
