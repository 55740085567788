import React, { FC } from 'react';
import { Grid, GridProps, Skeleton } from '@mui/material';

interface LoadingStateProps {
  type: 'article' | 'course' | 'recipe';
  amount: number;
  height: string;
  gridProps?: GridProps;
}

const LoadingState: FC<LoadingStateProps> = ({
  type,
  amount,
  height,
  gridProps,
}) => {
  return (
    <>
      {[...Array(amount)].map((_item, index) => (
        <Grid
          item
          xs={gridProps?.xs || 6}
          md={gridProps?.md || 4}
          key={`${type}_${index}`}
        >
          <Skeleton variant="rectangular" height={height} />
        </Grid>
      ))}
    </>
  );
};

export default LoadingState;
