import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useGetLastAssayResultsQuery } from '@fdha/graphql-api-patient';
import { Button } from '@fdha/web-ui-library';
import { DataEmptyState } from '@components';
import { useNavigate } from 'react-router';

import AminoAcidItem from './AminoAcidItem';
import LoadingAminoAcidItem from './LoadingAminoAcidItem';

const HomeTrialData = () => {
  const { data, loading } = useGetLastAssayResultsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  const results = useMemo(
    () =>
      [...(data?.lastAssayResults ?? [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [data?.lastAssayResults]
  );

  const handleDetails = (type: string = 'glycine') => {
    navigate('/trial-data', {
      state: { type },
    });
  };

  const showEmptyState = !loading && results?.length === 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {showEmptyState ? (
        <DataEmptyState />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid container rowSpacing={4} columnSpacing={2} marginBottom={5}>
            {loading
              ? [...Array(3)].map((_, index) => (
                  <Grid item xs={6} md={4} key={index}>
                    <LoadingAminoAcidItem />
                  </Grid>
                ))
              : results.map((item) => (
                  <Grid item xs={6} md={4} key={item.value}>
                    <AminoAcidItem
                      item={item}
                      handleClick={() => handleDetails(item.name.toLowerCase())}
                    />
                  </Grid>
                ))}
          </Grid>
          <Button
            onClick={() => handleDetails()}
            endEvaIcon={{ name: 'arrow-forward-outline' }}
            i18nKey="home:trial.button"
          >
            See more details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HomeTrialData;
