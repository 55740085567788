import React from 'react';
import { DefaultBasePage } from '@components';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  FaqCategory,
  useGetFaqCategoriesQuery,
} from '@fdha/graphql-api-patient';
import { Typography } from '@fdha/web-ui-library';

import List from './List';
import ButtonsBugAndFeedback from './ButtonsBugAndFeedback';

const Help = () => {
  const navigate = useNavigate();

  const { data: faqCategoriesData, loading } = useGetFaqCategoriesQuery();

  const faqCategories = faqCategoriesData?.faqCategories || [];
  const showFaqLoader = loading && !faqCategories.length;

  const renderFAQCategoryListItem = (listData: { item: FaqCategory }) => {
    const { item } = listData;
    return (
      <List.Item
        key={item.id}
        title={item.name}
        type="category"
        handleClick={() => navigate(`/profile/help/${item.id}`)}
      />
    );
  };

  return (
    <DefaultBasePage
      title="Help"
      contentSize="small"
      i18nKeyTitle="profile:help.title"
    >
      <ButtonsBugAndFeedback />
      <Typography
        variant="body1"
        fontWeight="bold"
        i18nKey="profile:help.subtitle"
      >
        What can we help you with?
      </Typography>
      <Box mt="12px">
        {showFaqLoader ? (
          <List.Loading />
        ) : (
          faqCategories.map((faqCategory) =>
            renderFAQCategoryListItem({ item: faqCategory })
          )
        )}
      </Box>
    </DefaultBasePage>
  );
};

export default Help;
