import { TextFieldV2, Typography } from '@fdha/web-ui-library';
import { BinaryQuestionProps, SingleChoiceQuestionProps } from '@models';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface SingleChoiceViewProps {
  question: SingleChoiceQuestionProps | BinaryQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

interface SingleChoiceValue {
  option: string;
  otherOption: string;
}

interface Context {
  [key: string]: SingleChoiceValue;
}

export const SingleChoiceView: FC<SingleChoiceViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, errors, touched } = useFormikContext<Context>();

  const key = question.id;
  const showOtherOption = values[key].option === 'other';
  const error = touched[key]?.otherOption && errors[key]?.otherOption;

  return (
    <Stack spacing={2}>
      <FormControl>
        <RadioGroup
          data-testid="SINGLE_CHOICE_RADIO"
          key={key}
          value={values[key].option}
          onChange={(event) => {
            handleChange({ option: event.target.value, otherOption: '' });
          }}
        >
          {question.options.map((option) => (
            <FormControlLabel
              data-testid="SINGLE_CHOICE_RADIO_OPTION"
              key={option.value}
              label={
                <Typography i18nKey={option?.i18nKey}>
                  {option.label}
                </Typography>
              }
              value={option.value}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {showOtherOption && (
        <TextFieldV2
          value={values[key].otherOption}
          onChange={(event) =>
            handleChange({
              option: values[key].option,
              otherOption: event.target.value,
            })
          }
          error={!!error}
          helperText={error}
        />
      )}
    </Stack>
  );
};
