import React, { FC } from 'react';
import { DecimalTextFieldV2, TimeInputV2 } from '@fdha/web-ui-library';
import { BHBQuestionProps } from '@models';
import { Box, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import QuestionHeader from '../QuestionHeader';

interface BHBViewProps {
  question: BHBQuestionProps;
  handleChange: (
    value: {
      time: string;
      value: string;
    },
    name?: string
  ) => void;
}

export const BHBView: FC<BHBViewProps> = ({ question, handleChange }) => {
  const { values, errors, touched } = useFormikContext<any>();

  const key = question.id;
  const error = errors[key] as {
    time: string;
    value: string;
  };

  const bhbTouched = touched[key] as { time: boolean; value: boolean };
  const timeError = bhbTouched?.time ? error?.time : undefined;
  const valueError = bhbTouched?.value ? error?.value : undefined;
  const isRequired = !!question.required;

  const onChange = (name: string, value: string) => {
    handleChange({ ...values[key], [name]: value });
  };

  return (
    <Stack spacing={2}>
      <Box data-testid="BHB_TIME_QUESTION">
        <QuestionHeader
          title="At what time did you check your BHB?"
          showRequired={isRequired}
          i18nKeyTitle="surveys:bhbSurvey.questions.time.title"
        />
        <TimeInputV2
          title=""
          time={values[key].time}
          dayPeriod={values[key].dayPeriod}
          error={timeError}
          onChange={onChange}
        />
      </Box>
      <Box data-testid="BHB_VALUE_QUESTION">
        <QuestionHeader
          title="What was your BHB value?"
          subtitle="Please write the full number including the decimal. Eg. 0.8"
          showRequired={isRequired}
          i18nKeyTitle="surveys:bhbSurvey.questions.value.title"
          i18nKeySubtitle="surveys:bhbSurvey.questions.value.subtitle"
        />
        <DecimalTextFieldV2
          unit="mmol/L"
          placeholder="0.0"
          i18nKeyPlaceholder="surveys:bhbSurvey.questions.value.placeholder"
          value={values[key].value}
          error={!!valueError}
          helperText={valueError}
          handleChange={(value) => onChange('value', value)}
          numberOfDecimals={1}
          maxLength={3}
        />
      </Box>
    </Stack>
  );
};
