import React from 'react';
import {
  Activity,
  ActivityKind,
  Appointment,
  Goal,
  Task,
} from '@fdha/graphql-api-patient';
import { useDialog, useSnackbar } from '@fdha/web-ui-library/';

import AddOrEditGoal from './AddGoal/AddOrEditGoal';
import AddOrEditTask from './AddTask/AddOrEditTask';
import AddOrEditAppt from './AddAppt/AddOrEditAppt';

interface EditActivityProps {
  data: Activity;
}

const EditActivity: React.FC<EditActivityProps> = ({ data }) => {
  const { closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();

  const onCancel = () => {
    closeDialog();
    showSnackbarV2({
      message: 'Changes Not Saved',
      severity: 'info',
      i18nKey: 'common:snackbar.changesNotSaved',
    });
  };

  return (
    <>
      {data.kind === ActivityKind.Goal ? (
        <AddOrEditGoal data={data as Goal} onCancel={onCancel} />
      ) : data.kind === ActivityKind.Task ? (
        <AddOrEditTask data={data as Task} onCancel={onCancel} />
      ) : (
        <AddOrEditAppt data={data as Appointment} onCancel={onCancel} />
      )}
    </>
  );
};

export default EditActivity;
