import { useDialog, useLoadingBar, useSnackbar } from '@fdha/web-ui-library';
import {
  AddDocumentInput,
  DocumentAccess,
  DocumentInfo,
  DocumentOverwriteOption,
  useAddDocumentMutation,
  useGetDocumentInfoLazyQuery,
} from '@fdha/graphql-api-patient';
import { doPut } from '@utils';
import { ChangeEvent } from 'react';
import { useAnalytics } from '@fdha/common-hooks';

export const useUploadDocuments = (refetch: () => void) => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { showLoadingV2, hideLoading } = useLoadingBar();
  const [addDocument] = useAddDocumentMutation();
  const { analyticsClient } = useAnalytics();

  const [getDocumentInfo, { refetch: refetchDocumentsInfo }] =
    useGetDocumentInfoLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ document }) => handleGetDocumentInfo(document),
    });

  const handleAddDocument = async (
    doc: AddDocumentInput,
    overwriteOption?: DocumentOverwriteOption
  ) => {
    try {
      const response = await addDocument({
        variables: {
          doc: {
            ...doc,
            overwriteOption,
          },
        },
      });
      return response.data?.addDocument;
    } catch (e) {
      showSnackbarV2({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
        i18nKey: 'profile:documents.snackbar.unableUploadFile',
      });
      console.log(e);
    }
  };

  const uploadFileToS3 = async (
    file: File,
    presignedData?: DocumentAccess | null
  ) => {
    if (presignedData) {
      const id = presignedData.document.id || '';
      const url = presignedData.url || '';
      await doPut(url, file, false);
      await getDocumentInfo({
        variables: { id },
      });
    }
  };

  const handleGetDocumentInfo = async (document?: DocumentInfo | null) => {
    setTimeout(async () => {
      try {
        if (!document) {
          await refetchDocumentsInfo();
        } else {
          hideLoading();
          refetch();
          showSnackbarV2({
            message: 'File Uploaded',
            severity: 'success',
            i18nKey: 'profile:documents.snackbar.fileUploaded',
          });
        }
      } catch (e) {
        console.log(e);
      }
    }, 2000);
  };

  const handleOverwriteFile = async (
    doc: AddDocumentInput,
    overwriteOption: DocumentOverwriteOption,
    file: File,
    refetch: () => void
  ) => {
    try {
      closeDialog();
      showLoadingV2();
      const documentData = await handleAddDocument(doc, overwriteOption);
      await uploadFileToS3(file, documentData);
    } catch (e) {
      hideLoading();
      console.log(e);
      showSnackbarV2({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
        i18nKey: 'profile:documents.snackbar.unableUploadFile',
      });
    }
  };

  const uploadDocument = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];

      const doc: AddDocumentInput = {
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
      };

      showLoadingV2();

      const documentData = await handleAddDocument(doc);

      if (documentData?.overwrite) {
        hideLoading();
        openDialogV2({
          title:
            'A file with this name already exists. Would you like to replace the existing one?',
          content: '',
          cancelButtonLabel: 'Keep Both Files',
          i18nKeyCancelButton: 'common:button.keep both files',
          confirmButtonLabel: 'Replace',
          i18nKeyConfirmButton: 'common:button.replace',
          handleCancel: async () => {
            await handleOverwriteFile(
              doc,
              DocumentOverwriteOption.Preserve,
              file,
              refetch
            );
            closeDialog();
          },
          handleConfirm: async () => {
            await handleOverwriteFile(
              doc,
              DocumentOverwriteOption.Overwrite,
              file,
              refetch
            );
            closeDialog();
          },
          handleDismiss: () => {
            closeDialog();
          },
          i18nKeyTitle: 'profile:documents.dialog.fileExistsTitle',
        });
      } else {
        await uploadFileToS3(file, documentData);
      }
      analyticsClient?.track('Documents Uploaded', {
        id: documentData?.document.id,
      });
    } catch (error) {
      showSnackbarV2({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
        i18nKey: 'profile:documents.snackbar.unableUploadFile',
      });
      hideLoading();
      console.log(error);
    } finally {
      e.target.value = '';
    }
  };

  return { uploadDocument };
};
