import React, { useRef } from 'react';
import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import { LoadMore } from '@fdha/web-ui-library';
import { Article, useGetArticlesQuery } from '@fdha/graphql-api-patient';
import { Grid } from '@mui/material';

import ArticleCard from '../ArticleCard';
import EmptyState from '../EmptyState';
import LoadingState from '../LoadingState';

const Articles = () => {
  const {
    data: articlesData,
    networkStatus,
    fetchMore,
  } = useGetArticlesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const hasMoreResults = useRef(true);

  const articles = articlesData?.articles || [];
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const showEmptyState = !articles.length && !isLoading;

  const renderArticleItem = (item: Article) => {
    const { title, createdBy, coverImage, id, url } = item;
    return (
      <Grid item xs={6} md={4} key={item.id}>
        <ArticleCard
          title={title}
          createdBy={createdBy}
          coverImage={coverImage}
          id={id}
          url={url ?? undefined}
        />
      </Grid>
    );
  };

  const loadMore = async () => {
    if (!hasMoreResults.current || isLoadingMore) {
      return;
    }

    const result = await fetchMore({
      variables: { offset: articles.length },
    });

    if (!result.data.articles.length) {
      hasMoreResults.current = false;
    }
  };

  return (
    <DefaultBasePage
      title="Explore"
      contentSize="large"
      i18nKeyTitle="learning:article.title"
    >
      {!showEmptyState ? (
        <Grid container rowSpacing={2} columnSpacing={3}>
          {isLoading ? (
            <LoadingState type="article" height="202px" amount={8} />
          ) : (
            <>
              {articles.map((item) => renderArticleItem(item))}
              <LoadMore
                onLoadMore={loadMore}
                listLength={articles.length}
                isLoadingMore={isLoadingMore}
              />
            </>
          )}
        </Grid>
      ) : (
        <EmptyState type="article" />
      )}
    </DefaultBasePage>
  );
};

export default Articles;
