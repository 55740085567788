import { resendSignUpCode } from '@aws-amplify/auth';
import {
  TextFieldV2,
  Typography,
  useDialog,
  useSnackbar,
  Link,
} from '@fdha/web-ui-library';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { CreateAccountResponse } from './createAccountScreens';

const Code = () => {
  const theme = useTheme();
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();

  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<CreateAccountResponse>();

  const [isLinkDisabled, setIsLinkDisabled] = useState(false);

  const error = touched.code ? errors.code : null;

  useEffect(() => {
    let isMounted = true;

    if (!isLinkDisabled) {
      return;
    }

    const timer = setTimeout(() => {
      if (isMounted) {
        setIsLinkDisabled(false);
      }
    }, 15000);

    return () => {
      clearTimeout(timer);
      isMounted = false;
    };
  }, [isLinkDisabled]);

  const resendConfirmationCode = async () => {
    try {
      setIsLinkDisabled(true);

      await resendSignUpCode({ username: values.email });

      openDialogV2({
        title: 'We just sent you another code.',
        content: null,
        confirmButtonLabel: 'OK',
        handleConfirm: async () => {
          closeDialog();
        },
        i18nKeyTitle:
          'login:createAccount.step3to7.input.smsCode.justSentAnother',
      });
    } catch (error: any) {
      showSnackbarV2({ message: error.message, severity: 'error' });
    }
  };

  return (
    <>
      <Typography
        i18nKey="login:createAccount.step3to7.input.smsCode.label"
        i18nParams={{ number: values.phoneNumber }}
        variant="h5"
      >
        Please enter the <strong>verification code</strong> we just sent via SMS
        to
        {` ${values.phoneNumber}`}
      </Typography>
      <TextFieldV2
        name="code"
        placeholder="xxxxxx"
        i18nKeyPlaceholder="login:createAccount.step3to7.input.smsCode.placeholder"
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        helperText={error}
      />
      <Typography color={theme.palette.text.secondary} sx={{ mt: 2 }}>
        {isLinkDisabled ? (
          <Typography i18nKey="login:createAccount.step3to7.input.smsCode.timeout">
            Wait 15 seconds before requesting again...
          </Typography>
        ) : (
          <>
            <Typography
              display="inline"
              i18nKey="login:createAccount.step3to7.input.smsCode.didntGetIt"
            >
              Didn't get it?
            </Typography>
            <Link
              display="inline"
              onClick={resendConfirmationCode}
              sx={{ cursor: 'pointer' }}
            >
              <Typography
                display="inherit"
                i18nKey="login:createAccount.step3to7.input.smsCode.sendAnother"
              >
                Send another code.
              </Typography>
            </Link>
          </>
        )}
        <br />
        <Typography i18nKey="login:createAccount.step3to7.input.smsCode.pleaseContact">
          If you still didn’t get the code, please contact
        </Typography>
        <Link
          display="inherit"
          href="mailto:developer@faeththerapeutics.com"
          target="_blank"
          i18nKey="login:createAccount.step3to7.input.smsCode.link"
        >
          developer@faeththerapeutics.com
        </Link>
      </Typography>
    </>
  );
};

export default Code;
