import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ExplanationViewProps } from '@utils';
import { Typography } from '@fdha/web-ui-library';

export const ExplanationView: FC<ExplanationViewProps> = ({
  title,
  i18nKeyTitle,
  content,
}) => {
  return (
    <>
      <Typography
        data-testid="EXPLANATION_VIEW_TITLE"
        variant="h4"
        mb={3}
        i18nKey={i18nKeyTitle}
      >
        {title}
      </Typography>
      {content?.map((item, index) => {
        return (
          <Box data-testid="EXPLANATION_VIEW_BODY" key={index}>
            <Typography
              i18nKey={
                item?.i18nkey?.startsWith('surveys:')
                  ? item.i18nkey
                  : `surveys:${item?.i18nkey}`
              }
            >
              {item?.data}
            </Typography>
            <br />
          </Box>
        );
      })}
    </>
  );
};
