import { Stack } from '@mui/material';

import GoalCard from './GoalCard';

interface RadioOption {
  value: string;
  i18nKey?: string;
  label: string;
  icon: string;
}

interface GoalCardGroupProps {
  options: RadioOption[];
  selectedValue: string;
  onClick: (value: string) => void;
}

const GoalCardGroup: React.FC<GoalCardGroupProps> = (props) => {
  const renderItem = (listItem: RadioOption) => {
    return (
      <GoalCard
        i18nKey={listItem.i18nKey}
        label={listItem.label}
        icon={listItem.icon}
        isChecked={listItem.value === props.selectedValue}
        onClick={() => props.onClick(listItem.value)}
      />
    );
  };

  return <Stack spacing={1.5}>{props.options.map(renderItem)}</Stack>;
};

export default GoalCardGroup;
