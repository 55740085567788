import { Icon, Typography } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { WeekDay } from '@fdha/graphql-api-patient';

const weekDays = [
  WeekDay.Mon,
  WeekDay.Tue,
  WeekDay.Wed,
  WeekDay.Thu,
  WeekDay.Fri,
  WeekDay.Sat,
  WeekDay.Sun,
];

interface ItemProps {
  label: string;
  day: number;
  selected: boolean;
}

const WeekDayItem: FC<ItemProps> = ({ label, day, selected }) => {
  const theme = useTheme();

  return (
    <Stack flex={1} justifyContent="center" textAlign="center" spacing={0.5}>
      <Typography
        variant="subtitle2"
        color={theme.palette.text.secondary}
        i18nKey={`meals:schedule.date.${label.toLowerCase()}`}
        i18nParams={{ day: day.toString() }}
      >
        {`${label}, ${day}`}
      </Typography>
      <Box
        border="1px solid"
        borderColor={
          selected
            ? theme.palette.success.border
            : theme.palette.action.disabled
        }
        padding={0.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
        borderRadius="4px"
        sx={{
          backgroundColor: selected
            ? theme.palette.success.lightBg
            : theme.palette.action.disabledBackground,
        }}
      >
        <Icon
          name={selected ? 'checkmark-circle-2' : 'close-circle'}
          fill={selected ? theme.palette.success.main : theme.palette.grey[700]}
          size="large"
        />
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={
              selected
                ? theme.palette.success.textDark
                : theme.palette.text.disabled
            }
            i18nKey={
              selected ? 'meals:schedule.onDiet' : 'meals:schedule.offDiet'
            }
          >
            {selected ? 'On Diet' : 'Off Diet'}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

interface Props {
  selectedDays: WeekDay[];
}

const weekDaysOnMonth = Array(7)
  .fill(new Date())
  .map(
    (val, index) =>
      new Date(val.setDate(val.getDate() - val.getDay() + index + 1))
  );

const WeekSchedule: FC<Props> = ({ selectedDays }) => {
  return (
    <Stack spacing={2} direction="row" mb={4}>
      {weekDays.map((weekDay, index) => (
        <WeekDayItem
          key={weekDay}
          label={weekDay}
          day={weekDaysOnMonth[index].getDate()}
          selected={selectedDays.includes(weekDay)}
        />
      ))}
    </Stack>
  );
};

export default WeekSchedule;
