import React, { useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import { InfiniteScrollList, SelectV2 } from '@fdha/web-ui-library';
import {
  DocumentInfo,
  DocumentOwnerFilter,
  useListDocumentsQuery,
} from '@fdha/graphql-api-patient';
import { Box, Skeleton, Stack } from '@mui/material';
import { getSortOrder, selectItems } from '@utils';
import { useDocuments } from '@hooks';

import DocumentCard from './DocumentCard';
import EmptyCard from './EmptyCard';

const CoachesUploads = () => {
  const { handleDownloadDocument } = useDocuments();

  const [sortBy, setSortBy] = useState(selectItems[0].value);

  const {
    data: documentsData,
    networkStatus,
    fetchMore,
    loading,
  } = useListDocumentsQuery({
    variables: {
      sortBy: {
        sortBy: [
          {
            field: sortBy,
            order: getSortOrder(sortBy),
          },
        ],
      },
      ownerFilter: DocumentOwnerFilter.Coach,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const documentsList =
    documentsData?.documents?.edges.map((edge) => edge.node) || [];
  const pageInfo = documentsData?.documents.pageInfo;

  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const loadMore = () => {
    if (!pageInfo?.hasNextPage || networkStatus === NetworkStatus.fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    });
  };

  const renderItem = (item: DocumentInfo) => (
    <DocumentCard document={item} handleDownload={handleDownloadDocument} />
  );

  const renderEmptyState = () => <EmptyCard isCoach />;

  const renderLoading = () => (
    <Stack spacing={2}>
      {[...Array(6)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={79} />
      ))}
    </Stack>
  );

  return (
    <DefaultBasePage
      i18nKeyTitle="profile:documents.folders.coachesUploads.title"
      title="Coaches Uploads"
      contentSize="small"
    >
      <Box display="flex">
        <SelectV2
          options={selectItems}
          useFirstOptionAsDefault
          variant="standard"
          onChange={(event) => {
            const value = event.target.value as string;
            setSortBy(value);
          }}
          sx={{ my: 2 }}
        />
      </Box>
      {loading ? (
        renderLoading()
      ) : (
        <InfiniteScrollList
          items={documentsList}
          renderItem={renderItem}
          loadMore={loadMore}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          renderEmptyState={renderEmptyState}
        />
      )}
    </DefaultBasePage>
  );
};

export default CoachesUploads;
