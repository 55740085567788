import { TextFieldV2, Typography } from '@fdha/web-ui-library';
import { useFormikContext } from 'formik';
import React from 'react';

import { CreateAccountResponse } from './createAccountScreens';

const FirstName = () => {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<CreateAccountResponse>();

  const error = touched.firstName ? errors.firstName : null;

  return (
    <>
      <Typography
        i18nKey="login:createAccount.step3to7.input.firstName.label"
        variant="h5"
      >
        What is your <b>first name</b>?
      </Typography>
      <TextFieldV2
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        helperText={error}
      />
    </>
  );
};

export default FirstName;
