import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Button, Link, Loader, Typography } from '@fdha/web-ui-library';
import { getPatientDeepLink } from '@utils';
import { PrivacyPolicyUtils, TermsAndConditionsUtils } from '@fdha/web-auth';
import { getEnv } from '@fdha/web-auth/src/utils/commonApi';
import { useIsMounted } from '@fdha/common-hooks';

const Download = () => {
  const theme = useTheme();
  const isBelow360 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(360)
  );

  const checkIsMounted = useIsMounted();

  const [privacyPolicy, setPrivacyPolicy] = useState<string | undefined>(
    undefined
  );
  const [terms, setTerms] = useState<string | undefined>(undefined);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  const [loadingPolicy, setLoadingPolicy] = useState<boolean>(true);
  const [loadingTerms, setLoadingTerms] = useState<boolean>(true);
  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState<boolean>(true);

  useEffect(() => {
    const getPrivacyPolicy = async () => {
      try {
        const privacyPolicyLink = await PrivacyPolicyUtils.getPolicyLink();

        checkIsMounted() && setPrivacyPolicy(privacyPolicyLink);
      } catch (error) {
        console.error('Error to get privacy policy');
      } finally {
        checkIsMounted() && setLoadingPolicy(false);
      }
    };

    const getTerms = async () => {
      try {
        const termsLink = await TermsAndConditionsUtils.getTermsLink();

        checkIsMounted() && setTerms(termsLink);
      } catch (error) {
        console.error('Error to get terms and conditions');
      } finally {
        checkIsMounted() && setLoadingTerms(false);
      }
    };

    const getDownloadUrl = async () => {
      try {
        const environment = await getEnv();

        checkIsMounted() && setDownloadUrl(getPatientDeepLink(environment));
      } catch (error) {
        console.error('Error to get download url');
      } finally {
        checkIsMounted() && setLoadingDownloadUrl(false);
      }
    };

    if (!downloadUrl) {
      getDownloadUrl();
    }

    if (!privacyPolicy) {
      getPrivacyPolicy();
    }

    if (!terms) {
      getTerms();
    }
  }, [downloadUrl, privacyPolicy, terms, checkIsMounted]);

  const getLink = useCallback(
    (url: string, text: string, i18nKey: string) => (
      <Link
        href={url}
        target="_blank"
        color={theme.palette.secondary.contrastText}
        i18nKey={i18nKey}
      >
        {text}
      </Link>
    ),
    [theme.palette.secondary.contrastText]
  );

  const aboutLink = useMemo(
    () =>
      getLink(
        'https://faeththerapeutics.com/about',
        'About us',
        'download:about'
      ),
    [getLink]
  );
  const privacyLink = useMemo(
    () =>
      privacyPolicy
        ? getLink(privacyPolicy, 'Privacy Policy', 'download:privacy')
        : undefined,
    [getLink, privacyPolicy]
  );
  const termsLink = useMemo(
    () =>
      terms ? getLink(terms, 'Terms of Use', 'download:terms') : undefined,
    [getLink, terms]
  );

  if (loadingPolicy || loadingTerms || loadingDownloadUrl) {
    return <Loader />;
  }

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={'/images/man_04.png'}
          alt="Man"
          width="100%"
          style={{ objectFit: 'cover' }}
        />
        <Box
          px={isBelow360 ? '20px' : '40px'}
          mt={isBelow360 ? '16px' : '27px'}
        >
          <img
            width="105px"
            height="32px"
            src="/images/Faeth_Logo_Small.png"
            alt="Faeth logo"
          />
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h6"
              py={isBelow360 ? 1 : 2}
              i18nKey="download:title"
            >
              Please download the Faeth app to continue use on a mobile device.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: isBelow360 ? '24px' : '20px' }}
              i18nKey="download:subtitle"
            >
              Spend more time on healing and less time on care coordination.
              Download our convenient and simple-to-use app.
            </Typography>

            <Button
              type="submit"
              variant="contained"
              href={downloadUrl}
              sx={{
                width: isBelow360 ? '320px' : '342px',
              }}
              i18nKey="download:button"
            >
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        mt={isBelow360 ? '24px' : '42px'}
        sx={{
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Box ml={isBelow360 ? '20px' : 2} display="flex" flexDirection="column">
          <Typography
            variant="body1"
            color={theme.palette.secondary.contrastText}
            mt={2}
            mb={isBelow360 ? '9px' : '25px'}
            i18nKey="download:faeth"
            i18nParams={{ currentYear }}
          >
            Faeth Therapeutics Inc. {currentYear}
          </Typography>
          <Typography variant="caption" mb={isBelow360 ? '18px' : '34px'}>
            {aboutLink}
          </Typography>
          <Typography variant="caption" mb={isBelow360 ? '18px' : '34px'}>
            {privacyLink}
          </Typography>
          <Typography variant="caption" mb="25px">
            {termsLink}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Download;
