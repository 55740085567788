import { Icon, Typography } from '@fdha/web-ui-library';
import { Card, CardActionArea, useTheme } from '@mui/material';

const FolderCard = (props: {
  title: string;
  i18nKeyTitle: string;
  handleClick: () => void;
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: theme.shadows[2],
        flex: 1,
        display: 'flex',
      }}
    >
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 2,
          justifyContent: 'flex-start',
        }}
        onClick={props.handleClick}
      >
        <Icon
          name="folder-outline"
          fill={theme.palette.text.primary}
          size="large"
          sx={{ pr: 2 }}
        />
        <Typography i18nKey={props.i18nKeyTitle} variant="h6">
          {props.title}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default FolderCard;
