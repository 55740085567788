import { ContentSize, ImageProps } from '@components';
import { MeUserTypeInput } from '@fdha/graphql-api-patient';

export enum CreateAccountScreenType {
  UserType = 'userType',
  LoginInfo = 'loginInfo',
  FirstName = 'firstName',
  LastName = 'lastName',
  BirthDate = 'birthDate',
  PhoneNumber = 'phoneNumber',
  Code = 'code',
}

interface CreateAccountScreenProps {
  id: CreateAccountScreenType;
  name: string;
  hideNextButton?: boolean;
  title?: string;
  i18nKeyTitle?: string;
  showLogo?: boolean;
  image?: ImageProps;
  contentSize?: ContentSize;
}

export interface CreateAccountResponse {
  userType?: MeUserTypeInput;
  email: string;
  password: string;
  confirmPassword: string;
  rememberMe: boolean;
  acceptedSms: boolean;
  acceptedTos: boolean;
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  phoneNumber: string;
  confirmPhoneNumber: string;
  code: string;
}

export const createAccountInitialValues: CreateAccountResponse = {
  userType: undefined,
  email: '',
  password: '',
  confirmPassword: '',
  rememberMe: false,
  acceptedSms: true,
  acceptedTos: false,
  isAuthenticated: false,
  firstName: '',
  lastName: '',
  birthDate: null,
  phoneNumber: '',
  confirmPhoneNumber: '',
  code: '',
};

export const initialImage = {
  url: '/images/food_02.png',
  altText: 'Food',
};

export const createAccountScreens: CreateAccountScreenProps[] = [
  {
    id: CreateAccountScreenType.UserType,
    name: 'User Type',
    title: 'What describes you best?',
    i18nKeyTitle: 'login:createAccount.step1.title',
    showLogo: false,
    image: initialImage,
    contentSize: 'small',
    hideNextButton: true,
  },
  {
    id: CreateAccountScreenType.LoginInfo,
    name: 'Login Info',
  },
  {
    id: CreateAccountScreenType.FirstName,
    name: 'First Name',
    title: 'Welcome to Faeth! Tell us more about yourself.',
    i18nKeyTitle: 'login:createAccount.step3to7.title',
    showLogo: false,
  },
  {
    id: CreateAccountScreenType.LastName,
    name: 'Last Name',
    title: 'Welcome to Faeth! Tell us more about yourself.',
    i18nKeyTitle: 'login:createAccount.step3to7.title',
    showLogo: false,
  },
  {
    id: CreateAccountScreenType.BirthDate,
    name: 'Birth Date',
    title: 'Welcome to Faeth! Tell us more about yourself.',
    i18nKeyTitle: 'login:createAccount.step3to7.title',
    showLogo: false,
  },
  {
    id: CreateAccountScreenType.PhoneNumber,
    name: 'Phone Number',
    title: 'Welcome to Faeth! Tell us more about yourself.',
    i18nKeyTitle: 'login:createAccount.step3to7.title',
    showLogo: false,
  },
  {
    id: CreateAccountScreenType.Code,
    name: 'Code',
    title: 'Welcome to Faeth! Tell us more about yourself.',
    i18nKeyTitle: 'login:createAccount.step3to7.title',
    showLogo: false,
  },
];
