import {
  ActivitySchema,
  buildDateTime,
  formatDate,
} from '@fdha/web-ui-library';
import {
  Activity,
  ActivityFrequency,
  ActivityReminder,
  WeekDay,
} from '@fdha/graphql-api-patient';
import { format, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getSchemaInitialValues = (data?: Activity): ActivitySchema => {
  const defaultTime = '08:00';
  const defaultDate = new Date();
  const defaultWeekDay = format(new Date(), 'EEE') as WeekDay;

  return {
    title: data?.title ?? '',
    description: data?.description ?? '',
    frequency: data?.frequency ?? ActivityFrequency.Once,
    reminder: data?.reminder ?? ActivityReminder.Never,
    date: data?.time ? new Date(data.time) : defaultDate,
    time: data?.time ? format(parseISO(data.time), 'hh:mm') : defaultTime,
    ends: data?.ends_at ? 'date' : 'never',
    ends_at: data?.ends_at
      ? new Date(utcToZonedTime(data.ends_at, 'UTC'))
      : undefined,
    dayPeriod: data?.time
      ? (format(parseISO(data.time), 'aaa') as 'am' | 'pm')
      : 'am',
    weekDays: data?.weekDays ?? [defaultWeekDay],
  };
};

type ActivityPayload = Omit<Activity, 'id' | 'instanceId' | 'kind'>;

export const buildInitialPayload = (
  values: ActivitySchema
): ActivityPayload => {
  const { date, time, dayPeriod, ends_at } = values;
  const parsed = buildDateTime(formatDate(date), time, dayPeriod);

  return {
    frequency: values.frequency,
    reminder: values.reminder,
    time: parsed,
    ends_at: ends_at
      ? parse(formatDate(ends_at), 'MM/dd/yyyy', new Date()).toISOString()
      : undefined,
    title: values.title,
    description: values.description,
    weekDays:
      values.frequency === ActivityFrequency.Weekly ||
      values.frequency === ActivityFrequency.Biweekly ||
      values.frequency === ActivityFrequency.Triweekly
        ? values.weekDays
        : undefined,
  };
};
