import React, { FC } from 'react';
import { DecimalTextFieldV2 } from '@fdha/web-ui-library';
import { DecimalQuestionProps } from '@models';
import { useFormikContext } from 'formik';
import { useTranslatedErrorMessages } from '@fdha/common-hooks';

interface DecimalValue {
  decimal: string;
  unit: string;
}

interface Context {
  [key: string]: DecimalValue;
}

interface DecimalViewProps {
  question: DecimalQuestionProps;
  handleChange: (value: DecimalValue) => void;
}

export const DecimalView: FC<DecimalViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, errors, touched } = useFormikContext<Context>();

  const { id, maxLength, placeholder, customPropsOfType } = question;
  const { min, max, unit, numberOfDecimals } = customPropsOfType;
  const { decimalBetweenMessage } = useTranslatedErrorMessages({
    min: min.toString(),
    max: max.toString(),
    unit: unit,
  });

  const key = id;
  const error = errors[key];
  const decimalTouched = touched[key];

  const valueError =
    decimalTouched?.decimal && error?.decimal
      ? decimalBetweenMessage
      : undefined;

  const onChange = (value: string) => {
    handleChange({ decimal: value, unit: value ? unit : '' });
  };

  return (
    <DecimalTextFieldV2
      unit={unit}
      placeholder={placeholder}
      maxLength={maxLength}
      numberOfDecimals={numberOfDecimals}
      value={values[key].decimal}
      error={!!valueError}
      helperText={valueError || decimalBetweenMessage}
      handleChange={onChange}
      i18nKeyHelper="surveys:steps.decimal.hint"
      i18nParamsHelper={{ min: String(min), max: String(max), unit: unit }}
    />
  );
};
