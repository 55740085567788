import { DefaultBasePage } from '@components';
import { ProfileChangePasswordV2 } from '@fdha/web-auth';

const ProfileChangePassword: React.FC<{ email?: string }> = ({ email }) => {
  return (
    <DefaultBasePage
      title="Change Password"
      contentSize="small"
      i18nKeyTitle="profile:changePassword.title"
    >
      <ProfileChangePasswordV2 email={email} />
    </DefaultBasePage>
  );
};

export default ProfileChangePassword;
