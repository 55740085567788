import { TextFieldV2 } from '@fdha/web-ui-library';
import { NumberQuestionProps } from '@models';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface NumberViewProps {
  question: NumberQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

interface Context {
  [key: string]: string;
}

export const NumberView: FC<NumberViewProps> = ({ question, handleChange }) => {
  const { values, handleBlur, errors, touched } = useFormikContext<Context>();

  const key = question.id;
  const error = touched[key] ? errors[key] : '';

  return (
    <TextFieldV2
      type="number"
      name={key}
      value={values[key]}
      placeholder={question.placeholder}
      onChange={(event) => handleChange(event.target.value)}
      onBlur={handleBlur}
      error={!!error}
      helperText={error}
    />
  );
};
