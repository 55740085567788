import { Button, SkeletonWrapper, Typography } from '@fdha/web-ui-library';
import { Box, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getBHBLevel, BHBLevel } from '@utils';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';

interface BHBInformationProps {
  value: number;
  limits: { bottom: number; upper: number };
  showSkeleton: boolean;
}

const BHBInformation: FC<BHBInformationProps> = ({
  value,
  limits,
  showSkeleton,
}) => {
  const navigate = useNavigate();
  const information = useMemo(() => {
    const bhbLevel = getBHBLevel(value, limits);

    switch (bhbLevel) {
      case BHBLevel.ABOVE:
        return {
          title: 'How to lower your BHB',
          label: 'lower',
          i18nKey: 'dataVisualization:bhb.clickRecommendations.lower.title',
        };
      case BHBLevel.BELOW:
        return {
          title: 'How to raise your BHB',
          label: 'raise',
          i18nKey: 'dataVisualization:bhb.clickRecommendations.raise.title',
        };
      default:
        return;
    }
  }, [value, limits]);

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      {information ? (
        <>
          <Divider sx={{ borderColor: grey[700] }} />
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              i18nKey={information.i18nKey}
            >
              {information.title}
            </Typography>
            <Typography
              marginTop={1}
              i18nKey={`dataVisualization:bhb.clickRecommendations.${information.label}.body`}
            >
              {`Click below to see recommendations for how to ${information.label}
              your BHB levels.`}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            startEvaIcon={{ name: 'book-open-outline' }}
            size="large"
            sx={{ width: 'fit-content' }}
            onClick={() => navigate(`/bhb-trial-data/${information.label}-bhb`)}
            i18nKey="dataVisualization:bhb.seeRecommendations"
          >
            See recommendations
          </Button>
        </>
      ) : (
        <></>
      )}
    </SkeletonWrapper>
  );
};

export default BHBInformation;
