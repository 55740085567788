import React from 'react';
import { theme, useDialog } from '@fdha/web-ui-library';
import { Button, Stack, Typography } from '@mui/material';

interface DiscardPostDialogContentProps {
  isEditMode: boolean;
  handleDiscard: () => void;
}

const DiscardPostDialogContent: React.FC<DiscardPostDialogContentProps> = ({
  isEditMode,
  handleDiscard,
}) => {
  const { closeDialog } = useDialog();

  const handleStay = () => {
    closeDialog();
  };

  return (
    <>
      <Typography variant="body1" color={theme.palette.text.secondary}>
        {isEditMode ? 'Changes will not be saved.' : 'Post will not be saved.'}
      </Typography>
      <Stack direction="row" justifyContent="flex-end" mt={2} spacing={1}>
        <Button variant="text" size="medium" onClick={handleStay}>
          Stay
        </Button>
        <Button variant="contained" size="medium" onClick={handleDiscard}>
          {isEditMode ? 'Discard Changes' : 'Discard Post'}
        </Button>
      </Stack>
    </>
  );
};

export default DiscardPostDialogContent;
