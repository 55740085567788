import React from 'react';
import { Card, CardActionArea, Stack, useTheme } from '@mui/material';
import { Icon, Typography } from '@fdha/web-ui-library';

interface GoalCardProps {
  label: string;
  i18nKey?: string;
  icon: string;
  isChecked: boolean;
  onClick: () => void;
}

const GoalCard: React.FC<GoalCardProps> = ({
  label,
  i18nKey,
  icon,
  isChecked,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Card>
      <CardActionArea
        onClick={onClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
          py: 2,
          px: 1.5,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Icon name={icon} fill={theme.palette.text.secondary} size="large" />
          <Typography
            i18nKey={i18nKey}
            variant="body1"
            color={theme.palette.text.primary}
            alignSelf="center"
            fontWeight={isChecked ? 'bold' : undefined}
            ml={1.5}
          >
            {label}
          </Typography>
        </Stack>
        <Icon
          name={isChecked ? 'radio-button-on' : 'radio-button-off-outline'}
          fill={
            isChecked
              ? theme.palette.primary.main
              : theme.palette.text.secondary
          }
          size="large"
        />
      </CardActionArea>
    </Card>
  );
};

export default GoalCard;
