import { ImageBasePage } from '@components';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { ResetPasswordV2 } from '@fdha/web-auth/src/components';
import { useLocation } from 'react-router';

interface StateProps {
  email: string;
  obscuredEmail: string;
}

const ResetPassword = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const email =
    state?.obscuredEmail ||
    'your email address associated to your Faeth account';
  const authStatus = useAuthStatus();
  const showLogo = authStatus === AuthStatus.SIGNED_OUT;

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Woman',
      }}
      titleVariant="h4"
      title="We sent you a code via email"
      i18nKeyTitle="login:resetPassword.title"
      subtitle={`Please enter the 6 digit code sent to ${email}`}
      i18nKeySubtitle={
        state?.obscuredEmail
          ? 'login:resetPassword.subtitle1'
          : 'login:resetPassword.subtitle2'
      }
      i18nParamsSubtitle={{ email: state?.obscuredEmail || '' }}
      alignHeader="flex-start"
      showLogo={showLogo}
    >
      <ResetPasswordV2 email={state?.email} />
    </ImageBasePage>
  );
};

export default ResetPassword;
