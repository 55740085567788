import {
  Box,
  Chip,
  IconButton,
  IconButtonProps,
  styled,
  useTheme,
} from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';
import {
  ChatAutoComplete,
  EmojiPicker,
  MessageInputProps as StreamMessageInputProps,
  UploadsPreview,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';
import './MessageInput.css';
import { Icon } from '@fdha/web-ui-library';
import { grey } from '@mui/material/colors';
import { useTranslation } from '@fdha/common-hooks';

import { GiphyContext } from '../ChannelInner';
import QuotedMessagePreview from '../quotedMessagePreview/QuotedMessagePreview';

const EmojiButton = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => {
  // This needs to be a div because emojiPickerRef corresponds to HTMLDivElement
  const Wrapper = styled('div')(({ theme }) => ({
    height: 24,
    margin: '0 20px',
    cursor: 'pointer',
    svg: {
      height: 24,
      width: 24,
      path: { fill: theme.palette.grey[600] },
    },
    ':hover': {
      'svg path': { fill: theme.palette.primary.main },
    },
  }));

  return (
    <Wrapper role="button" {...props} ref={ref}>
      <Icon name="smiling-face" fill={grey[900]} size="medium" />
    </Wrapper>
  );
});

const GiphyIndicator = () => (
  <Chip
    color="primary"
    label="GIPHY"
    size="small"
    sx={{ fontWeight: 'bold', fontSize: 12, marginLeft: 1 }}
  />
);

const SendButton = (props: IconButtonProps) => {
  const Button = styled(IconButton)(({ theme }) => ({
    margin: '0 20px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: 14,
    ':hover': {
      backgroundColor: theme.palette.primary.main,
    },
    svg: {
      height: 20,
      width: 20,
      path: { fill: theme.palette.primary.contrastText },
    },
  }));

  return (
    <Button {...props}>
      <Icon name="paper-plane-outline" fill={grey[50]} size="medium" />
    </Button>
  );
};

type MessageInputProps = StreamMessageInputProps & {
  preDefinedMessage?: string;
};

const MessageInput: React.FC<MessageInputProps> = ({ ...props }) => {
  const { translate } = useTranslation();
  const {
    setText,
    text,
    numberOfUploads,
    handleChange,
    handleSubmit,
    openEmojiPicker,
    uploadNewFiles,
    emojiPickerRef,
  } = useMessageInputContext();

  const { acceptedFiles, maxNumberOfFiles, multipleUploads, quotedMessage } =
    useChannelStateContext();
  const { giphyState, setGiphyState } = useContext(GiphyContext);
  const theme = useTheme();

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const { value } = event.target;

      const deletePressed =
        event.nativeEvent instanceof InputEvent &&
        event.nativeEvent.inputType === 'deleteContentBackward';

      if (text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (!giphyState && text.startsWith('/giphy') && !numberOfUploads) {
        event.target.value = value.replace('/giphy', '');
        setGiphyState(true);
      }

      handleChange(event);
    },
    [giphyState, handleChange, numberOfUploads, setGiphyState, text]
  );

  useEffect(() => {
    if (!!props.preDefinedMessage) {
      setText(props.preDefinedMessage);
    }
    return () => {
      setText('');
    };
  }, [props.preDefinedMessage, setText]);

  return (
    <>
      {quotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />}
      <Box display="flex" alignItems="center" padding="8px" position="relative">
        <Box alignItems="flex-end" ml={1} pt={1}>
          <FileUploadButton
            accepts={acceptedFiles}
            handleFiles={uploadNewFiles}
            multiple={multipleUploads}
            disabled={
              maxNumberOfFiles !== undefined &&
              numberOfUploads >= maxNumberOfFiles
            }
          >
            <Icon name="attach" size="large" fill={grey[900]} />
          </FileUploadButton>
        </Box>
        <EmojiButton
          onClick={openEmojiPicker}
          ref={emojiPickerRef}
          data-testid="EMOJI_BUTTON"
        />
        <Box
          width="100%"
          minHeight={3.75}
          height="fit-content"
          borderRadius="15px"
          border="2px solid"
          borderColor={theme.palette.text.secondary}
          overflow="auto"
        >
          <ImageDropzone
            accept={acceptedFiles}
            handleFiles={uploadNewFiles}
            multiple={multipleUploads}
            disabled={
              (maxNumberOfFiles !== undefined &&
                numberOfUploads >= maxNumberOfFiles) ||
              giphyState
            }
          >
            {giphyState && !numberOfUploads && <GiphyIndicator />}
            <UploadsPreview />
            <ChatAutoComplete
              onChange={onChange}
              rows={1}
              placeholder={translate(
                'chat:channel.sendMessage',
                'Send message'
              )}
            />
          </ImageDropzone>
        </Box>
        <SendButton onClick={handleSubmit} data-testid="SEND_BUTTON" />
        <EmojiPicker />
      </Box>
    </>
  );
};

export default MessageInput;
