import { makeVar } from '@apollo/client';

enum ChatScreenType {
  Media = 'Media',
  Channel = 'Channel',
  ChannelList = 'ChannelList',
}

const selectedChatScreenType = makeVar<ChatScreenType>(
  ChatScreenType.ChannelList
);

const setSelectedChatScreenType = (type: ChatScreenType) => {
  selectedChatScreenType(type);
};

export { ChatScreenType, selectedChatScreenType, setSelectedChatScreenType };
