import React from 'react';
import { RecipeBase, useGetRecipesV2Query } from '@fdha/graphql-api-patient';
import { Box, Grid } from '@mui/material';

import EmptyState from '../EmptyState';
import RecipeCard from '../RecipeCard';
import LoadingState from '../LoadingState';

import Header from './Header';

const RecipeSection = () => {
  const { data, loading } = useGetRecipesV2Query({
    variables: { first: 3 },
    fetchPolicy: 'network-only',
  });

  const recipes = data?.recipesV2.edges.map((edge) => edge.node) || [];

  const showEmptyState = !recipes.length && !loading;
  const showSeeAll = !loading && !!recipes.length;

  const renderItem = (item: RecipeBase) => {
    const { id, name, recipeType, coverImage, video } = item;

    return (
      <Grid item xs={6} md={4} key={item.id}>
        <RecipeCard
          id={id}
          title={name}
          type={recipeType}
          video={video}
          coverImage={coverImage}
        />
      </Grid>
    );
  };

  return (
    <Box>
      <Header
        title="Recipes"
        route="/learning/recipes"
        showSeeAll={showSeeAll}
        i18nKey="learning:recipe.title"
      />
      {!showEmptyState ? (
        <Grid container rowSpacing={2} columnSpacing={3}>
          {loading ? (
            <LoadingState type="recipe" amount={3} height="202px" />
          ) : (
            recipes.map((item) => renderItem(item))
          )}
        </Grid>
      ) : (
        <EmptyState type="recipe" />
      )}
    </Box>
  );
};

export default RecipeSection;
