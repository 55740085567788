import { Icon, SkeletonWrapper, Typography } from '@fdha/web-ui-library';
import { Paper, Stack, useTheme } from '@mui/material';
import { getBHBLevel, BHBLevel as BHBLevelEnum } from '@utils';
import React, { FC, useMemo } from 'react';

interface BHBLevelProps {
  value: number;
  limits: { bottom: number; upper: number };
  showSkeleton: boolean;
}

const BHBLevel: FC<BHBLevelProps> = ({ value, limits, showSkeleton }) => {
  const theme = useTheme();

  const { color, iconColor, text, icon } = useMemo(() => {
    const bhbLevel = getBHBLevel(value, limits);

    switch (bhbLevel) {
      case BHBLevelEnum.ABOVE:
        return {
          icon: 'trending-up',
          color: '#FCCDDE',
          iconColor: '#B0271D',
          text: (
            <Typography i18nKey="dataVisualization:bhb.levelStatus.above">
              {'Your BHB level is <strong>above</strong> the suggested range.'}
            </Typography>
          ),
        };
      case BHBLevelEnum.BELOW:
        return {
          icon: 'trending-down',
          color: '#FCCDDE',
          iconColor: '#B0271D',
          text: (
            <Typography i18nKey="dataVisualization:bhb.levelStatus.below">
              {'Your BHB level is <strong>below</strong> the suggested range.'}
            </Typography>
          ),
        };
      default:
        return {
          icon: 'radio-button-on-outline',
          color: theme.palette.primary.background,
          iconColor: theme.palette.primary.main,
          text: (
            <Typography i18nKey="dataVisualization:bhb.levelStatus.within">
              {'Your BHB level is <strong>within</strong> range.'}
            </Typography>
          ),
        };
    }
  }, [
    value,
    limits,
    theme.palette.primary.background,
    theme.palette.primary.main,
  ]);

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      <Paper
        sx={{
          backgroundColor: color,
          px: 2.5,
          py: 1.5,
          borderColor: theme.palette.action.disabled,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon name={icon} fill={iconColor} size="xlarge" />
          {text}
        </Stack>
      </Paper>
    </SkeletonWrapper>
  );
};

export default BHBLevel;
