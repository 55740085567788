import React from 'react';
import { CardContent, Skeleton } from '@mui/material';
import { HomeCard } from '@components';

const LoadingAminoAcidItem = () => {
  return (
    <HomeCard onClick={() => {}}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: 0,
        }}
      >
        <Skeleton variant="rectangular" height={72} width={300} />
        <Skeleton
          variant="rectangular"
          height={6}
          width={300}
          sx={{ mt: 1.5, mb: 2 }}
        />
        <Skeleton variant="rectangular" height={30} width={80} />
        <Skeleton
          variant="rectangular"
          height={50}
          width={300}
          sx={{ mt: 1 }}
        />
      </CardContent>
    </HomeCard>
  );
};

export default LoadingAminoAcidItem;
