import { Typography } from '@fdha/web-ui-library';
import { ScaleQuestionProps } from '@models';
import { Box, Card, CardActionArea, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface ScaleViewProps {
  question: ScaleQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

export const ScaleView: FC<ScaleViewProps> = ({ question, handleChange }) => {
  const theme = useTheme();
  const { values } = useFormikContext<any>();

  const key = question.id;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      flexWrap="wrap"
    >
      {question.options.map((option) => {
        const isSelected = option.value === values[key];

        return (
          <Card
            key={option.value}
            sx={{
              width: 76,
              height: 66,
              backgroundColor: isSelected
                ? theme.palette.primary.main
                : theme.palette.background.default,
              display: 'flex',
              mr: 2,
              mb: 2,
            }}
          >
            <CardActionArea
              onClick={() => handleChange(option.value)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                color={
                  isSelected
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary
                }
              >
                {option.label}
              </Typography>
            </CardActionArea>
          </Card>
        );
      })}
    </Box>
  );
};
