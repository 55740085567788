import { SelectV2, states, TextFieldV2 } from '@fdha/web-ui-library';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface AddressViewProps {
  handleChange: (value: any, name?: string) => void;
}

interface AddressValue {
  address: string;
  complement: string;
  city: string;
  state: string;
  zip: string;
}

interface Context {
  [key: string]: AddressValue;
}

export const AddressView: FC<AddressViewProps> = ({ handleChange }) => {
  const { values, errors, touched, handleBlur } = useFormikContext<Context>();

  const { address, complement, city, zip, state } = values.address;

  const error = {
    address: touched.address?.address ? errors.address?.address : '',
    city: touched.address?.city ? errors.address?.city : '',
    state: touched.address?.state ? errors.address?.state : '',
    zip: touched.address?.zip ? errors.address?.zip : '',
  };

  const handleAddressChange = (field: string, value: string) => {
    handleChange({ ...values.address, [field]: value });
  };

  return (
    <Stack spacing={2}>
      <TextFieldV2
        name="address"
        title="Address"
        value={address}
        onChange={(event) => handleAddressChange('address', event.target.value)}
        onBlur={handleBlur}
        error={!!error.address}
        helperText={error.address ?? 'Street address'}
        i18nKeyTitle="profile:yourAccount.input.address.label"
        i18nKeyHelper={
          error.address ? undefined : 'profile:yourAccount.input.address.helper'
        }
      />
      <TextFieldV2
        name="complement"
        title="Address line #2 if applicable"
        helperText="Appt, Suite, Unit, Building, Floor, etc"
        value={complement}
        onChange={(event) =>
          handleAddressChange('complement', event.target.value)
        }
        onBlur={handleBlur}
        i18nKeyTitle="profile:yourAccount.input.addressLine2.label"
        i18nKeyHelper="profile:yourAccount.input.address.helper"
      />
      <Stack spacing={2} direction="row">
        <TextFieldV2
          name="city"
          title="City"
          value={city}
          onChange={(event) => handleAddressChange('city', event.target.value)}
          onBlur={handleBlur}
          error={!!error.city}
          helperText={error.city}
          i18nKeyTitle="profile:yourAccount.input.city.label"
        />
        <SelectV2
          fullWidth
          name="state"
          title="State"
          value={state}
          options={states}
          onChange={(event) =>
            handleAddressChange(
              'state',
              event.target.value as unknown as string
            )
          }
          onBlur={handleBlur}
          error={!!error.state}
          helperText={error.state}
          i18nKeyTitle="profile:yourAccount.input.state.label"
        />
        <TextFieldV2
          name="zip"
          title="ZIP/Postal Code"
          value={zip}
          onChange={(event) => handleAddressChange('zip', event.target.value)}
          onBlur={handleBlur}
          error={!!error.zip}
          helperText={error.zip}
          i18nKeyTitle="profile:yourAccount.input.zipCode.label"
        />
      </Stack>
    </Stack>
  );
};
