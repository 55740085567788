import { Box } from '@mui/material';
import React from 'react';

const SplashLoader = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src="/images/faeth_logo_large.png" alt="Faeth logo" />
    </Box>
  );
};

export default SplashLoader;
