import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import {
  useAnalytics,
  useEffectOnce,
  useFeatureFlag,
} from '@fdha/common-hooks';
import { LoadMore } from '@fdha/web-ui-library';
import { useNotifications } from '@hooks';
import {
  LearningModuleOverview,
  SortByOrder,
  useGetLearningModulesQuery,
} from '@fdha/graphql-api-patient';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import CourseCard from '../CourseCard';
import EmptyState from '../EmptyState';
import LoadingState from '../LoadingState';

const Courses = () => {
  const { isFeatureEnabled } = useFeatureFlag();
  const showCourses = isFeatureEnabled('show_learning_content');

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { analyticsClient } = useAnalytics();
  const { clearNotification } = useNotifications();
  useEffectOnce(() => {
    clearNotification('learning');
  });

  const {
    data: coursesData,
    fetchMore,
    networkStatus,
  } = useGetLearningModulesQuery({
    variables: {
      sortBy: { sortBy: [{ field: 'assigned_on', order: SortByOrder.Desc }] },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const courses =
    coursesData?.learningModules.edges.map((edge) => edge.node) || [];
  const pageInfo = coursesData?.learningModules.pageInfo;
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const showEmptyStateCourses = (!courses.length || !showCourses) && !isLoading;

  const loadMore = () => {
    if (!pageInfo?.hasNextPage || isLoadingMore) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    });
  };

  const navigateCourse = (item: LearningModuleOverview) => {
    const courseRoute = `${pathname}/${item.id}`;
    navigate(courseRoute);
    analyticsClient?.track('Course Opened', {
      id: item.id,
      name: item.title,
    });
  };

  const renderCourseItem = (item: LearningModuleOverview) => {
    return (
      <Grid item xs={12} md={6} key={item.id}>
        <CourseCard
          coverImg={item.image}
          title={item.title}
          progress={item.status ?? 0}
          onClick={() => navigateCourse(item)}
        />
      </Grid>
    );
  };

  return (
    <DefaultBasePage
      title="Courses"
      contentSize="large"
      i18nKeyTitle="learning:course.title"
    >
      {!showEmptyStateCourses ? (
        <Grid container rowSpacing={2} columnSpacing={2}>
          {isLoading ? (
            <LoadingState
              type="course"
              height="230px"
              amount={5}
              gridProps={{ xs: 12, md: 6 }}
            />
          ) : (
            <>
              {courses.map((item) => renderCourseItem(item))}
              <LoadMore
                onLoadMore={loadMore}
                listLength={courses.length}
                isLoadingMore={isLoadingMore}
              />
            </>
          )}
        </Grid>
      ) : (
        <EmptyState type="course" />
      )}
    </DefaultBasePage>
  );
};

export default Courses;
