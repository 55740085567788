import { useCallback, useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const DEFAULT_MESSAGE =
  'Leaving this page without saving will discard any unsaved changes.';

const useConfirmExit = (confirmExit: () => boolean, when: boolean = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (
  when: boolean = true,
  message: string = DEFAULT_MESSAGE
) => {
  const handlePopStateRef = useRef<(() => void) | null>(null);

  const handlePopState = useCallback((message: string) => {
    const confirm = window.confirm(message);

    if (confirm && handlePopStateRef.current) {
      window.history.back();
      window.removeEventListener('popstate', handlePopStateRef.current);
    }

    if (!confirm && handlePopStateRef.current) {
      window.removeEventListener('popstate', handlePopStateRef.current);
      handlePopStateRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (when) {
      if (!handlePopStateRef.current) {
        handlePopStateRef.current = () => handlePopState(message);
        window.history.pushState({}, document.title, window.location.href);
        window.addEventListener('popstate', handlePopStateRef.current);
      }

      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when, handlePopState]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  useConfirmExit(confirmExit, when);
};
