import { SelectV2, TextFieldV2 } from '@fdha/web-ui-library';
import { TextSelectQuestionProps } from '@models';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface TextSelectViewProps {
  question: TextSelectQuestionProps;
  handleChange: (value: any, name?: string) => void;
}

export const TextSelectView: FC<TextSelectViewProps> = ({
  question,
  handleChange,
}) => {
  const { values, errors, touched } = useFormikContext<any>();

  const key = question.id;
  const error = errors[key] as {
    text: string;
    select: string;
  };

  const textSelectTouched = touched[key] as { text: boolean; select: boolean };
  const textError = textSelectTouched?.text ? error?.text : '';
  const selectError = textSelectTouched?.select ? error?.select : '';

  return (
    <Stack direction="row" spacing={2}>
      <TextFieldV2
        fullWidth
        title={question.text.label}
        i18nKeyTitle={question.text.i18nKeyLabel}
        value={values[key]?.text}
        onChange={(event) =>
          handleChange({ ...values[key], text: event.target.value })
        }
        error={!!textError}
        helperText={textError}
      />
      <SelectV2
        fullWidth
        title={question.select.label}
        i18nKeyTitle={question.select.i18nKeyLabel}
        options={question.select.options}
        value={values[key]?.select}
        onChange={(event) =>
          handleChange({ ...values[key], select: event.target.value })
        }
        error={!!selectError}
        helperText={selectError}
      />
    </Stack>
  );
};
