import React, { useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { DefaultBasePage } from '@components';
import {
  Button,
  InfiniteScrollList,
  SelectV2,
  Typography,
} from '@fdha/web-ui-library';
import {
  DocumentInfo,
  DocumentOwnerFilter,
  useListDocumentsQuery,
} from '@fdha/graphql-api-patient';
import { Box, Skeleton, Stack } from '@mui/material';
import { getSortOrder, selectItems } from '@utils';
import { useDocuments, useUploadDocuments } from '@hooks';

import DocumentCard from './DocumentCard';
import EmptyCard from './EmptyCard';

const YourUploads = () => {
  const { handleDownloadDocument, handleDeleteDocument } = useDocuments();
  const [sortBy, setSortBy] = useState(selectItems[0].value);
  const {
    data: documentsData,
    networkStatus,
    fetchMore,
    refetch,
    loading,
  } = useListDocumentsQuery({
    variables: {
      sortBy: {
        sortBy: [
          {
            field: sortBy,
            order: getSortOrder(sortBy),
          },
        ],
      },
      ownerFilter: DocumentOwnerFilter.Patient,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });
  const { uploadDocument } = useUploadDocuments(refetch);

  const documentsList =
    documentsData?.documents?.edges.map((edge) => edge.node) || [];
  const pageInfo = documentsData?.documents.pageInfo;

  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const loadMore = () => {
    if (!pageInfo?.hasNextPage || networkStatus === NetworkStatus.fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    });
  };

  const onDelete = async (id: string) => {
    await handleDeleteDocument(id, refetch);
  };

  const renderItem = (item: DocumentInfo) => (
    <DocumentCard
      document={item}
      handleDownload={handleDownloadDocument}
      handleDelete={onDelete}
    />
  );

  const renderEmptyState = () => <EmptyCard />;

  const renderLoading = () => (
    <Stack spacing={2}>
      {[...Array(6)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={79} />
      ))}
    </Stack>
  );

  return (
    <DefaultBasePage
      i18nKeyTitle="profile:documents.folders.yourUploads.title"
      title="Your Uploads"
      contentSize="small"
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        columnGap={2}
        mt={1}
        mb={2}
      >
        <SelectV2
          options={selectItems}
          useFirstOptionAsDefault
          variant="standard"
          onChange={(event) => {
            const value = event.target.value as string;
            setSortBy(value);
          }}
        />
        <Button
          variant="contained"
          startEvaIcon={{
            name: 'plus-outline',
          }}
          size="large"
          component="label"
        >
          <Typography
            i18nKey="profile:documents.uploadButton"
            variant="inherit"
          >
            Upload File
          </Typography>
          <input type="file" hidden onChange={uploadDocument} />
        </Button>
      </Box>
      {loading ? (
        renderLoading()
      ) : (
        <InfiniteScrollList
          items={documentsList}
          renderItem={renderItem}
          loadMore={loadMore}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          renderEmptyState={renderEmptyState}
        />
      )}
    </DefaultBasePage>
  );
};

export default YourUploads;
