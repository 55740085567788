import { IconMenu } from '@fdha/web-ui-library';
import { ChatScreenType, setSelectedChatScreenType } from '@states';
import React from 'react';

const HeaderMenu = () => {
  const options = [
    {
      label: 'Chat Media',
      i18nKeyLabel: 'chat:chatMedia.title',
      handleClick: () => setSelectedChatScreenType(ChatScreenType.Media),
      testId: 'CHAT_MEDIA_BUTTON',
    },
  ];

  return <IconMenu items={options} />;
};

export default HeaderMenu;
