import {
  BasePageV2,
  BasePageV2Body,
  BasePageV2Header,
  BasePageV2Navigation,
  NavigationCloseAction,
  ContentSize,
  LinearProgressProps,
} from '@fdha/web-ui-library';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { privatePageNameByRoute, publicPageNameByRoute } from '@routes';
import { onError } from '@apollo/client/link/error';
import { useApolloClient } from '@apollo/client';

export interface Progress {
  show: boolean;
  progressBarProps: LinearProgressProps;
}

interface Props {
  title?: string;
  i18nKeyTitle?: string;
  i18nTitleParams?: { [key: string]: string };
  i18nKeySubtitle?: string;
  i18nSubtitleParams?: { [key: string]: string };
  centeredTitle?: boolean;
  subtitle?: string;
  showBack?: boolean;
  showClose?: boolean;
  showNavigation?: boolean;
  coverImageUrl?: string;
  contentSize?: ContentSize;
  currentRouteName?: { text?: string; i18nKey?: string };
  progress?: Progress;
  isLoading?: boolean;
  rightButton?: JSX.Element;
  type?: 'default' | 'cover' | 'notFound';
  handleActionBeforeClose?: () => Promise<NavigationCloseAction>;
}

const DefaultBasePage: React.FC<PropsWithChildren<Props>> = ({
  title,
  i18nKeyTitle,
  i18nTitleParams,
  i18nKeySubtitle,
  i18nSubtitleParams,
  centeredTitle = false,
  subtitle,
  showBack = true,
  showClose = false,
  showNavigation = true,
  coverImageUrl,
  contentSize = 'xlarge',
  currentRouteName,
  isLoading,
  progress,
  rightButton,
  handleActionBeforeClose,
  children,
  type,
}) => {
  const authStatus = useAuthStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const pageNamesByRoute = useMemo(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      return privatePageNameByRoute;
    }
    return publicPageNameByRoute;
  }, [authStatus]);

  const errorControl = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (!extensions.extensions) {
          return;
        }
        const codeExtensions = extensions.extensions as { code?: string };

        if (codeExtensions.code === 'NOT_FOUND') {
          setIsNotFound(true);
        }
      });
    }
  });

  client.setLink(errorControl.concat(client.link));

  const notFoundRedirect = () => navigate('/');

  const handleBack = () => {
    navigate('../');
  };

  const shouldShowHeader = showBack || subtitle || title || rightButton;
  const shouldShowNavigation = showNavigation || showClose || progress?.show;
  const typeByProps = isNotFound
    ? 'notFound'
    : coverImageUrl
    ? 'cover'
    : 'default';

  return (
    <BasePageV2
      type={type || typeByProps}
      contentSize={contentSize}
      coverImageUrl={coverImageUrl}
      handleNotFoundRedirect={notFoundRedirect}
    >
      {shouldShowNavigation && (
        <BasePageV2Navigation
          type={showNavigation ? 'breadcrumb' : 'close'}
          breadcrumbProps={{
            currentRouteName,
            pageNamesByRoute,
            pathname: location.pathname,
            onClick: (route) => navigate(route),
          }}
          closeProps={{
            onBeforeClose: handleActionBeforeClose,
            onClose: handleBack,
          }}
          progress={progress?.show ? progress?.progressBarProps : undefined}
          isLoading={isLoading}
        />
      )}
      {shouldShowHeader && (
        <BasePageV2Header
          title={title}
          i18nKeyTitle={i18nKeyTitle}
          i18nTitleParams={i18nTitleParams}
          i18nKeySubtitle={i18nKeySubtitle}
          i18nSubtitleParams={i18nSubtitleParams}
          subtitle={subtitle}
          showBack={showBack}
          onClickBack={handleBack}
          centeredHeader={centeredTitle}
          rightButton={rightButton}
          isLoading={isLoading}
        />
      )}
      <BasePageV2Body>{children}</BasePageV2Body>
    </BasePageV2>
  );
};

export default DefaultBasePage;
