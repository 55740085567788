import {
  EmptyStateCard,
  Icon,
  Loader,
  SelectedMenuSection,
  ShipmentStatusCard,
  theme,
  Typography,
  useDialog,
} from '@fdha/web-ui-library';
import {
  DeliveryStatus,
  useGetDeliveryV2Query,
  useGetSelectedMenuOptionsV2Query,
  useGetMenuOptionSelectionsQuery,
  TrackingStatus,
} from '@fdha/graphql-api-patient';
import { Box, Divider, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSectionTypeLabel,
  groupDishes,
  isDeliveryDelayed,
} from '@fdha/common-utils';
import { useFeatureFlag } from '@fdha/common-hooks';

import DishDetails from '../dishDetails/DishDetails';

const styles = {
  cancelDeliveryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingX: 2,
    paddingY: 3,
    justifyContent: 'space-between',
  },
};

const ViewDelivery = () => {
  const { openDialogV2 } = useDialog();
  const params = useParams();
  const deliveryId = params.id || '';
  const { data: deliveryData, loading: loadingDelivery } =
    useGetDeliveryV2Query({
      variables: { deliveryId },
      fetchPolicy: 'cache-and-network',
    });

  const deliveryStatus = deliveryData?.deliveryV2?.status;
  const isCanceled = deliveryData?.deliveryV2?.isCanceled;
  const isDeliveryClosed = deliveryStatus === DeliveryStatus.Closed;
  const deliveryDate = deliveryData?.deliveryV2?.deliveryDate || '';

  const dietPlanDeliveryMenuId =
    deliveryData?.deliveryV2?.dietPlanDeliveryMenu.id ?? '';

  const {
    data: menuOptionsClosedDelivery,
    loading: loadingSelectedMenuOptions,
  } = useGetSelectedMenuOptionsV2Query({
    variables: {
      deliveryId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !deliveryId && !isDeliveryClosed,
  });

  const {
    data: menuOptionsOpenDelivery,
    loading: loadingMenuOptionSelections,
  } = useGetMenuOptionSelectionsQuery({
    variables: { dietPlanDeliveryMenuId },
    fetchPolicy: 'cache-and-network',
    skip: !dietPlanDeliveryMenuId && isDeliveryClosed,
  });

  const menuOptions = useMemo(() => {
    if (isDeliveryClosed) {
      return menuOptionsClosedDelivery?.selectedMenuOptionsV2;
    }

    return menuOptionsOpenDelivery?.menuOptionSelectionV2;
  }, [menuOptionsClosedDelivery, menuOptionsOpenDelivery, isDeliveryClosed]);

  const { isFeatureEnabled, isLoading: featureFlagLoading } = useFeatureFlag();

  const hasSides = !!menuOptions?.sides?.length;
  const shipmentOrder = deliveryData?.deliveryV2?.order;
  const shipmentLabels = shipmentOrder?.shipmentLabels || [];

  const selectedMenuOptions = useMemo(() => {
    const dishes = groupDishes(menuOptions);

    return {
      entreeDishes:
        dishes?.entreeDishes.filter((dish) => dish.quantity > 0) || [],
      snacks: dishes?.snacks.filter((dish) => dish.quantity > 0) || [],
    };
  }, [menuOptions]);

  const hasNoSelections = useMemo(() => {
    if (!selectedMenuOptions) {
      return true;
    }

    return (
      !selectedMenuOptions.entreeDishes.length &&
      !selectedMenuOptions.snacks.length
    );
  }, [selectedMenuOptions]);

  const isLoading = useMemo(
    () =>
      loadingSelectedMenuOptions ||
      loadingDelivery ||
      featureFlagLoading ||
      loadingMenuOptionSelections,
    [
      loadingSelectedMenuOptions,
      loadingDelivery,
      featureFlagLoading,
      loadingMenuOptionSelections,
    ]
  );

  const showShipmentStatus =
    isFeatureEnabled('show_shipment_details') && !!shipmentOrder;

  const onClickDish = (dishId: string, dishName: string) =>
    openDialogV2({
      title: dishName,
      content: <DishDetails dishId={dishId} />,
      showCloseButton: true,
      hidePadding: true,
      hideTopPadding: true,
      maxWidth: 'md',
    });

  const renderShipmentStatuses = () => {
    if (!shipmentLabels.length) {
      return (
        <ShipmentStatusCard
          status={TrackingStatus.BeingPrepared}
          isOwnDelivery
        />
      );
    }

    return (
      <Box display="flex" flexDirection="column" rowGap={2}>
        {shipmentLabels.map((shipment) => {
          const { id, trackingNumber, trackingUrl, trackingStatus } = shipment;
          return (
            <ShipmentStatusCard
              key={id}
              trackingId={trackingNumber ?? undefined}
              status={trackingStatus}
              isOwnDelivery
              onTrackDelivery={
                trackingUrl
                  ? () => window.open(trackingUrl, '_blank')
                  : undefined
              }
              isDelayed={isDeliveryDelayed(new Date(deliveryDate), [shipment])}
            />
          );
        })}
      </Box>
    );
  };

  const renderCancelMessage = () => {
    return (
      <Paper
        sx={styles.cancelDeliveryContainer}
        variant="elevation"
        elevation={0}
      >
        <Typography
          variant="body1"
          style={{ color: theme.palette.text.primary }}
          i18nKey="temporary:meals.deliveryStatus.cancelled"
        >
          Your delivery has been canceled.
        </Typography>
        <Icon
          name="close-circle-outline"
          size="xlarge"
          backgroundColor={grey[200]}
          fill={theme.palette.text.primary}
        />
      </Paper>
    );
  };

  const renderEmptyState = () => {
    if (isCanceled) {
      return (
        <EmptyStateCard
          message="No dishes selected for this delivery."
          i18nKeyMessage="temporary:meals.deliveryPreview.canceledEmptyState"
        />
      );
    }

    return (
      <EmptyStateCard
        message="You’ll receive the default meals set by Food Ops team. It’s possible to
    set a custom menu by editing an open delivery."
        i18nKeyMessage="meals:deliveryPreview.emptyState"
      />
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      {showShipmentStatus && renderShipmentStatuses()}
      {isCanceled && renderCancelMessage()}
      {hasNoSelections ? (
        renderEmptyState()
      ) : (
        <Paper sx={{ padding: 2 }} variant="elevation" elevation={0}>
          <SelectedMenuSection
            mealType={getSectionTypeLabel('entreeDishes', hasSides)}
            options={selectedMenuOptions.entreeDishes}
            onClick={onClickDish}
            hideMealType
          />
          {selectedMenuOptions.snacks.length ? (
            <>
              <Divider />
              <SelectedMenuSection
                mealType={getSectionTypeLabel('snacks', hasSides)}
                options={selectedMenuOptions.snacks}
                onClick={onClickDish}
                hideMealType
              />
            </>
          ) : null}
        </Paper>
      )}
    </Box>
  );
};

export default ViewDelivery;
