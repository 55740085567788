import { useReactiveVar } from '@apollo/client';
import { ActivityDetailsCardV2 } from '@fdha/web-ui-library';
import { Appointment } from '@fdha/graphql-api-patient';
import { Box, Grid, Typography } from '@mui/material';
import { selectedActivity } from '@states';
import React, { FC } from 'react';

interface AppointmentDetailsProps {
  isCreatedByCoach: boolean;
}

export const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  isCreatedByCoach,
}) => {
  const appt = useReactiveVar(selectedActivity) as Appointment;

  return (
    <>
      <Typography variant="h5" sx={{ overflowWrap: 'anywhere' }}>
        {appt.title}
      </Typography>
      <Box mb={2}>
        <ActivityDetailsCardV2.CreatedBy isCreatedByCoach={isCreatedByCoach} />
      </Box>
      <ActivityDetailsCardV2.Details details={appt.description} />
      <Grid container>
        <ActivityDetailsCardV2.Date date={appt.time} />
        <ActivityDetailsCardV2.Duration duration={appt.duration} />
        <ActivityDetailsCardV2.EndDate date={appt.ends_at} />
        <ActivityDetailsCardV2.Frequency
          frequency={appt.frequency}
          weekDays={appt.weekDays}
        />
        <ActivityDetailsCardV2.Time time={appt.time} />
        <ActivityDetailsCardV2.Location location={appt.location} />
        <ActivityDetailsCardV2.Reminder reminder={appt.reminder} />
      </Grid>
    </>
  );
};
