import { Box, IconButton } from '@mui/material';
import React, { useMemo } from 'react';
import { useChatContext, useChannelStateContext } from 'stream-chat-react';
import { grey } from '@mui/material/colors';
import { Avatar, theme, Icon, OverflowText } from '@fdha/web-ui-library';
import { displayProtectedImage } from '@fdha/web-auth';
import { ChannelType } from '@utils';

import HeaderMenu from './HeaderMenu';

interface ChannelHeaderProps {
  handleBack?: () => void;
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ handleBack }) => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const members = Object.values(channel.state.members);
  const otherMembers = members.filter(({ user }) => user?.id !== client.userID);

  const messagingDisplayName = useMemo(() => {
    return otherMembers.map((member) => member?.user?.name).join(', ');
  }, [otherMembers]);

  const displayAvatar =
    channel.type === ChannelType.Messaging
      ? otherMembers[0]?.user?.image
      : null;
  const displayName =
    channel.type === ChannelType.Broadcast
      ? channel?.data?.name
      : messagingDisplayName;
  const displayPresence =
    channel.type === ChannelType.Messaging
      ? otherMembers[0]?.user?.online
        ? 'online'
        : 'offline'
      : undefined;
  const avatarIconName =
    channel.type === ChannelType.Broadcast
      ? displayName
      : otherMembers[0]?.user?.name;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      height="88px"
      borderBottom="solid 1px"
      borderColor={grey[300]}
      data-testid="CHANNEL_HEADER"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <>
          {handleBack && (
            <Box mx="28px" my={4}>
              <IconButton onClick={handleBack} sx={{ p: 0 }}>
                <Icon
                  name="arrow-back-outline"
                  size="large"
                  fill={theme.palette.primary.main}
                />
              </IconButton>
            </Box>
          )}
          <Box marginRight={2} minWidth={5}>
            <Avatar
              picture={displayAvatar}
              name={avatarIconName}
              presence={displayPresence}
              size={40}
              downloadPicture={displayProtectedImage}
            />
          </Box>
          <Box width={340}>
            <OverflowText
              label={displayName || ''}
              variant="h5"
              fontWeight="bold"
            >
              {displayName}
            </OverflowText>
          </Box>
        </>
      </Box>
      {channel.type === ChannelType.Messaging && (
        <Box marginRight={2} lineHeight="1em" display="flex">
          <HeaderMenu />
        </Box>
      )}
    </Box>
  );
};

export default ChannelHeader;
