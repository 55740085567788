import * as Yup from 'yup';
import {
  signUp,
  confirmSignUp,
  signIn,
  getCurrentUser,
} from '@aws-amplify/auth';
import { ImageBasePage } from '@components';
import { PasswordRuleUtils } from '@fdha/web-auth';
import {
  Button,
  Typography,
  addPhoneNumberValidation,
  convertMaskToE164,
  formatDate,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  NotificationTypeV2,
  useUpdateProfileMutation,
} from '@fdha/graphql-api-patient';
import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikErrors, FormikState, FormikTouched } from 'formik';
import React, { useState } from 'react';
import { useLocalStorage } from '@hooks';
import { useTranslatedErrorMessages } from '@fdha/common-hooks';

import BirthDate from './BirthDate';
import Code from './Code';
import FirstName from './FirstName';
import LastName from './LastName';
import LoginInfo from './LoginInfo';
import PhoneNumber from './PhoneNumber';
import UserType from './UserType';
import {
  createAccountInitialValues,
  CreateAccountResponse,
  createAccountScreens,
  CreateAccountScreenType,
  initialImage,
} from './createAccountScreens';

const CreateAccount = () => {
  const {
    requiredMessage,
    validDateMessage,
    validEmailMessage,
    matchPasswordMessage,
    validPhoneMessage,
    acceptTermsMessage,
  } = useTranslatedErrorMessages();
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();
  const { saveToLocalStorage } = useLocalStorage('me', false);

  const [image, setImage] = useState(initialImage);
  const [title, setTitle] = useState('');
  const [index, setIndex] = useState(0);
  const [rulesErrorList, setRulesErrorList] = useState<string[] | undefined>(
    undefined
  );

  const [updateProfile] = useUpdateProfileMutation();

  const createAccountValidationSchema = [
    Yup.object().shape({
      userType: Yup.string(),
    }),
    Yup.object().shape({
      email: Yup.string().email(validEmailMessage).required(requiredMessage),
      password: Yup.string().required(requiredMessage),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], matchPasswordMessage)
        .required(requiredMessage),
      rememberMe: Yup.bool().oneOf([true, false]),
      acceptedSms: Yup.bool().oneOf([true, false]),
      acceptedTos: Yup.bool().oneOf([true], acceptTermsMessage),
    }),
    Yup.object().shape({
      firstName: Yup.string().required(requiredMessage),
    }),
    Yup.object().shape({
      lastName: Yup.string().required(requiredMessage),
    }),
    Yup.object().shape({
      birthDate: Yup.date()
        .nullable(true)
        .typeError(validDateMessage)
        .required(requiredMessage),
    }),
    Yup.object().shape({
      phoneNumber:
        addPhoneNumberValidation(validPhoneMessage).required(requiredMessage),
      confirmPhoneNumber: addPhoneNumberValidation(validPhoneMessage)
        .oneOf([Yup.ref('phoneNumber'), null], 'Phone numbers should match.')
        .required(requiredMessage),
    }),
    Yup.object().shape({
      code: Yup.string().required(requiredMessage),
    }),
  ];

  const screen = createAccountScreens[index];
  const isLastScreen = index === createAccountScreens.length - 1;
  const shouldSignUp = screen?.id === CreateAccountScreenType.PhoneNumber;

  const updatePatientProfile = async (values: CreateAccountResponse) => {
    const defaultNotificationPreference = values.acceptedSms
      ? NotificationTypeV2.Sms
      : NotificationTypeV2.Push;

    await updateProfile({
      variables: {
        props: {
          type: values.userType,
          default_notification_preference: defaultNotificationPreference,
        },
      },
    });
  };

  const handleSignUp = async (values: CreateAccountResponse) => {
    const userAttributes = {
      given_name: values.firstName.trim(),
      family_name: values.lastName.trim(),
      name: `${values.firstName.trim()} ${values.lastName.trim()}`,
      email: values.email.trim(),
      birthdate: formatDate(values.birthDate),
      phone_number: convertMaskToE164(values.phoneNumber),
    };

    await signUp({
      username: values.email,
      password: values.password,
      options: {
        userAttributes,
      },
    });
  };

  const handleConfirmSignUp = async (values: CreateAccountResponse) => {
    try {
      await confirmSignUp({
        username: values.email,
        confirmationCode: values.code,
      });
      await signIn({
        username: values.email,
        password: values.password,
      });

      const { username } = await getCurrentUser();

      navigate('/mission');

      await updatePatientProfile(values);

      saveToLocalStorage(
        {
          rememberMe: values.rememberMe,
          isAuthenticated: true,
          acceptedSms: values.acceptedSms,
          lastLogin: new Date(),
        },
        false,
        `pat_${username}`
      );
    } catch (error: any) {
      showSnackbarV2({
        message:
          error.code === 'CodeMismatchException'
            ? 'Invalid verification code, please try again.'
            : error.message !== 'undefined'
            ? error.message
            : 'Error to create account',
        severity: 'error',
      });
    }
  };

  const handleBack = (
    values: CreateAccountResponse,
    resetForm: {
      (
        nextState?: Partial<FormikState<CreateAccountResponse>> | undefined
      ): void;
    }
  ) => {
    if (index === 0) {
      navigate(-1);
    } else {
      if (
        createAccountScreens[index - 1].id === CreateAccountScreenType.LoginInfo
      ) {
        resetForm({ values: { ...values, password: '', confirmPassword: '' } });
      }

      setIndex(index - 1);
    }
  };

  const handleNext = async (
    values: CreateAccountResponse,
    setSubmitting: (isSubmitting: boolean) => void,
    setTouched: (touched: FormikTouched<CreateAccountResponse>) => void
  ) => {
    try {
      if (rulesErrorList?.length) {
        setSubmitting(false);
        return;
      }

      if (shouldSignUp) {
        await handleSignUp(values);
      }

      if (isLastScreen) {
        await handleConfirmSignUp(values);
      } else {
        setIndex(index + 1);
      }

      setSubmitting(false);
      setTouched({});
    } catch (error) {
      showSnackbarV2({
        message: (error as { message: string }).message,
        severity: 'error',
      });
      setSubmitting(false);
    }
  };

  const handleErrors = (errors: FormikErrors<CreateAccountResponse>) => {
    if (
      screen?.id === CreateAccountScreenType.LoginInfo &&
      errors.acceptedTos
    ) {
      showSnackbarV2({
        i18nKey: 'login:createAccount.step2.terms.snackbar.error',
        message:
          'You need to accept the terms and privacy policy to create your account.',
        severity: 'error',
      });
    }
  };

  const renderScreen = () => {
    switch (screen?.id) {
      case CreateAccountScreenType.UserType:
        return (
          <UserType
            index={index}
            setImage={setImage}
            setIndex={setIndex}
            setTitle={setTitle}
          />
        );
      case CreateAccountScreenType.LoginInfo:
        return <LoginInfo rulesErrorList={rulesErrorList} />;
      case CreateAccountScreenType.FirstName:
        return <FirstName />;
      case CreateAccountScreenType.LastName:
        return <LastName />;
      case CreateAccountScreenType.BirthDate:
        return <BirthDate />;
      case CreateAccountScreenType.PhoneNumber:
        return <PhoneNumber />;
      case CreateAccountScreenType.Code:
        return <Code />;
    }
  };

  const validate = (values: CreateAccountResponse) => {
    const errors: Partial<CreateAccountResponse> = {};

    if (values.password) {
      setRulesErrorList(
        PasswordRuleUtils.getPasswordRulesListErrorV2(values.password.trim())
      );
    }

    return errors;
  };

  const renderSubtitle = () => (
    <Typography
      i18nKey="login:createAccount.subtitle"
      i18nParams={{
        current: (index + 1).toString(),
        total: createAccountScreens.length.toString(),
      }}
      variant="subtitle2"
      color={theme.palette.text.hint}
    >{`Step ${index + 1} of ${createAccountScreens.length}`}</Typography>
  );

  return (
    <ImageBasePage
      image={screen?.image || image}
      title={screen?.title || title}
      titleVariant="h4"
      alignHeader="flex-start"
      subtitle={renderSubtitle()}
      showLogo={screen?.showLogo}
      contentSize={screen?.contentSize}
    >
      <Formik
        initialValues={createAccountInitialValues}
        validate={validate}
        validationSchema={createAccountValidationSchema[index]}
        onSubmit={(values, { setSubmitting, setTouched }) => {
          handleNext(values, setSubmitting, setTouched);
        }}
      >
        {({ handleSubmit, resetForm, errors, values, isSubmitting }) => {
          return (
            <Form>
              {renderScreen()}
              <Box
                display="flex"
                justifyContent={isLastScreen ? 'flex-end' : 'space-between'}
                mt={2}
              >
                {!isLastScreen && (
                  <Button
                    sx={{ mr: 4 }}
                    onClick={() => handleBack(values, resetForm)}
                    i18nKey="common:button.back"
                  >
                    Back
                  </Button>
                )}
                {!screen?.hideNextButton && (
                  <Button
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ width: '137px' }}
                    onClick={() => {
                      handleErrors(errors);
                      handleSubmit();
                    }}
                    i18nKey={
                      isLastScreen ? 'common:button.done' : 'common:button.next'
                    }
                  >
                    {isLastScreen ? 'Done' : 'Next'}
                  </Button>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </ImageBasePage>
  );
};

export default CreateAccount;
