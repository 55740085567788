import { CircularProgressV2, OverflowText } from '@fdha/web-ui-library';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import React from 'react';

interface CourseCardProps {
  coverImg: string;
  title: string;
  progress: number;
  onClick: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({
  coverImg,
  title,
  progress,
  onClick,
}) => {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          height="166"
          image={coverImg}
          alt="Course image"
        />
        <CardContent sx={{ height: '72px' }}>
          <Box display="flex" alignItems="center">
            <CircularProgressV2 color="success" value={progress} />
            <OverflowText label={title} variant="h6" ml={2}>
              {title}
            </OverflowText>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CourseCard;
