import React, { useMemo } from 'react';
import { MenuOptionSelection } from '@fdha/graphql-api-patient';
import {
  formatUTCDate,
  Link,
  Loader,
  TagFilter,
  Typography,
} from '@fdha/web-ui-library';
import {
  MealType,
  PatientMealSelectionInputV2,
  PatientOptionSelectionV2,
  useGetDeliveryV2Query,
  useGetMenuOptionSelectionsQuery,
  useUpdatePatientMealSelectionV2Mutation,
} from '@fdha/graphql-api-patient';
import { Paper, Box, useTheme } from '@mui/material';
import {
  MenuOptions,
  MenuSectionV2,
  pluralize,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { useNavigate } from 'react-router';
import {
  useAnalytics,
  useFeatureFlag,
  useMenuOptionFilter,
} from '@fdha/common-hooks';
import {
  calculateAmountToSelect,
  getAllMenuOptionSelections,
  groupDishes,
  isFull,
} from '@fdha/common-utils';
import { FormikErrors, useFormik } from 'formik';
import { isEmpty, cloneDeep } from 'lodash';

import DishDetails from '../dishDetails/DishDetails';

import { FooterButtons } from './FooterButtons';

export const DIALOG_CONTENT_HEIGHT = 'calc(100vh - 350px)';

export interface EditDeliveryProps {
  deliveryId: string;
}

const EditDelivery: React.FC<EditDeliveryProps> = ({ deliveryId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { openDialogV2 } = useDialog();
  const { showSnackbarV2 } = useSnackbar();

  const { data: deliveryData, loading: deliveryLoading } =
    useGetDeliveryV2Query({
      variables: {
        deliveryId,
      },
      skip: !deliveryId,
      fetchPolicy: 'cache-and-network',
    });

  const [updatePatientMealSelectionV2] =
    useUpdatePatientMealSelectionV2Mutation();

  const dietPlanDeliveryMenuId =
    deliveryData?.deliveryV2?.dietPlanDeliveryMenu.id ?? '';

  const { data, loading } = useGetMenuOptionSelectionsQuery({
    variables: { dietPlanDeliveryMenuId },
    skip: !dietPlanDeliveryMenuId,
    fetchPolicy: 'cache-and-network',
  });
  const { analyticsClient } = useAnalytics();

  const hasSides = !!data?.menuOptionSelectionV2?.sides.length;

  const initialValues = useMemo(() => {
    const menuOptionSelection = cloneDeep(data?.menuOptionSelectionV2);
    const dishes = groupDishes(menuOptionSelection);

    return {
      entreeDishes: dishes?.entreeDishes || [],
      snacks: dishes?.snacks || [],
    };
  }, [data?.menuOptionSelectionV2]);

  const { entreesAmount, snackAmount } = useMemo(() => {
    const initialAmount = data?.menuOptionSelectionV2?.amountPerDay || 0;
    const initialSnackAmount = data?.menuOptionSelectionV2?.snackAmount || 0;

    return {
      entreesAmount: calculateAmountToSelect(
        initialValues.entreeDishes,
        initialAmount
      ),
      snackAmount: calculateAmountToSelect(
        initialValues.snacks,
        initialSnackAmount
      ),
    };
  }, [initialValues, data?.menuOptionSelectionV2]);

  const validateMenu = (values: MenuOptions) => {
    const errors: FormikErrors<MenuOptions> = {};
    (Object.keys(values) as (keyof typeof values)[]).forEach((key) => {
      const mealAmount = key === MealType.Snacks ? snackAmount : entreesAmount;
      const dishes = values[key] as MenuOptionSelection[];
      if (!isFull(dishes, mealAmount)) {
        errors[key] = `${key} is not filled`;
      }
    });
    return errors;
  };

  const menuOptionSelectionToMealSelectionInput = (
    menuOptionSelection: MenuOptionSelection
  ): PatientOptionSelectionV2 => {
    return {
      menuOptionId: menuOptionSelection.menuOptionId,
      quantity: menuOptionSelection.quantity,
    };
  };

  const foodProgramUserId = deliveryData?.deliveryV2?.foodProgramUser.id ?? '';

  const buildPatientMealSelectionInputPayload = (
    values: MenuOptions
  ): PatientMealSelectionInputV2 => {
    const patientMealSelectionInput: PatientOptionSelectionV2[] = [];
    Object.values(values).forEach((value) => {
      const dishes = value as MenuOptionSelection[];
      const selection = dishes
        .map(menuOptionSelectionToMealSelectionInput)
        .filter((selection) => selection.quantity > 0);
      patientMealSelectionInput.push(...selection);
    });

    return {
      foodProgramUserId,
      deliveryMenuId: dietPlanDeliveryMenuId,
      patientOptionSelections: patientMealSelectionInput,
    };
  };

  const onSubmit = async () => {
    const patientMealSelection = buildPatientMealSelectionInputPayload(values);
    try {
      await updatePatientMealSelectionV2({
        variables: {
          props: patientMealSelection,
        },
      });
      analyticsClient?.track('Meal Selection Updated', {
        id: deliveryData?.deliveryV2?.id,
        name: deliveryData?.deliveryV2?.deliveryDate,
      });
      showSnackbarV2({
        message: 'Selection Saved',
        severity: 'success',
        i18nKey: 'meals:editDelivery.snackbar.success',
      });
    } catch (error) {
      console.error(error);
      showSnackbarV2({
        message: 'Unable to save meal selections. Please try again',
        severity: 'error',
        i18nKey: 'meals:editDelivery.snackbar.error',
      });
    }
  };

  const allMenuOptionSelections = useMemo(() => {
    return getAllMenuOptionSelections(initialValues);
  }, [initialValues]);

  const {
    values,
    isValid,
    isSubmitting,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validate: validateMenu,
    onSubmit,
  });

  const { isFeatureEnabled, isLoading: loadingFeatureFlags } = useFeatureFlag();

  const showTags = isFeatureEnabled('show_dish_tags');

  const isLoading = deliveryLoading || loading || loadingFeatureFlags;

  const {
    filterOptions,
    appliedFilters,
    draftResults,
    results,
    handleApply,
    handleCancel,
    handleClearAll,
    handleDraftChange,
  } = useMenuOptionFilter(allMenuOptionSelections, isLoading);

  const filteredMenuIds = useMemo(
    () => results.map((menuOption) => menuOption.menuOptionId),
    [results]
  );

  const filteredEntreeDishes = useMemo(() => {
    return values.entreeDishes.filter((menuOption) =>
      filteredMenuIds.includes(menuOption.menuOptionId)
    );
  }, [filteredMenuIds, values.entreeDishes]);

  const filteredSnacks = useMemo(() => {
    return values.snacks.filter((menuOption) =>
      filteredMenuIds.includes(menuOption.menuOptionId)
    );
  }, [filteredMenuIds, values.snacks]);

  const filterButtonConfirmLabelI18n = useMemo(() => {
    const resultCount = draftResults.length;

    const fallback = `Show ${resultCount > 0 ? resultCount : 'All'} ${pluralize(
      resultCount,
      'Meal',
      undefined,
      true
    )}`;

    return {
      key: 'temporary:tagFilter.filters.meals.confirmWithResults',
      fallback,
      params: { count: resultCount },
    };
  }, [draftResults.length]);

  const filteredResultsLabel = useMemo(() => {
    return appliedFilters.length
      ? `Showing ${pluralize(results.length, 'result')}`
      : null;
  }, [appliedFilters, results.length]);

  const buttonTitleI18n = useMemo(
    () => ({
      key: 'temporary:tagFilter.button.title',
      fallback: 'Filters',
    }),
    []
  );

  const buttonLabelI18n = useMemo(
    () =>
      appliedFilters.length
        ? {
            key: 'temporary:tagFilter.button.titleWithCount',
            fallback: `Filters (${appliedFilters.length})`,
            params: { count: appliedFilters.length },
          }
        : buttonTitleI18n,
    [buttonTitleI18n, appliedFilters.length]
  );

  const clearButtonLabelI18n = useMemo(
    () => ({
      key: 'temporary:tagFilter.modal.clearAll',
      fallback: 'Clear all',
    }),
    []
  );

  const openForChangesDateIsoString = deliveryData?.deliveryV2?.openChanges;

  const openForChangesDateFormatted = useMemo(
    () => formatUTCDate(openForChangesDateIsoString, 'monthDay') || '',
    [openForChangesDateIsoString]
  );

  const onCancel = () => {
    navigate('../');
    showSnackbarV2({
      message: 'Changes Not Saved',
      severity: 'info',
      i18nKey: 'common:snackbar.changesNotSaved',
    });
  };

  const onDishClicked = (dishId: string, dishName: string) =>
    openDialogV2({
      title: dishName,
      content: <DishDetails dishId={dishId} />,
      showCloseButton: true,
      hidePadding: true,
      hideTopPadding: true,
      maxWidth: 'md',
    });

  const renderFilter = () => {
    return (
      <Paper
        variant="elevation"
        elevation={2}
        sx={{
          paddingX: 3,
          paddingY: 2,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          marginBottom: 1,
        }}
      >
        <TagFilter
          anchorTitle={buttonLabelI18n}
          containerTitle={buttonTitleI18n}
          options={filterOptions}
          appliedValue={appliedFilters}
          confirmButtonLabel={filterButtonConfirmLabelI18n}
          clearButtonLabel={clearButtonLabelI18n}
          onDraftChange={handleDraftChange}
          onApply={handleApply}
          onCancel={handleCancel}
          v2
        />
        {!!appliedFilters.length && (
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              i18nKey="temporary:tagFilter.container.showingResults"
              i18nParams={{ count: results.length }}
            >
              {filteredResultsLabel}
            </Typography>
            <Link
              component="button"
              onClick={handleClearAll}
              i18nKey="temporary:tagFilter.container.clearFilters"
            >
              Clear filters
            </Link>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <>
      <Typography
        mb={3}
        i18nKey="meals:editDelivery.open"
        i18nParams={{ date: openForChangesDateFormatted }}
      >
        {`Open for changes until ${openForChangesDateFormatted} at 9 AM ET`}
      </Typography>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!!filterOptions.length && showTags && renderFilter()}
          <Box
            overflow="scroll"
            sx={{
              overflow: 'hidden',
              marginBottom: 2,
              borderRadius: '5px',
            }}
          >
            <MenuSectionV2
              name="entreeDishes"
              amount={entreesAmount}
              hasSides={hasSides}
              showTags={showTags}
              filteredOptions={filteredEntreeDishes}
              allOptions={values.entreeDishes}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              onDishClicked={onDishClicked}
            />
            {snackAmount > 0 && (
              <MenuSectionV2
                name="snacks"
                amount={snackAmount}
                hasSides={hasSides}
                filteredOptions={filteredSnacks}
                allOptions={values.snacks}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onDishClicked={onDishClicked}
              />
            )}
          </Box>
          {!deliveryLoading && (
            <FooterButtons
              amount={entreesAmount}
              snackAmount={snackAmount}
              values={values}
              disabled={!isValid || isSubmitting || isEmpty(touched)}
              hasSides={hasSides}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditDelivery;
