import { Box, InputAdornment, Stack, useTheme } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';
import { Icon, TextFieldV2, Typography } from '@fdha/web-ui-library';

interface FormikContext {
  steps: { title: string }[];
}

interface GoalStepInputProps {
  disabled: boolean;
  title: string;
  error?: string;
  onChangeTitle: (newTitle: string) => void;
  onBlur: (currentTitle?: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const GoalStepTextInput: React.FC<GoalStepInputProps> = ({
  disabled,
  onBlur,
  onChangeTitle,
  onKeyDown,
  title,
  error,
}) => {
  const theme = useTheme();

  return (
    <TextFieldV2
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              name="square-outline"
              fill={theme.palette.text.secondary}
              size="medium"
            />
          </InputAdornment>
        ),
      }}
      inputProps={{ maxLength: 100 }}
      onBlur={(event) => onBlur(event.target.value)}
      onChange={(event) => onChangeTitle(event.target.value)}
      onKeyDown={onKeyDown}
      placeholder="Type here to add a step"
      i18nKeyPlaceholder="activities:fields.steps.add"
      value={title}
      error={!!error}
      helperText={error}
    />
  );
};

interface GoalStepsProps {
  disabled?: boolean;
}

const GoalSteps: React.FC<GoalStepsProps> = ({ disabled }) => {
  const {
    values: { steps },
    handleChange,
    touched,
    errors,
  } = useFormikContext<FormikContext>();

  const stepsError =
    touched.steps && typeof errors.steps === 'string'
      ? errors.steps
      : undefined;

  const addOrDeleteStep = (
    title: string = '',
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (!title && index !== steps.length - 1) {
      arrayHelpers.remove(index);
    } else if (title && index === steps.length - 1) {
      arrayHelpers.push({ title: '' });
    }
  };

  return (
    <Box marginTop={2}>
      <Typography
        variant="body1"
        marginBottom="6px"
        fontWeight="bold"
        i18nKey="activities:fields.steps.label"
      >
        Steps
      </Typography>
      <FieldArray
        name="steps"
        render={(arrayHelpers) => (
          <Stack display="flex" flexDirection="column" rowGap={1}>
            {steps.map((step, index) => (
              <GoalStepTextInput
                disabled={!!disabled}
                key={index}
                title={step.title}
                error={stepsError}
                onChangeTitle={handleChange(`steps[${index}].title`)}
                onBlur={(title?: string) =>
                  addOrDeleteStep(title, index, arrayHelpers)
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    addOrDeleteStep(
                      (event.target as HTMLInputElement).value,
                      index,
                      arrayHelpers
                    );
                  }
                }}
              />
            ))}
          </Stack>
        )}
      />
    </Box>
  );
};

export default GoalSteps;
