import { DefaultBasePage } from '@components';
import { displayProtectedImage } from '@fdha/web-auth';
import {
  addPhoneNumberValidation,
  Button,
  MaskedTextFieldV2,
  SelectV2,
  states,
  TextFieldV2,
  UploadPictureV2,
  useSnackbar,
  convertMaskToE164,
  maskPhone,
  getAddressValidation,
  DatePickerV2,
  formatDate,
} from '@fdha/web-ui-library';
import {
  GetProfileDocument,
  useGetProfileQuery,
  UserType,
  useUpdateProfileMutation,
  useUploadProfilePictureMutation,
} from '@fdha/graphql-api-patient';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { usePrompt } from '@hooks';
import { useTranslatedErrorMessages } from '@fdha/common-hooks';
import { utcToZonedTime } from 'date-fns-tz';

interface Profile {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  complement: string;
  city: string;
  state: string;
  zip: string;
  birthDate: Date | null;
}

const YourAccount = () => {
  const {
    requiredMessage,
    validEmailMessage,
    validPhoneMessage,
    validDateMessage,
    validZipMessage,
  } = useTranslatedErrorMessages();

  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const { data } = useGetProfileQuery();
  const [uploadPicture] = useUploadProfilePictureMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(requiredMessage),
    email: Yup.string()
      .trim()
      .email(validEmailMessage)
      .required(requiredMessage),
    phoneNumber:
      addPhoneNumberValidation(validPhoneMessage).required(requiredMessage),
    birthDate: Yup.date()
      .nullable(true)
      .typeError(validDateMessage)
      .required(requiredMessage),
  });

  const addressValidationSchema = validationSchema.concat(
    getAddressValidation({
      required: requiredMessage,
      zip: validZipMessage,
    })
  );

  const isCt = data?.me.type === UserType.ClinicalTrialPatient;

  const initialValues: Profile = {
    name: data?.me.name || '',
    email: data?.me.email || '',
    phoneNumber: maskPhone(data?.me.phone_number),
    address: data?.me.address?.street_address || '',
    complement: data?.me.address?.complement || '',
    city: data?.me.address?.locality || '',
    state: data?.me.address?.region || '',
    zip: data?.me.address?.postal_code || '',
    birthDate: data?.me.birthdate
      ? new Date(utcToZonedTime(data.me.birthdate, 'UTC'))
      : null,
  };

  const handleUpload = async (imageFile: File) => {
    await uploadPicture({
      variables: {
        props: imageFile,
      },
      refetchQueries: [GetProfileDocument],
    });
  };

  const handleErrors = (
    errors: FormikErrors<Profile>,
    touched: FormikTouched<Profile>
  ) => {
    return {
      name: touched.name && errors.name,
      email: touched.email && errors.email,
      phoneNumber: touched.phoneNumber && errors.phoneNumber,
      birthDate: touched.birthDate && errors.birthDate,
      address: touched.address && errors.address,
      zip: touched.zip && errors.zip,
      city: touched.city && errors.city,
      state: touched.state && errors.state,
    };
  };

  const handleCancel = () => {
    showSnackbarV2({
      message: 'Changes Not Saved',
      severity: 'info',
      i18nKey: 'common:snackbar.changesNotSaved',
    });
    navigate('/profile', { replace: true });
  };

  const handleSubmit = async (values: Profile) => {
    try {
      await updateProfile({
        variables: {
          props: {
            name: values.name?.trim().replace(/\s+/g, ' '),
            email: values.email?.trim(),
            phone_number: convertMaskToE164(values.phoneNumber),
            birthdate: formatDate(values.birthDate),
            address: {
              street_address: values.address,
              complement: values.complement,
              locality: values.city,
              region: values.state,
              postal_code: values.zip,
            },
          },
        },
        refetchQueries: [GetProfileDocument],
      });

      showSnackbarV2({
        message: 'Changes Saved',
        severity: 'success',
        i18nKey: 'common:snackbar.changesSaved',
      });
    } catch (error) {
      console.error(error);
      showSnackbarV2({
        message: 'Error to update profile',
        severity: 'error',
        i18nKey: 'profile:yourAccount.snackbar.errorUpdateProfile',
      });
    }
  };

  const {
    errors,
    isSubmitting,
    values,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit: FormikHandleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: isCt ? addressValidationSchema : validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const error = handleErrors(errors, touched);

  usePrompt(dirty);

  return (
    <DefaultBasePage
      title="Your Account"
      contentSize="small"
      i18nKeyTitle="profile:yourAccount.title"
    >
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box alignSelf="center">
          <UploadPictureV2
            name={values.name}
            picture={data?.me.picture}
            size={100}
            downloadPicture={displayProtectedImage}
            handleUpload={handleUpload}
          />
        </Box>
        <Stack spacing={2} mt="22px">
          <TextFieldV2
            name="name"
            i18nKeyTitle="profile:yourAccount.input.name.label"
            title="Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!error.name}
            helperText={error.name}
          />
          <DatePickerV2
            title="Birth date"
            value={values.birthDate}
            i18nKeyTitle="profile:yourAccount.input.birthDate.label"
            locale={data?.me?.language}
            onBlur={handleBlur}
            error={!!error.birthDate}
            helperText={error.birthDate}
            onChange={(value) => {
              setFieldValue('birthDate', value);
            }}
          />
          <MaskedTextFieldV2
            name="phoneNumber"
            i18nKeyTitle="profile:yourAccount.input.mobileNumber.label"
            title="Mobile number"
            mask="phoneNumber"
            i18nKeyPlaceholder="profile:yourAccount.input.mobileNumber.placeholder"
            placeholder="xxx-xxx-xxxx"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!error.phoneNumber}
            helperText={error.phoneNumber}
          />
          <TextFieldV2
            name="email"
            i18nKeyTitle="profile:yourAccount.input.email.label"
            title="Email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!error.email}
            helperText={error.email}
          />
          {isCt && (
            <>
              <TextFieldV2
                name="address"
                i18nKeyTitle="profile:yourAccount.input.address.label"
                title="Address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!error.address}
                i18nKeyHelper={
                  error.address
                    ? undefined
                    : 'profile:yourAccount.input.address.helper'
                }
                helperText={error.address ?? 'Street address'}
              />
              <TextFieldV2
                name="complement"
                i18nKeyTitle="profile:yourAccount.input.addressLine2.label"
                title="Address line #2 if applicable"
                i18nKeyHelper="profile:yourAccount.input.addressLine2.helper"
                helperText="Appt, Suite, Unit, Building, Floor, etc"
                value={values.complement}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextFieldV2
                name="city"
                i18nKeyTitle="profile:yourAccount.input.city.label"
                title="City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!error.city}
                helperText={error.city}
              />
              <Stack spacing={2} direction="row">
                <SelectV2
                  fullWidth
                  name="state"
                  i18nKeyTitle="profile:yourAccount.input.state.label"
                  title="State"
                  value={values.state}
                  options={states}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!error.state}
                  helperText={error.state}
                />
                <TextFieldV2
                  name="zip"
                  i18nKeyTitle="profile:yourAccount.input.zipCode.label"
                  title="ZIP/Postal code"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!error.zip}
                  helperText={error.zip}
                />
              </Stack>
            </>
          )}
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button onClick={handleCancel} i18nKey="common:button.cancel">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => FormikHandleSubmit()}
              sx={{ width: '140px' }}
              i18nKey="common:button.save"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </DefaultBasePage>
  );
};

export default YourAccount;
