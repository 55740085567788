"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        patientApiBaseUrl: 'https://ysyuu83xi9.execute-api.us-east-1.amazonaws.com/prod/patient/graphql',
        dolbyPatientBaseUrl: 'https://ysyuu83xi9.execute-api.us-east-1.amazonaws.com/prod/dolby-patient',
        commonApi: 'https://ysyuu83xi9.execute-api.us-east-1.amazonaws.com/prod/common',
        apiKey: 'xablauapikeyxablau123-prod',
        webSocketBaseUrl: 'wss://2assnrw8ed.execute-api.us-east-1.amazonaws.com/prod'
    },
    cloudfront: 'd1c1rgjojpvagl.cloudfront.net',
    cognito: {
        patientUserPoolId: 'us-east-1_p49VvBtvZ',
        patientUserPoolClientId: '7lessv6bs8oqg9o08q6kuf8pdv'
    },
    pinpoint: '818a479129954e0fbfdc4487a5b06421',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
