export const getTooltipColor = (value: number, target: number) => {
  if (value <= target) {
    return '#335A26';
  }

  return '#B72D3B';
};

export enum BHBLevel {
  ABOVE,
  BELOW,
  OK,
}

export const getBHBLevel = (
  value: number,
  limits: { bottom: number; upper: number }
) => {
  if (value > limits.upper) {
    return BHBLevel.ABOVE;
  } else if (value < limits.bottom) {
    return BHBLevel.BELOW;
  } else {
    return BHBLevel.OK;
  }
};

export const getBHBTooltipColor = (
  value: number,
  limits: { bottom: number; upper: number }
) => {
  const bhbLevel = getBHBLevel(value, limits);

  switch (bhbLevel) {
    case BHBLevel.ABOVE:
    case BHBLevel.BELOW:
      return '#B72D3B';
    default:
      return '#335A26';
  }
};
