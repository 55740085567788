import React from 'react';
import { useAnalytics, useFeatureFlag } from '@fdha/common-hooks';
import {
  LearningModuleOverview,
  SortByOrder,
  useGetLearningModulesQuery,
} from '@fdha/graphql-api-patient';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CourseCard from '../CourseCard';
import EmptyState from '../EmptyState';
import LoadingState from '../LoadingState';

import Header from './Header';

const CourseSection = () => {
  const { isFeatureEnabled } = useFeatureFlag();
  const showCourses = isFeatureEnabled('show_learning_content');
  const { analyticsClient } = useAnalytics();
  const navigate = useNavigate();

  const { data, loading } = useGetLearningModulesQuery({
    variables: {
      first: 2,
      sortBy: { sortBy: [{ field: 'assigned_on', order: SortByOrder.Desc }] },
    },
    fetchPolicy: 'network-only',
  });

  const courses = data?.learningModules.edges.map((edge) => edge.node) || [];

  const hasCourses = !!courses.length && showCourses;
  const showEmptyStateCourses = !hasCourses && !loading;
  const showSeeAll = !loading && hasCourses;

  const navigateCourse = (item: LearningModuleOverview) => {
    const courseRoute = `/learning/courses/${item.id}`;
    navigate(courseRoute);
    analyticsClient?.track('Course Opened', {
      id: item.id,
      name: item.title,
    });
  };

  const renderItem = (item: LearningModuleOverview) => {
    return (
      <Grid item xs={12} md={6} key={item.id}>
        <CourseCard
          coverImg={item.image}
          title={item.title}
          progress={item.status ?? 0}
          onClick={() => navigateCourse(item)}
        />
      </Grid>
    );
  };

  return (
    <Box>
      <Header
        title="Courses"
        route="/learning/courses"
        showSeeAll={showSeeAll}
        i18nKey="learning:course.title"
      />
      {!showEmptyStateCourses ? (
        <Grid container rowSpacing={2} columnSpacing={2}>
          {loading ? (
            <LoadingState
              type="course"
              height="230px"
              amount={2}
              gridProps={{ xs: 12, md: 6 }}
            />
          ) : (
            courses.map((item) => renderItem(item))
          )}
        </Grid>
      ) : (
        <EmptyState type="course" />
      )}
    </Box>
  );
};

export default CourseSection;
