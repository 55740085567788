import React, { useState } from 'react';
import { ImageBasePage } from '@components';
import { Button, Typography, useSnackbar } from '@fdha/web-ui-library';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  GetCommunityUserDocument,
  useJoinCommunityMutation,
} from '@fdha/graphql-api-patient';
import fdhaConfig from '@fdha/aws-config-patient';

const Community = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { showSnackbarV2 } = useSnackbar();

  const [index, setIndex] = useState(0);

  const [joinCommunityMutation, { loading }] = useJoinCommunityMutation({
    refetchQueries: [GetCommunityUserDocument],
  });

  const [checked, setChecked] = useState(false);

  const content = [
    {
      title:
        'A safe and supportive Community to connect with others on their journey.',
      type: 'mainPage',
    },
    {
      title: 'Be mindful:',
      subtitle1: 'This is a safe space to be vulnerable.',
      subtext1: 'Be respectful of everyone’s experiences and privacy.',
      subtitle2: 'This is a safe space to connect.',
      subtext2: 'Share, listen, and treat others with kindness.',
      subtitle3: 'This is a stress-free space.',
      subtext3:
        'Do not post hate speech, spam, misinformation or advertisement.',
      type: 'agreementsPage',
    },
  ];

  const isLastItem = index + 1 === content.length;
  const item = content[index];
  const isAgreementsPage = item.type === 'agreementsPage';

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleNext = async () => {
    if (!isLastItem) {
      setIndex(index + 1);
    } else {
      try {
        await joinCommunityMutation();
        navigate('/community');
      } catch (e) {
        showSnackbarV2({
          severity: 'error',
          message: 'Sorry, something went wrong. Please try again.',
        });
      }
    }
  };

  const handleBack = () => {
    if (index !== 0) {
      setIndex(index - 1);
    } else {
      navigate(-1);
    }
  };

  const agreementsText = (title: string = '', text: string = '') => {
    return (
      <Box mb={3}>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.text.secondary}>
          {text}
        </Typography>
      </Box>
    );
  };

  const getCheckBoxLabel = () => {
    return (
      <Typography variant="subtitle1">
        I agree to the{' '}
        <Link
          href={`https://${fdhaConfig.cloudfront}/FDHACommunityGuidelines.html`}
          target="_blank"
        >
          Community Guidelines
        </Link>
        .
      </Typography>
    );
  };

  return (
    <ImageBasePage
      image={{ url: '/images/woman_07.png', altText: 'Woman' }}
      title={item.title}
      titleVariant={isAgreementsPage ? 'h3' : undefined}
      alignHeader="flex-start"
      imagePosition="right"
      contentSize="small"
    >
      {isAgreementsPage ? (
        <Box>
          <Box mt={3}>{agreementsText(item.subtitle1, item.subtext1)}</Box>
          {agreementsText(item.subtitle2, item.subtext2)}
          {agreementsText(item.subtitle3, item.subtext3)}
          <FormControlLabel
            control={<Checkbox />}
            name="acceptedAgreements"
            checked={checked}
            onChange={handleChange}
            label={getCheckBoxLabel()}
          />
        </Box>
      ) : (
        ''
      )}
      <Stack direction="row" spacing={2} mt={4} mb={3}>
        <Button
          i18nKey="common:button.back"
          variant="outlined"
          fullWidth
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={isAgreementsPage ? !checked || loading : false}
        >
          {isLastItem ? 'Join' : 'Next'}
        </Button>
      </Stack>
    </ImageBasePage>
  );
};

export default Community;
