import { ImageBasePage } from '@components';
import { Button, Typography } from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CommunityUnavailable = () => {
  const navigate = useNavigate();

  const content = {
    title: 'Our Community feature is only available on our mobile app…for now.',
    subtitle:
      'Connect with us through our convenient mobile app while we work hard to bring our Community feature to you on the web.',
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <ImageBasePage
      image={{ url: '/images/couple_01.png', altText: 'Couple' }}
      title={content.title}
      titleVariant="h3"
      alignHeader="flex-start"
      imagePosition="right"
      fullImage
      contentSize="small"
    >
      <Box mt={3}>
        <Typography variant="body1" fontSize="18px">
          {content.subtitle}
        </Typography>
      </Box>

      <Stack mt={4} mb={3} width="50%">
        <Button variant="contained" onClick={handleBack} fullWidth>
          Go to home
        </Button>
      </Stack>
    </ImageBasePage>
  );
};

export default CommunityUnavailable;
