import { DefaultBasePage } from '@components';
import { useGetProfileQuery } from '@fdha/graphql-api-patient';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@fdha/web-ui-library';
import { useFeatureFlag, useTranslation } from '@fdha/common-hooks';
import React from 'react';

import MealsHelpCard from './MealsHelpCard';

const MealsHelp = () => {
  const navigate = useNavigate();
  const { data } = useGetProfileQuery();
  const { translate } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlag();
  const showIntercom = isFeatureEnabled('show_intercom');

  const openChat = (message: string) => {
    showIntercom
      ? Intercom('showNewMessage', message)
      : navigate('/chat', {
          state: {
            channelId: data?.me.stream_channel_id,
            preDefinedMessage: message,
          },
        });
  };

  return (
    <DefaultBasePage
      title="Help"
      contentSize="small"
      i18nKeyTitle="meals:help.title"
    >
      <Stack spacing={1.5}>
        <Typography variant="body1" i18nKey="meals:help.subtitle">
          We are here to help you if you have any questions about your diet plan
          or delivery.
        </Typography>
        <Stack direction="row" spacing={1.5}>
          <MealsHelpCard
            title="Message Coach for help"
            subtitle="They’ll help you find the best possible solution."
            i18nKeyTitle="meals:help.messageCoach.title"
            i18nKeySubtitle="meals:help.messageCoach.subtitle"
            icon="message-square-outline"
            onClick={() =>
              openChat(
                translate(
                  'meals:help.messageCoach.message',
                  'Hi Coach. I am having an issue with my meal delivery. Can you help me?'
                )
              )
            }
          />
          <MealsHelpCard
            title="Request delivery change"
            subtitle="Talk to your Coach and request the changes you want."
            i18nKeyTitle="meals:help.deliveryChange.title"
            i18nKeySubtitle="meals:help.deliveryChange.subtitle"
            icon="repeat-outline"
            onClick={() =>
              openChat(
                translate(
                  'meals:help.deliveryChange.message',
                  'Hi Coach. I need to request a change to my meal delivery.'
                )
              )
            }
          />
        </Stack>
        <Stack direction="row" spacing={1.5}>
          <MealsHelpCard
            title="Report delivery issues"
            subtitle="Contact your coach about your deliveries."
            i18nKeyTitle="meals:help.deliveryIssues.title"
            i18nKeySubtitle="meals:help.deliveryIssues.subtitle"
            icon="car"
            onClick={() =>
              openChat(
                translate(
                  'meals:help.deliveryIssues.message',
                  'Hi Coach. I am having an issue with my meal delivery. Can you help me?'
                )
              )
            }
          />
          <MealsHelpCard
            title="FAQs"
            subtitle="Find out if your question has already been answered."
            i18nKeyTitle="meals:help.faqs.title"
            i18nKeySubtitle="meals:help.faqs.subtitle"
            icon="question-mark-circle-outline"
            onClick={() => navigate('/profile/help')}
          />
        </Stack>
      </Stack>
    </DefaultBasePage>
  );
};

export default MealsHelp;
