import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { Button, Typography } from '@fdha/web-ui-library';

interface HeaderProps {
  title: string;
  route: string;
  showSeeAll: boolean;
  i18nKey?: string;
}

const Header: FC<HeaderProps> = ({ title, route, showSeeAll, i18nKey }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Typography variant="h5" i18nKey={i18nKey}>
        {title}
      </Typography>
      {showSeeAll && (
        <Button onClick={() => navigate(route)} i18nKey="common:button.seeAll">
          See All
        </Button>
      )}
    </Box>
  );
};

export default Header;
