import {
  useCommunityMarkAllNotificationsAsSeenMutation,
  useListCommunityNotificationsQuery,
  ListCommunityNotificationsDocument,
} from '@fdha/graphql-api-patient';
import { useCallback } from 'react';

export const useCommunityNotifications = () => {
  const {
    data,
    loading: isLoading,
    refetch,
  } = useListCommunityNotificationsQuery();
  const count = data?.communityNotifications.numUnseen || 0;

  const [communityMarkAllNotificationsAsSeen] =
    useCommunityMarkAllNotificationsAsSeenMutation();

  const handleClear = useCallback(async () => {
    if (!count) {
      return;
    }

    try {
      await communityMarkAllNotificationsAsSeen({
        refetchQueries: [ListCommunityNotificationsDocument],
      });
    } catch (e) {
      console.log('Unable to clear community notifications', e);
    }
  }, [communityMarkAllNotificationsAsSeen, count]);

  return {
    count,
    isLoading,
    clear: handleClear,
    refetch: refetch,
  };
};
