import React from 'react';
import { ImageBasePage } from '@components';
import { Button, Typography, Link } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdvertisingMeals = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const getAdvertisingText = (
    title: string,
    text: string,
    i18nKeyTitle: string,
    i18nKeyText: string
  ) => {
    return (
      <Box mb={3}>
        <Typography variant="h6" i18nKey={i18nKeyTitle}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          i18nKey={i18nKeyText}
          color={theme.palette.text.secondary}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const linkFaeth = (
    <Link
      href="https://www.faeththerapeutics.com"
      target="_blank"
      i18nKey="meals:advertisingTrial.link"
    >
      www.faeththerapeutics.com
    </Link>
  );

  return (
    <ImageBasePage
      image={{ url: '/images/food_04.png', altText: 'Food' }}
      title="Interested in joining a Faeth Clinical Trial? We may be able to deliver meals to your door if you qualify."
      i18nKeyTitle="meals:advertisingTrial.title"
      alignHeader="flex-start"
      imagePosition="right"
      contentSize="small"
      fullImage={true}
      showLogo={false}
    >
      <>
        <Box mt={4}>
          {getAdvertisingText(
            'Precision Nutrition',
            'Scientifically-backed meals that reprogram your metabolism to starve your cancer of the nutrients it needs.',
            'meals:advertisingTrial.nutrition.title',
            'meals:advertisingTrial.nutrition.text'
          )}
        </Box>
        {getAdvertisingText(
          'On-demand Support',
          'Faeth Study Dietitians are committed to supporting you throughout your time on trial.',
          'meals:advertisingTrial.support.title',
          'meals:advertisingTrial.support.text'
        )}
      </>

      <Stack direction="row" spacing={2} mt={4} mb={4}>
        <Button
          i18nKey="common:button.back"
          variant="outlined"
          fullWidth
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          href="https://faeththerapeutics.com/clinical-trials"
          target="_blank"
          i18nKey="meals:advertisingTrial.button"
        >
          Contact Us
        </Button>
      </Stack>

      <Typography
        variant="body1"
        i18nKey="meals:advertisingTrial.learnMore"
        i18nComponents={{ linkFaeth }}
      >
        {`Learn more at: <linkFaeth>www.faeththerapeutics.com</linkFaeth>`}
      </Typography>
    </ImageBasePage>
  );
};

export default AdvertisingMeals;
