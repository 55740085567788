import React from 'react';
import { DefaultBasePage, HomeCard } from '@components';
import { Box, CardContent, CardMedia, Grid, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useGetMainRoutes } from '@hooks';
import { Icon, SingleBadge, Typography } from '@fdha/web-ui-library';
import { useFeatureFlag } from '@fdha/common-hooks';
import { useNavigate } from 'react-router-dom';

import HomeTrialData from './trialData/HomeTrialData';
import HomeBHBTrialData from './bhbTrialData/HomeBHBTrialData';

interface CardItem {
  label: string;
  i18nKeyLabel?: string;
  text: string;
  i18nKeyText?: string;
  inactiveIcon: string;
  image: string;
  to: string;
  notificationCount?: number;
}

const Home = () => {
  const theme = useTheme();
  const { isFeatureEnabled } = useFeatureFlag();
  const { mainRoutes } = useGetMainRoutes();
  const navigate = useNavigate();

  const showAminoAcidsTrialData = isFeatureEnabled('show_trial_data');
  const showBhbTrialData = isFeatureEnabled('show_bhb_trial_data');
  const showTrialData = showAminoAcidsTrialData || showBhbTrialData;

  const getCardInfo = (card: string) => {
    switch (card) {
      case 'surveys':
        return {
          image: '/images/man_01.png',
          text: 'Complete surveys to track your health and keep your Coach in the loop.',
          i18nKeyText: 'home:card.surveys.description',
        };
      case 'chat':
        return {
          image: '/images/woman_03.png',
          text: 'Get in touch with your Faeth Coach and get help achieving your goals.',
          i18nKeyText: 'home:card.chat.description',
        };
      case 'activities':
        return {
          image: '/images/woman_04.png',
          text: 'Follow up on your appointments, never miss a task and set up your goals.',
          i18nKeyText: 'home:card.activities.description',
        };
      case 'community':
        return {
          image: '/images/women_group_01.png',
          text: 'Join Faeth’s safe and supportive space to connect with others on their journey.',
          i18nKeyText: 'home:card.community.description',
        };
      case 'learning':
        return {
          image: '/images/woman_05.png',
          text: 'Access cancer-related and wellness content from experts anytime you want.',
          i18nKeyText: 'home:card.learning.description',
        };
      case 'meals':
        return {
          image: '/images/food_01.png',
          text: 'If you are currently on a Faeth trial, update your meal orders here. If you are not on a trial, learn more about them here.',
          i18nKeyText: 'home:card.meals.description',
        };
      default:
        return {
          image: '',
          text: '',
          i18nKeyText: '',
        };
    }
  };

  const mainRoutesCards = mainRoutes.map((item) => {
    const { text, i18nKeyText, image } = getCardInfo(item.value);
    return {
      ...item,
      text,
      i18nKeyText,
      image,
    };
  });

  const renderItem = (item: CardItem) => (
    <HomeCard onClick={() => navigate(item.to)}>
      <CardMedia
        sx={{ borderRadius: '6px' }}
        component="img"
        height={172}
        src={item.image}
        alt="Faeth card picture"
      />
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: 0,
          marginTop: 1,
        }}
      >
        <Icon name={item.inactiveIcon} size="xlarge" fill={grey[900]} />
        <Box display="flex" alignItems="center" marginY={1}>
          <Typography i18nKey={item.i18nKeyLabel} variant="h5">
            {item.label}
          </Typography>
          <SingleBadge
            color="error"
            badgeContent={item.notificationCount}
            sx={{ marginLeft: 1 }}
          />
        </Box>
        <Typography
          i18nKey={item.i18nKeyText}
          variant="body1"
          color={theme.palette.text.secondary}
        >
          {item.text}
        </Typography>
      </CardContent>
    </HomeCard>
  );
  return (
    <DefaultBasePage
      i18nKeyTitle={showTrialData ? 'home:trial.title' : 'home:title'}
      title={showTrialData ? 'Trial data' : 'Where do you want to go next?'}
      showBack={false}
      showNavigation={false}
      centeredTitle
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {showTrialData && (
          <>
            {showBhbTrialData ? <HomeBHBTrialData /> : <HomeTrialData />}
            <Typography i18nKey="home:title" variant="h4" margin={3}>
              Where do you want to go next?
            </Typography>
          </>
        )}
        <Grid container rowSpacing={4} columnSpacing={2} marginBottom={5}>
          {mainRoutesCards.map((item) => (
            <Grid item xs={6} md={4} key={item.value}>
              {renderItem(item)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </DefaultBasePage>
  );
};

export default Home;
