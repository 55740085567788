import React, { useEffect, useState } from 'react';
import { DefaultBasePage } from '@components';
import { useLocation } from 'react-router-dom';
import { Paper, Stack } from '@mui/material';
import {
  ActivitiesViewV2,
  Button,
  DayRange,
  useDialog,
  usePrevious,
  useSnackbar,
} from '@fdha/web-ui-library';
import { useLocalStorage, useReadLocalStorage } from '@hooks';
import { setSelectedActivity, updateSelectedActivity } from '@states';
import { parseISO } from 'date-fns';
import {
  Activity,
  ActivityKind,
  GetActivitiesInRangeDocument,
  Task,
  useGetActivitiesInRangeQuery,
  useGetProfileQuery,
  useUnscheduleAppointmentMutation,
  useUnscheduleGoalMutation,
  useUnscheduleTaskMutation,
  useUpdateTaskCompletedStateMutation,
} from '@fdha/graphql-api-patient';
import { NetworkStatus, useApolloClient } from '@apollo/client';

import {
  AppointmentDetails,
  GoalDetails,
  TaskDetails,
} from './ActivitiesDetails';
import AddActivity from './AddActivity/AddActivity';
import EditActivity from './AddActivity/EditActivity';

const Activities = () => {
  const location = useLocation();
  const client = useApolloClient();
  const { openDialogV2, closeDialog } = useDialog();
  const { showSnackbarV2 } = useSnackbar();
  const activityPreferences = useReadLocalStorage('activityPreferences', false);
  const previousActivityPreferences = usePrevious(activityPreferences);
  const { saveToLocalStorage } = useLocalStorage('activityPreferences', false);

  const query = new URLSearchParams(window.location.search);
  const dateFromUrl = query.get('startDate');
  const activityDetailsIdFromUrl = query.get('activityDetailsId');
  const initialDateToScroll = dateFromUrl ? parseISO(dateFromUrl) : new Date();

  const [selectedRange, setSelectedRange] = useState<DayRange>();
  const [dateToScroll, setDateToScroll] = useState<Date | undefined>(
    initialDateToScroll
  );
  const previousDate = usePrevious(dateToScroll?.toISOString());

  const { data: profileData } = useGetProfileQuery();
  const { data, error, networkStatus } = useGetActivitiesInRangeQuery({
    variables: {
      from: selectedRange?.from.toISOString() || '',
      to: selectedRange?.to.toISOString() || '',
    },
    notifyOnNetworkStatusChange: true,
  });
  const [updateTaskCompletedState] = useUpdateTaskCompletedStateMutation();
  const [unscheduleTaskMutation] = useUnscheduleTaskMutation();
  const [unscheduleGoalMutation] = useUnscheduleGoalMutation();
  const [unscheduleAppointmentMutation] = useUnscheduleAppointmentMutation();

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'activities',
      });
    };
  }, [client.cache]);

  const onUpdateTask = (data: Task, completed: boolean) => {
    updateTaskCompletedState({
      variables: { instanceId: data.instanceId!, completed: completed },
      update(cache) {
        cache.modify({
          id: cache.identify(data),
          fields: {
            completed() {
              return completed;
            },
          },
        });
      },
    });

    updateSelectedActivity({ completed } as Task);
  };

  const getIsCreatedByCoach = (creatorId?: string | null) =>
    creatorId !== profileData?.me.id;

  const getContent = (activity: Activity) => {
    const isCreatedByCoach = getIsCreatedByCoach(activity.creatorId);
    setSelectedActivity(activity);

    switch (activity.kind) {
      case ActivityKind.Task:
        return (
          <TaskDetails
            isCreatedByCoach={isCreatedByCoach}
            onUpdateTask={onUpdateTask}
          />
        );
      case ActivityKind.Goal:
        return <GoalDetails isCreatedByCoach={isCreatedByCoach} />;
      case ActivityKind.Appointment:
        return <AppointmentDetails isCreatedByCoach={isCreatedByCoach} />;
    }
  };

  const openEditActivity = (activity: Activity) => {
    openDialogV2({
      title: `Edit ${activity.kind}`,
      i18nKeyTitle: `activities:addOrEdit.editTitle.${activity.kind.toLocaleLowerCase()}`,
      content: <EditActivity data={activity} />,
    });
  };

  const getHeaderButtons = (activity: Activity) => (
    <Stack spacing={2} direction="row" mr={3}>
      <Button
        startEvaIcon={{ name: 'edit-outline' }}
        onClick={() => openEditActivity(activity)}
        i18nKey="common:button.edit"
      >
        Edit
      </Button>
      <Button
        startEvaIcon={{ name: 'trash-2-outline' }}
        onClick={async () => {
          try {
            const props = {
              variables: { id: activity.id },
              refetchQueries: [GetActivitiesInRangeDocument],
            };
            switch (activity.kind) {
              case ActivityKind.Task:
                await unscheduleTaskMutation(props);
                break;
              case ActivityKind.Goal:
                await unscheduleGoalMutation(props);
                break;
              case ActivityKind.Appointment:
                await unscheduleAppointmentMutation(props);
                break;
            }
            showSnackbarV2({
              message: `${activity.kind} Unscheduled`,
              severity: 'info',
              i18nKey: `activities:addOrEdit.snackbar.${activity.kind.toLowerCase()}.unscheduled`,
            });
          } catch (error) {
            showSnackbarV2({
              message: `Unable to Unschedule ${activity.kind}`,
              severity: 'error',
              i18nKey: `activities:addOrEdit.snackbar.${activity.kind.toLowerCase()}.unableUnschedule`,
            });
          } finally {
            closeDialog();
          }
        }}
        i18nKey="activities:addOrEdit.button"
      >
        Unschedule
      </Button>
    </Stack>
  );

  const handleSelectActivity = (activity: Activity) => {
    const isCreatedByCoach = getIsCreatedByCoach(activity.creatorId);

    openDialogV2({
      title: 'Details',
      i18nKeyTitle: 'activities:details.title',
      content: getContent(activity),
      headerButtons: !isCreatedByCoach && getHeaderButtons(activity),
      showCloseButton: true,
    });
  };

  const handleAddActivity = () => {
    openDialogV2({
      title: 'Add new',
      i18nKeyTitle: 'activities:addOrEdit.addTitle',
      content: <AddActivity />,
    });
  };

  if (error) {
    console.log(JSON.stringify(error, null, 2));
    return null;
  }

  return (
    <DefaultBasePage
      i18nKeyTitle="activities:title"
      title="Activities"
      contentSize="small"
    >
      <Paper
        sx={{
          height: '100%',
          pb: 3,
        }}
      >
        <ActivitiesViewV2
          pathname={location.pathname}
          loading={networkStatus === NetworkStatus.loading || !data}
          activityPreferences={activityPreferences}
          previousActivityPreferences={previousActivityPreferences}
          dateFromUrl={dateFromUrl}
          activityDetailsIdFromUrl={activityDetailsIdFromUrl || ''}
          selectedRange={selectedRange}
          previousDate={previousDate}
          dateToScroll={dateToScroll?.toISOString()}
          activitiesInRange={data?.activitiesInRange}
          onAdd={handleAddActivity}
          onUpdateTask={onUpdateTask}
          setSelectedRange={setSelectedRange}
          saveToLocalStorage={saveToLocalStorage}
          setDateToScroll={setDateToScroll}
          updateSelectedActivity={updateSelectedActivity}
          handleSelectActivity={handleSelectActivity}
        />
      </Paper>
    </DefaultBasePage>
  );
};

export default Activities;
