import React, { useEffect } from 'react';
import { parseISO } from 'date-fns';
import Icon from 'react-eva-icons';
import { FileIcon } from 'react-file-utils';
import { useChannelStateContext } from 'stream-chat-react';
import prettyBytes from 'pretty-bytes';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import {
  formatDate,
  InfiniteScrollList,
  OverflowText,
  Typography,
} from '@fdha/web-ui-library';
import {
  Box,
  Card,
  CardActionArea,
  useTheme,
  ListItem,
  Stack,
  Skeleton,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  ChannelAttachment,
  useGetChannelAttachmentsQuery,
} from '@fdha/graphql-api-patient';

const ChatMedia = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const { channel } = useChannelStateContext();

  const { data, fetchMore, networkStatus } = useGetChannelAttachmentsQuery({
    variables: { id: channel.id || '', limit: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const attachments = data?.channelAttachments.attachments || [];
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'channelAttachments',
      });
    };
  }, [client.cache]);

  const loadMore = () => {
    if (
      !attachments.length ||
      networkStatus === NetworkStatus.fetchMore ||
      !data?.channelAttachments.next
    ) {
      return;
    }

    fetchMore({
      variables: {
        next: data?.channelAttachments.next,
      },
    });
  };

  const getFileDateAndSize = (
    fileSize?: number | null,
    createdAt?: string | null
  ) => {
    const date = createdAt && formatDate(parseISO(createdAt));
    const size = fileSize && prettyBytes(fileSize);

    if (!fileSize) {
      return date;
    }

    return `${date} | ${size}`;
  };

  const renderEmptyState = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingTop={2}
      >
        <Typography
          i18nKey="chat:chatMedia.emptyState.message"
          color={theme.palette.text.disabled}
        >
          No media files.
        </Typography>
      </Box>
    );
  };

  const renderFileType = (item: ChannelAttachment) => {
    if (item.type === 'image') {
      return (
        <img
          src={item.url || ''}
          alt={item.title || ''}
          width="46px"
          height="46px"
        />
      );
    }
    return (
      <FileIcon mimeType={item.mimeType || ''} big size={46} version="2" />
    );
  };

  const renderListItem = (item: ChannelAttachment, index: number) => {
    return (
      <ListItem disablePadding key={index}>
        <Card sx={{ width: '100%', height: '79px' }}>
          <CardActionArea
            href={item.url || ''}
            target="_blank"
            download
            sx={{ height: '100%' }}
          >
            <Box
              paddingY={2}
              paddingRight={3}
              paddingLeft={2}
              display="flex"
              alignItems="center"
            >
              <Box>{renderFileType(item)}</Box>
              <Box
                justifyContent="flex-start"
                flex="1 1 auto"
                display="flex"
                paddingLeft={2}
                paddingRight={1}
                overflow="hidden"
                flexDirection="column"
              >
                <OverflowText label={item.title || ''}>
                  {item.title}
                </OverflowText>
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                  i18nKey={item.createdAt ? 'chat:chatMedia.date' : ''}
                  i18nParams={{ date: item.createdAt || '' }}
                >
                  {getFileDateAndSize(item.fileSize, item.createdAt)}
                </Typography>
              </Box>
              <Icon name="download-outline" fill={grey[800]} size="large" />
            </Box>
          </CardActionArea>
        </Card>
      </ListItem>
    );
  };

  const renderLoadingState = () => (
    <Stack spacing={2}>
      {[...Array(7)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={79} />
      ))}
    </Stack>
  );

  return (
    <Box paddingX={2} paddingY={2} overflow="auto" flexGrow={1}>
      {isLoading ? (
        renderLoadingState()
      ) : (
        <InfiniteScrollList
          items={attachments}
          renderItem={renderListItem}
          renderEmptyState={renderEmptyState}
          loadMore={loadMore}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
        />
      )}
    </Box>
  );
};

export default ChatMedia;
