import { useReactiveVar } from '@apollo/client';
import {
  ActivityDetailsCardV2,
  CircularProgressV2,
  Typography,
} from '@fdha/web-ui-library';
import {
  Goal,
  useUpdateGoalStepCompletedStateMutation,
} from '@fdha/graphql-api-patient';
import { Box, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import { selectedActivity, updateSelectedActivity } from '@states';
import React, { FC } from 'react';

interface GoalDetailsProps {
  isCreatedByCoach: boolean;
}

export const GoalDetails: FC<GoalDetailsProps> = ({ isCreatedByCoach }) => {
  const goal = useReactiveVar(selectedActivity) as Goal;

  const completed = goal.steps.filter((step) => step.completed);
  const completedRatio = completed.length / goal.steps.length;
  const progress = Math.round((completedRatio + Number.EPSILON) * 100);

  const [updateGoalStepCompletedState] =
    useUpdateGoalStepCompletedStateMutation();

  const handleChangeGoalStepState = async (
    stepId: string,
    completed: boolean
  ) => {
    await updateGoalStepCompletedState({
      variables: { completed: completed, stepId, instanceId: goal.instanceId! },
      update(cache, { data: updatedData }) {
        if (!updatedData) {
          return;
        }
        const { updateGoalStepCompletedState: updatedSteps } = updatedData;
        cache.modify({
          id: cache.identify(goal),
          fields: {
            steps() {
              return updatedSteps;
            },
          },
        });
        updateSelectedActivity({ steps: updatedSteps } as Goal);
      },
    });
  };

  return (
    <>
      <Stack spacing={1} direction="row" alignItems="center">
        <CircularProgressV2 value={progress} />
        <Typography variant="h5" sx={{ overflowWrap: 'anywhere' }}>
          {goal.title}
        </Typography>
      </Stack>
      <Box my={2}>
        <ActivityDetailsCardV2.CreatedBy isCreatedByCoach={isCreatedByCoach} />
        <Box display="flex" flexDirection="column" my={3}>
          {goal.steps.map((step) => (
            <FormControlLabel
              key={step.id}
              control={
                <Checkbox
                  checked={step.completed}
                  onChange={(_, value) =>
                    handleChangeGoalStepState(step.id, value)
                  }
                />
              }
              label={step.title}
            />
          ))}
        </Box>
        <ActivityDetailsCardV2.Details details={goal.description} />
      </Box>
      <Grid container>
        <ActivityDetailsCardV2.Date date={goal.time} />
        <ActivityDetailsCardV2.EndDate date={goal.ends_at} />
        <ActivityDetailsCardV2.Time time={goal.time} />
        <ActivityDetailsCardV2.Frequency
          frequency={goal.frequency}
          weekDays={goal.weekDays}
        />
        <ActivityDetailsCardV2.Reminder reminder={goal.reminder} />
      </Grid>
    </>
  );
};
