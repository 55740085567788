import { styled } from '@mui/material';
import { FONT_FAMILY } from '@fdha/web-ui-library';
import { CustomStyles } from 'stream-chat-react';

export const customStyles: CustomStyles = {
  '--main-font': FONT_FAMILY,
  '--second-font': FONT_FAMILY,
  '--grey-gainsboro': 'transparent',
  '--border-radius-md': '25px',
  '--font-weight-bold': '500',
  '--font-weight-semi-bold': '500',
};

export const StyledChatWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '.str-chat': {
    maxHeight: `calc(100vh - ${theme.spacing(10)} - 185px)`, // account for padding and border to prevent overflow
  },
  '.str-chat-channel-list.messaging': {
    position: 'static',
    left: 'auto',
    top: 'auto',
    zIndex: 'auto',
    minHeight: 'auto',
    overflowY: 'visible',
    boxShadow: 'none',
    flex: 1,
    width: '100%',
  },
  '.str-chat__message-simple-status': {
    zIndex: 0,
  },
}));
