import { Box, Skeleton } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { ChannelListMessengerProps } from 'stream-chat-react';
import { Typography } from '@fdha/web-ui-library';

import StyledChannelListWrapper from './styles';

const ChannelList: React.FC<PropsWithChildren<ChannelListMessengerProps>> = (
  props
) => {
  const { children, error, loading } = props;

  let content: React.ReactNode;
  if (error) {
    content = 'Error loading conversations, please try again momentarily.';
  } else if (loading) {
    return <Skeleton variant="rectangular" height={600} />;
  } else {
    content = children;
  }

  return (
    <StyledChannelListWrapper>
      <Box marginTop={2} marginBottom={0.5}>
        <Typography
          i18nKey="chat:list.title"
          variant="h5"
          sx={{ marginLeft: 2 }}
        >
          Conversations
        </Typography>
      </Box>
      <Box
        height="100%"
        overflow="auto"
        style={error || loading ? { padding: '10px 10px 0 16px' } : {}}
      >
        <Typography variant="body1" i18nKey="chat:channel.error">
          {content}
        </Typography>
      </Box>
    </StyledChannelListWrapper>
  );
};

export default React.memo(ChannelList);
