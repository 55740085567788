import React, { PropsWithChildren } from 'react';
import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  styled,
} from '@mui/material';

interface Props {
  onClick: () => void;
}

const CardActionArea = styled(MuiCardActionArea)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const Card = styled(MuiCard)(({ theme }) => ({
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  backgroundColor: theme.palette.background.default,
  width: '100%',
  height: '100%',
}));

const HomeCard: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
}) => {
  return (
    <Card>
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: 2,
          width: '100%',
          height: '100%',
        }}
        onClick={onClick}
      >
        {children}
      </CardActionArea>
    </Card>
  );
};

export default HomeCard;
