import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Typography } from '@fdha/web-ui-library';

export interface NotificationValue {
  enabled: boolean;
  type?: string;
}
interface Props {
  name: string;
  description: string;
  value: NotificationValue;
  onChange: (newValue: NotificationValue, oldValue: NotificationValue) => void;
}

const NotificationCard: React.FC<Props> = ({
  name,
  description,
  value,
  onChange,
}) => {
  const theme = useTheme();

  const type = value.type || '';
  const radioValue = value.enabled ? type : '';

  return (
    <Card variant="outlined">
      <CardContent sx={{ paddingRight: 3 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography
              i18nKey={`profile:notifications.input.${name.toLowerCase()}.label`}
              variant="body1"
            >
              {`Allow Notifications for ${name}`}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              i18nKey={`profile:notifications.input.${name.toLowerCase()}.description`}
            >
              {description}
            </Typography>
          </Box>
          <Switch
            checked={value.enabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, enabled: event.target.checked }, value)
            }
          />
        </Box>
      </CardContent>
      <CardActions sx={{ padding: 2, borderTop: `1px solid ${grey[300]}` }}>
        <FormControl>
          <RadioGroup
            row
            name="notificationType"
            value={radioValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, type: event.target.value }, value)
            }
          >
            <FormControlLabel
              value="sms"
              control={<Radio disabled={!value.enabled} size="small" />}
              label={
                <Typography
                  variant="inherit"
                  i18nKey="profile:notifications.input.options.sms"
                >
                  SMS
                </Typography>
              }
            />
            <FormControlLabel
              value="push"
              control={<Radio disabled={!value.enabled} size="small" />}
              label={
                <Typography
                  variant="inherit"
                  i18nKey="profile:notifications.input.options.push"
                >
                  Push
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </CardActions>
    </Card>
  );
};

export default NotificationCard;
