import {
  AppBarDrawerProps,
  AppBar as AppBarLib,
  MenuItem,
  useSnackbar,
} from '@fdha/web-ui-library';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetMainRoutes } from '@hooks';
import { signOut } from '@aws-amplify/auth';
import { handleInviteFriends } from '@utils';
import { displayProtectedImage } from '@fdha/web-auth';
import { privateRoutesWithoutHeader } from '@routes';
import React, { useCallback, useMemo } from 'react';
import { useFeatureFlag } from '@fdha/common-hooks';

interface AppBarProps {
  userName: string;
  logoRedirectUrl?: string;
  avatarRedirectUrl?: string;
  avatarPicture?: string;
}

const AppBar: React.FC<AppBarProps> = ({
  userName,
  logoRedirectUrl = '/',
  avatarRedirectUrl = '/profile',
  avatarPicture,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showSnackbarV2 } = useSnackbar();

  const { isFeatureEnabled } = useFeatureFlag();
  const showDocuments = isFeatureEnabled('show_documents');

  const { mainRoutes } = useGetMainRoutes();

  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      showSnackbarV2({
        message: 'Error to log out',
        severity: 'error',
        i18nKey: 'profile:snackbar.errorLogOut',
      });
    }
  }, [navigate, showSnackbarV2]);

  const handleDrawerItemClick = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const drawerOptions: AppBarDrawerProps['options'] = useMemo(() => {
    const homeRoute = [
      {
        value: '/',
        label: 'Home',
        onClick: handleDrawerItemClick,
        activeIcon: 'home',
        inactiveIcon: 'home-outline',
        'data-testid': 'HOME_PAGE',
        i18nKeyLabel: 'home:pageTitle',
      },
    ];

    return [
      ...homeRoute,
      ...mainRoutes.map(({ to, ...rest }) => ({
        ...rest,
        value: to,
        onClick: handleDrawerItemClick,
      })),
    ];
  }, [mainRoutes, handleDrawerItemClick]);

  const menuItems: MenuItem[] = useMemo(() => {
    return [
      {
        testId: 'YOUR_ACCOUNT_OPTION',
        label: 'Your Account',
        i18nKeyLabel: 'profile:yourAccount.title',
        handleClick: () => navigate('/profile/your-account'),
      },
      {
        testId: 'MEDICAL_INFO_OPTION',
        label: 'Medical Information',
        i18nKeyLabel: 'profile:medicalInformation.title',
        handleClick: () => navigate('/profile/medical-information'),
      },
      ...(showDocuments
        ? [
            {
              testId: 'DOCUMENTS_OPTION',
              label: 'Documents',
              i18nKeyLabel: 'profile:documents.title',
              handleClick: () => navigate('/profile/documents'),
            },
          ]
        : []),
      {
        testId: 'NOTIFICATIONS_OPTION',
        label: 'Notifications',
        i18nKeyLabel: 'profile:notifications.title',
        handleClick: () => navigate('/profile/notifications'),
      },
      {
        testId: 'HELP_OPTION',
        label: 'Help',
        i18nKeyLabel: 'profile:help.title',
        handleClick: () => navigate('/profile/help'),
      },
      {
        testId: 'INVITE_OPTION',
        label: 'Invite Friends',
        i18nKeyLabel: 'profile:inviteFriends.title',
        iconEnd: 'person-add-outline',
        handleClick: () => handleInviteFriends(showSnackbarV2),
      },
      {
        testId: 'LOG_OUT_OPTION',
        label: 'Log Out',
        i18nKeyLabel: 'profile:logout',
        iconEnd: 'log-out-outline',
        handleClick: handleLogout,
      },
    ];
  }, [handleLogout, navigate, showDocuments, showSnackbarV2]);

  const shouldHide = useMemo(() => {
    return (
      privateRoutesWithoutHeader.findIndex((route) => route === pathname) !== -1
    );
  }, [pathname]);

  const selectedDrawerOption = useMemo(() => {
    const regex = /\/([^/]+)/;
    const [firstPath] = pathname.match(regex) || [];

    return drawerOptions.find((opt) => firstPath === opt.value)?.value || '/';
  }, [drawerOptions, pathname]);

  return (
    <AppBarLib
      shouldHide={shouldHide}
      drawerProps={{
        buttonText: 'Menu',
        i18nKey: 'home:menu',
        options: drawerOptions,
        activeOption: selectedDrawerOption,
        logoUrl: '/images/Faeth_Logo_Small.png',
      }}
      logoProps={{
        url: '/images/Faeth_Logo_Small.png',
        onClick: () => navigate(logoRedirectUrl),
      }}
      userMenuProps={{
        options: menuItems,
        name: userName,
        picture: avatarPicture,
        fetchPicture: displayProtectedImage,
        onAvatarClick: () => navigate(avatarRedirectUrl),
      }}
    />
  );
};

export default AppBar;
