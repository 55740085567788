import { Paper, styled } from '@mui/material';

const StyledChannelListWrapper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'hidden',
  padding: 'unset',
}));

export default StyledChannelListWrapper;
