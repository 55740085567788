import { AccordionV2 } from '@fdha/web-ui-library';
import { Box, Skeleton, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import CategoryCard from './CategoryCard';

interface ItemProps {
  title: string;
  type: 'category' | 'question';
  content?: React.ReactNode;
  handleClick?: () => void;
}

const Item: React.FC<ItemProps> = ({ title, type, content, handleClick }) => {
  return (
    <Box pb="12px">
      {type === 'category' ? (
        <CategoryCard
          title={title}
          handleClick={() => handleClick && handleClick()}
        />
      ) : (
        <AccordionV2 title={title}>
          <>{content}</>
        </AccordionV2>
      )}
    </Box>
  );
};

const Loading = () => {
  return (
    <Stack spacing="12px">
      {[...Array(7)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={76} width={520} />
      ))}
    </Stack>
  );
};

const List: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default Object.assign(List, { Item, Loading });
