import React from 'react';
import { useAnalytics } from '@fdha/common-hooks';
import { RecipeType } from '@fdha/graphql-api-patient';
import { useNavigate } from 'react-router';

import LearningCard from './LearningCard';

interface RecipeCardProps {
  id: string;
  title: string;
  type?: RecipeType | null;
  video?: string | null;
  coverImage: string;
}

const RecipeCard: React.FC<RecipeCardProps> = ({
  id,
  title,
  type,
  video,
  coverImage,
}) => {
  const navigate = useNavigate();
  const { analyticsClient } = useAnalytics();

  const handleClick = () => {
    navigate(`/learning/recipe?id=${id}`);
    analyticsClient?.track('Recipe Opened', {
      id: id,
      name: title,
    });
  };

  return (
    <LearningCard
      type="recipe"
      title={title}
      subtitle={type}
      showVideoIcon={!!video}
      coverImage={coverImage}
      onClick={handleClick}
      i18nKey={`learning:recipe.type.${type?.toLowerCase()}`}
    />
  );
};

export default RecipeCard;
