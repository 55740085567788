import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { useListBhbAssaysResultsQuery } from '@fdha/graphql-api-patient';
import { Graph, Button, formatUTCDate, Typography } from '@fdha/web-ui-library';
import { DataEmptyState } from '@components';
import { getBHBTooltipColor } from '@utils';
import { useTranslation } from '@fdha/common-hooks';

const HomeBHBTrialData = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const { data, loading } = useListBhbAssaysResultsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const assaysData = data?.getAssays.edges.map((edge) => edge.node);

  const handleDetails = () => {
    navigate('/bhb-trial-data');
  };

  const limits = useMemo(() => {
    return {
      upper: assaysData?.[0]?.upperLimit ?? 0,
      bottom: assaysData?.[0]?.bottomLimit ?? 0,
    };
  }, [assaysData]);

  const graphData = useMemo(
    () =>
      assaysData?.map((item) => {
        const date = formatUTCDate(item.eventDate, 'formalMonthDay') ?? '';
        const value = item.value > 6.5 ? '6.5' : item.value.toFixed(1);

        return {
          x: translate('dataVisualization:graphDate', date, {
            date: item.eventDate,
          }),
          y: value,
          color: getBHBTooltipColor(item.value, limits),
        };
      }) || [],
    [assaysData, limits, translate]
  );

  const showEmptyState =
    !loading && assaysData?.length && assaysData.length <= 1;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {showEmptyState ? (
        <DataEmptyState />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            showSkeleton={loading}
            i18nKey="dataVisualization:bhb.goal"
            i18nParams={{
              bottomLimit: limits?.bottom.toFixed(1),
              upperLimit: limits?.upper.toFixed(1),
            }}
          >
            {`The goal range for your <strong>BHB</strong> level is between{' '}
            <strong>{{ bottomLimit }} - {{ upperLimit }} mmol/L</strong>`}
          </Typography>
          <Box maxWidth="520px" mt={2} mb={3}>
            <Graph
              data={graphData}
              limits={limits}
              showSkeleton={loading}
              showCard
            />
          </Box>
          <Button
            onClick={handleDetails}
            endEvaIcon={{ name: 'arrow-forward-outline' }}
            i18nKey="home:trial.button"
          >
            See more details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HomeBHBTrialData;
