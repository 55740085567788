import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useGetGoogleOAuthUrlQuery,
  useSetGoogleApiCredentialsMutation,
} from '@fdha/graphql-api-patient';
import { Loader } from '@fdha/web-ui-library';
import { CONTENT_HEIGHT } from '@utils';

const SignInGoogleOAuth = () => {
  const { data: googleOAuthUrl } = useGetGoogleOAuthUrlQuery();
  const [setGoogleApiCredentials] = useSetGoogleApiCredentialsMutation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const authCode = query.get('code');

    if (!authCode) {
      navigate(googleOAuthUrl?.googleOAuthUrl.url || '', {});
      return;
    }

    const setGoogleCredentials = async () => {
      try {
        await setGoogleApiCredentials({
          variables: { authCode: authCode || '' },
        });
        updateSuccedStatusOnParent(true);
      } catch {
        updateSuccedStatusOnParent(false);
      }
    };

    setGoogleCredentials();
  }, [
    googleOAuthUrl?.googleOAuthUrl.url,
    navigate,
    setGoogleApiCredentials,
    location.search,
  ]);

  const updateSuccedStatusOnParent = (isGoogleAuthSucceeded: boolean) => {
    if (!window?.opener?.history) {
      return;
    }

    window.opener.history.pushState(
      'Google OAuth Param',
      '',
      `?google_oauth_success=${isGoogleAuthSucceeded ? 1 : 0}`
    );

    window.opener.dispatchEvent(new Event('locationchange'));
    window.close();
  };

  return (
    <Box height={CONTENT_HEIGHT}>
      <Loader />
    </Box>
  );
};

export default SignInGoogleOAuth;
