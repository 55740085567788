import { TextFieldV2, Typography } from '@fdha/web-ui-library';
import { useFormikContext } from 'formik';
import React from 'react';

import { CreateAccountResponse } from './createAccountScreens';

const LastName = () => {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<CreateAccountResponse>();

  const error = touched.lastName ? errors.lastName : null;

  return (
    <>
      <Typography
        i18nKey="login:createAccount.step3to7.input.lastName.label"
        variant="h5"
      >
        What is your <b>last name</b>?
      </Typography>
      <TextFieldV2
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        helperText={error}
      />
    </>
  );
};

export default LastName;
