import { Button, theme } from '@fdha/web-ui-library';
import { Stack } from '@mui/material';

const ButtonsBugAndFeedback = () => {
  return (
    <Stack direction="row" spacing="12px" mb={3}>
      <Button
        startEvaIcon={{
          name: 'alert-triangle-outline',
          fill: theme.palette.primary.main,
          size: 'large',
        }}
        onClick={() =>
          window.open(
            'mailto:bugreport@faeththerapeutics.com?subject=Faeth%20Patient%20App%20Bug%20Report'
          )
        }
        i18nKey="profile:help.actions.reportBug"
      >
        Report a bug
      </Button>
      <Button
        startEvaIcon={{
          name: 'volume-up-outline',
          fill: theme.palette.primary.main,
          size: 'large',
        }}
        onClick={() =>
          window.open(
            'mailto:appfeedback@faeththerapeutics.com?subject=Faeth%20Patient%20App%20Feedback'
          )
        }
        i18nKey="profile:help.actions.shareFeedback"
      >
        Share feedback
      </Button>
    </Stack>
  );
};

export default ButtonsBugAndFeedback;
