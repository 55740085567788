import React, { FC } from 'react';
import { EmptyStateCard, Icon, Typography } from '@fdha/web-ui-library';
import { Box, Link, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '@fdha/common-hooks';

interface DataEmptyStateProps {
  type?: 'full' | 'partial';
}

const DataEmptyState: FC<DataEmptyStateProps> = ({ type = 'full' }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isFeatureEnabled } = useFeatureFlag();
  const showIntercom = isFeatureEnabled('show_intercom');
  const title =
    type === 'full'
      ? {
          content: 'There is no data available at the moment.',
          i18nKey: 'home:trial.emptyStateFull.title',
        }
      : {
          content: 'This data couldn’t be shown.',
          i18nKey: 'home:trial.emptyStatePartial.title',
        };
  const text =
    type === 'full'
      ? {
          content:
            'Multiple lab results are needed to display this information. If you have already completed 4 or more lab tests, please <contactLink>contact your coach</contactLink>.',
          i18nKey: 'home:trial.emptyStateFull.description',
        }
      : {
          content:
            'We were not able to retrieve this information at the moment, please check again later. If the data continues to be unavailable, please <contactLink>contact your coach</contactLink>.',
          i18nKey: 'home:trial.emptyStatePartial.description',
        };

  const getContactCoachLink = () => (
    <Link
      onClick={() => {
        showIntercom ? Intercom('show') : navigate('/chat');
      }}
      sx={{ cursor: 'pointer' }}
    />
  );

  const message = (
    <Typography
      variant="body2"
      i18nKey={text.i18nKey}
      i18nComponents={{
        contactLink: getContactCoachLink(),
      }}
    >
      {text.content}
    </Typography>
  );

  const icon = (
    <Box
      width="60px"
      height="60px"
      borderRadius="40px"
      bgcolor={theme.palette.background.default}
      padding="15px"
      marginBottom={1}
    >
      <Icon name="eye-off-outline" size="xlarge" fill={grey[600]} />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={5}
      maxWidth="540px"
    >
      <EmptyStateCard
        iconType="custom"
        customIcon={icon}
        title={title.content}
        i18nKeyTitle={title.i18nKey}
        message={message}
      />
    </Box>
  );
};

export default DataEmptyState;
