import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

//https:github.com/reach/router/issues/242#issuecomment-491537468
const ScrollToTop = () => {
  const location = useLocation();
  React.useEffect(() => window.scrollTo(0, 0), [location?.pathname]);
  return <Outlet />;
};

export default ScrollToTop;
