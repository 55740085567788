import { AutocompleteV2, Typography } from '@fdha/web-ui-library';
import { AutocompleteQuestionProps } from '@models';
import { Link } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

interface AutocompleteViewProps {
  question: AutocompleteQuestionProps;
  handleChange: (value: any, name?: string) => void;
  handleNext: () => void;
}

interface Context {
  [key: string]: string;
}

export const AutocompleteView: FC<AutocompleteViewProps> = ({
  question,
  handleChange,
  handleNext,
}) => {
  const { values, errors, touched } = useFormikContext<Context>();

  const key = question.id;
  const value =
    question.options.find((option) => option?.id === values[key]) || null;
  const error = touched[key] ? errors[key] : '';

  return (
    <>
      <AutocompleteV2
        options={question.options}
        value={value}
        onChange={(_event, value) => handleChange(value?.id)}
        error={!!error}
        helperText={error}
      />
      <Link
        onClick={() => {
          handleChange('');
          handleNext();
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Typography i18nKey={question?.link?.i18nKey}>
          {question?.link.label}
        </Typography>
      </Link>
    </>
  );
};
