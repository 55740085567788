import { Skeleton, Stack } from '@mui/material';

export const LoadingFeed = () => {
  return (
    <Stack spacing={3}>
      <Skeleton variant="rectangular" height={120} width="100%" />
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={253} width="100%" />
      ))}
    </Stack>
  );
};

export const LoadingNotifications = () => {
  return <Skeleton variant="rectangular" height={305} width="100%" />;
};
