import { Box, useTheme, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { Attachment, logChatPromiseExecution, UserResponse } from 'stream-chat';
import {
  MessageInput,
  MessageList,
  StreamMessage,
  useChannelActionContext,
  Window,
} from 'stream-chat-react';
import { ChatScreenType, setSelectedChatScreenType } from '@states';
import { useAnalytics } from '@fdha/common-hooks';
import { Typography } from '@fdha/web-ui-library';

import ChannelHeader from './header/ChannelHeader';

export const GiphyContext = React.createContext(
  {} as {
    giphyState: boolean;
    setGiphyState: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

const messageActions = ['delete', 'edit', 'quote', 'react'];

interface ChannelInnerProps {
  showMessageInput: boolean;
}

const ChannelInner: React.FC<ChannelInnerProps> = ({ showMessageInput }) => {
  const { sendMessage } = useChannelActionContext();
  const { analyticsClient } = useAnalytics();

  const theme = useTheme();

  const [giphyState, setGiphyState] = useState(false);
  const giphyContextValue = { giphyState, setGiphyState };

  const overrideSubmitHandler = (message: {
    attachments?: Attachment[];
    mentioned_users?: UserResponse[];
    text?: string;
    parent?: StreamMessage;
  }) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '');
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };
      analyticsClient?.track('Chat Message Sent');
      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    }

    setGiphyState(false);
  };

  const handleBack = () => {
    setSelectedChatScreenType(ChatScreenType.ChannelList);
  };

  return (
    <GiphyContext.Provider value={giphyContextValue}>
      <Window>
        <ChannelHeader handleBack={handleBack} />
        <MessageList messageActions={messageActions} />
        <Divider sx={{ borderColor: grey[300] }} />
        {showMessageInput ? (
          <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />
        ) : (
          <Box textAlign="center" padding={1.5}>
            <Typography
              fontSize="16px"
              color={theme.palette.text.secondary}
              i18nKey="chat:channel.cantSendMessage"
            >
              You can't send messages in this channel
            </Typography>
          </Box>
        )}
      </Window>
    </GiphyContext.Provider>
  );
};

export default ChannelInner;
