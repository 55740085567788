import React from 'react';
import { Card, CardActionArea, useTheme, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  SurveyFrequency,
  SurveyStatus,
  WeekDay,
} from '@fdha/graphql-api-patient';
import { getRecurrenceText } from '@utils';
import { Typography } from '@fdha/web-ui-library';
import { useTranslation } from '@fdha/common-hooks';

interface SurveyCardProps {
  name: string;
  i18nNameKey?: string;
  status: SurveyStatus;
  frequency?: SurveyFrequency;
  dueAt?: string;
  weekDays?: WeekDay[];
  handleClick: () => void;
}

const SurveyCard: React.FC<SurveyCardProps> = ({
  name,
  i18nNameKey,
  status,
  frequency,
  dueAt,
  weekDays,
  handleClick,
}) => {
  const theme = useTheme();

  const { translate } = useTranslation();

  const isOpen = status === SurveyStatus.Open;

  const getStatusColor = () =>
    isOpen ? theme.palette.info.main : theme.palette.error.main;

  const recurrenceOpts = {
    frequency,
    weekDays,
    dueAt,
  };

  const recurrenceData = getRecurrenceText(recurrenceOpts, translate);
  const recurrenceI18nParams = {
    ...recurrenceData.i18nParams,
  };

  return (
    <Card
      sx={{
        border: `0.5px solid ${grey[700]}`,
        boxShadow: theme.shadows[2],
      }}
    >
      <CardActionArea
        onClick={handleClick}
        data-testid={`SURVEY_CARD_${name}`}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          padding: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            textOverflow="ellipsis"
            i18nKey={i18nNameKey}
          >
            {name}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              width={8}
              height={8}
              bgcolor={getStatusColor()}
              borderRadius={50}
            />
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              marginLeft={1}
              color={getStatusColor()}
              i18nKey={`surveys:card.status.${status}`}
            >
              {status.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="subtitle1"
          i18nKey={recurrenceData.i18nKey}
          i18nParams={recurrenceI18nParams}
        >
          {recurrenceData.formattedString}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default SurveyCard;
