import {
  getCancersTypesWithI18n,
  getCaregiversWithI18n,
  getCurrentTreatmentsWithI18n,
  states,
} from '@fdha/web-ui-library';
import {
  LogicAction,
  LogicCondition,
  SurveyQuestionType,
} from '@fdha/graphql-api-patient';
import { DateOption, QuestionType, QuestionProps } from '@models';

export const getWelcomeSurveyQuestions = (): QuestionProps[] => [
  {
    id: 'cancerDiagnosis',
    title: 'What’s your cancer diagnosis?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.cancerDiagnosis.title',
    type: QuestionType.Autocomplete,
    required: true,
    link: {
      label: 'I can’t find my cancer type in the list',
      i18nKey: 'surveys:welcomeSurvey.questions.cancerDiagnosis.linkLabel',
      route: 'Additional Information',
    },
    options: getCancersTypesWithI18n(),
  },
  {
    id: 'additionalInformation',
    title:
      'Is there any additional information that you would like to share with us about your diagnosis?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.additionalInformation.title',
    subtitle: 'The more you share, the more helpful we can be.',
    i18nKeySubtitle:
      'surveys:welcomeSurvey.questions.additionalInformation.subtitle',
    type: QuestionType.OpenText,
    required: false,
    helperText: 'E.g. mutational status, stage, current treatment',
    i18nKeyHelperText:
      'surveys:welcomeSurvey.questions.additionalInformation.helperText',
  },
  {
    id: 'diagnosisDate',
    title: 'When were you diagnosed?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.diagnosisDate.title',
    placeholder: 'mm/yyyy',
    mask: 'monthYear',
    type: QuestionType.DateOption,
    required: true,
    options: [
      {
        label: 'I remember the date',
        i18nKeyLabel:
          'surveys:welcomeSurvey.questions.diagnosisDate.options.remember',
        value: DateOption.Remember,
      },
      {
        label: "I don't remember the date",
        i18nKeyLabel:
          'surveys:welcomeSurvey.questions.diagnosisDate.options.dontRemember',
        value: DateOption.DontRemember,
      },
    ],
    initialValue: {
      date: '',
      radioOption: '',
    },
  },
  {
    id: 'currentTreatment',
    title: 'What’s your current treatment?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.currentTreatment.title',
    type: QuestionType.MultipleChoice,
    required: true,
    options: getCurrentTreatmentsWithI18n(),
  },
  {
    id: 'oncologistName',
    title: 'What’s the name of your oncologist?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.oncologistName.title',
    type: QuestionType.OpenText,
    required: false,
  },
  {
    id: 'facilityName',
    title: 'What’s the name of the facility where you are being treated?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.facilityName.title',
    type: QuestionType.OpenText,
    required: false,
  },
  {
    id: 'facility',
    title: 'What city is it in?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.facility.title',
    type: QuestionType.TextSelect,
    required: false,
    text: {
      label: 'City',
      i18nKeyLabel: 'surveys:welcomeSurvey.questions.facility.textLabel',
    },
    select: {
      label: 'State',
      i18nKeyLabel: 'surveys:welcomeSurvey.questions.facility.selectLabel',
      options: states,
    },
    initialValue: {
      text: '',
      select: '',
    },
  },
  {
    id: 'primaryCaregiver',
    title: 'Who is your primary caregiver?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.primaryCaregiver.title',
    showHipaaSubtitle: true,
    type: QuestionType.SingleChoice,
    required: false,
    options: getCaregiversWithI18n(),
    otherOption: {
      label: 'Other',
    },
  },
  {
    id: 'caregiverName',
    title: 'What is their name?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.caregiverName.title',
    showHipaaSubtitle: true,
    type: QuestionType.OpenText,
    required: false,
  },
  {
    id: 'isPrimaryCheerleader',
    title: 'Is this person also your primary cheerleader?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.isPrimaryCheerleader.title',
    showHipaaSubtitle: true,
    type: QuestionType.Binary,
    options: [
      {
        label: 'Yes',
        i18nKey:
          'surveys:welcomeSurvey.questions.isPrimaryCheerleader.options.yes',
        value: 'yes',
      },
      {
        label: 'No',
        i18nKey:
          'surveys:welcomeSurvey.questions.isPrimaryCheerleader.options.no',
        value: 'no',
      },
    ],
    required: false,
  },
  {
    id: 'primaryCheerleader',
    title: 'Who is your primary cheerleader?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.primaryCheerleader.title',
    showHipaaSubtitle: true,
    type: QuestionType.SingleChoice,
    required: false,
    options: getCaregiversWithI18n(),
    otherOption: {
      label: 'Other',
    },
    logic: {
      conditionQuestion: {
        value: 'isPrimaryCheerleader',
        type: SurveyQuestionType.Binary,
      },
      condition: LogicCondition.EqualTo,
      conditionAnswer: 'no',
      action: LogicAction.Show,
    },
  },
  {
    id: 'cheerleaderName',
    title: 'What is their name?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.cheerleaderName.title',
    showHipaaSubtitle: true,
    type: QuestionType.OpenText,
    required: false,
    logic: {
      conditionQuestion: {
        value: 'isPrimaryCheerleader',
        type: SurveyQuestionType.Binary,
      },
      condition: LogicCondition.EqualTo,
      conditionAnswer: 'no',
      action: LogicAction.Show,
    },
  },
  {
    id: 'motivation',
    title: 'What is your motivation for joining Faeth?',
    i18nKeyTitle: 'surveys:welcomeSurvey.questions.motivation.title',
    subtitle:
      'Maybe you’re here because you want to live longer for your kids, or you want to contribute to cancer research to help future generations, or maybe you are here as a caregiver to support a loved one.',
    i18nKeySubtitle: 'surveys:welcomeSurvey.questions.motivation.subtitle',
    type: QuestionType.OpenText,
    required: false,
  },
];
