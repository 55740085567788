import React, { FC } from 'react';
import { Box, CardContent, Stack, Theme, useMediaQuery } from '@mui/material';
import { AssayResult } from '@fdha/graphql-api-patient';
import { DataVariation, Icon, Typography, theme } from '@fdha/web-ui-library';
import { grey } from '@mui/material/colors';
import { HomeCard } from '@components';

interface AminoAcidItemProps {
  item: AssayResult;
  handleClick: () => void;
}

const AminoAcidItem: FC<AminoAcidItemProps> = ({ item, handleClick }) => {
  const isBelowLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  const firstDash = 100 / 3;
  const firstDashPercentage = `${firstDash}%`;
  const secondDash = firstDash * 2;
  const secondDashPercentage = `${secondDash}%`;

  const { baseline, value } = item;
  const target = item.baseline - (item.baseline * item.target) / 100;

  const renderLabel = () => {
    const getProps = () => {
      const outOfTarget = {
        text: 'Out of target',
        textColor: '#540000',
        color: '#FF9683',
        i18nKey: 'dataVisualization:aa.outOfTarget',
      };

      if (value <= target) {
        return {
          text: 'On target',
          textColor: '#004933',
          color: '#C2F5A1',
          left: `${firstDash / 2}%`,
          i18nKey: 'dataVisualization:aa.onTarget',
        };
      } else if (value <= baseline) {
        return {
          ...outOfTarget,
          left: `${firstDash + firstDash / 2}%`,
        };
      } else {
        return {
          ...outOfTarget,
          left: `${secondDash + firstDash / 2}%`,
        };
      }
    };

    const { text, textColor, color, left, i18nKey } = getProps();

    return (
      <Box
        py={0.5}
        px={1}
        bottom={theme.spacing(2)}
        left={left}
        borderRadius="5px"
        position="absolute"
        sx={{ backgroundColor: color, transform: 'translate(-50%)' }}
      >
        <Typography
          color={textColor}
          variant="caption"
          fontWeight="bold"
          noWrap
          i18nKey={i18nKey}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const renderDash = (
    offset: string,
    label: string,
    i18nKey?: string,
    i18nParams?: { [key: string]: string }
  ) => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="absolute"
      left={offset}
      sx={{ transform: 'translate(-50%, -7.5%)' }}
    >
      <Box
        width="2px"
        height="13px"
        sx={{
          backgroundColor: grey[900],
        }}
      />
      <Icon name="arrow-up" fill={grey[900]} size="large" />
      <Typography
        variant="caption"
        textAlign="center"
        color={theme.palette.text.secondary}
        i18nKey={i18nKey}
        i18nParams={i18nParams}
      >
        {label}
      </Typography>
    </Box>
  );

  const renderDot = () => {
    let position;

    if (value <= target) {
      position = (firstDash * value) / target;
    } else if (value < baseline) {
      position =
        firstDash + ((value - target) * firstDash) / (baseline - target);
    } else {
      position = Math.min(
        100,
        secondDash + ((value - baseline) * firstDash) / target
      );
    }

    const color = value <= target ? '#095400' : '#540000';

    return (
      <Box
        width="13px"
        height="13px"
        borderRadius="7.5px"
        position="absolute"
        marginLeft={`${position}%`}
        sx={{
          backgroundColor: color,
          transform: 'translate(-50%, -30%)',
        }}
      />
    );
  };

  return (
    <HomeCard onClick={handleClick}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: 0,
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center" flexWrap="wrap">
          <Typography variant="h2">{item.value.toFixed(2)}</Typography>
          <Stack
            spacing={0.5}
            alignItems="center"
            direction={isBelowLg ? 'row' : 'column'}
          >
            <DataVariation variation={item.variation} target={item.target} />
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              i18nKey="dataVisualization:aa.ofBaseline"
            >
              of baseline
            </Typography>
          </Stack>
        </Stack>
        <Box
          width="100%"
          height="5px"
          borderRadius="5px"
          position="relative"
          mt={6}
          mb={8}
          sx={{
            background: `linear-gradient(to right, #3CA130 0, #3CA130 ${firstDashPercentage}, #B72D3B ${firstDashPercentage}, #B72D3B ${secondDashPercentage})`,
          }}
        >
          {renderLabel()}
          {renderDash(
            firstDashPercentage,
            'Target ({{ number }})',
            'dataVisualization:aa.targetNumber',
            { number: target.toFixed(2) }
          )}
          {renderDash(
            secondDashPercentage,
            'Baseline ({{ number }})',
            'dataVisualization:aa.baselineNumber',
            { number: baseline.toFixed(2) }
          )}
          {renderDot()}
        </Box>
        <Typography
          variant="h5"
          mb={1}
          i18nKey={`dataVisualization:aa.aminoAcids.${item.name.toLowerCase()}`}
        >
          {item.name}
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.text.secondary}
          i18nKey={`dataVisualization:aa.targetDescription.home.${item.name.toLocaleLowerCase()}`}
          i18nParams={{ percentage: item.target }}
        >
          {`The target is to get your <strong>${item.name.toLocaleLowerCase()}</strong> level
          to at least <strong>{{ percentage }}%</strong> below your baseline levels.`}
        </Typography>
      </CardContent>
    </HomeCard>
  );
};

export default AminoAcidItem;
