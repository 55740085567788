import { DefaultBasePage } from '@components';
import { RTEContentRender } from '@fdha/web-ui-library';
import {
  Faq,
  useGetFaqCategoriesQuery,
  useGetFaQsQuery,
} from '@fdha/graphql-api-patient';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import ButtonsBugAndFeedback from './ButtonsBugAndFeedback';
import List from './List';

const FaqCategory = () => {
  const { id } = useParams();
  const { data: faqData, loading } = useGetFaQsQuery({
    variables: { categoryId: id },
  });

  const { data: faqCategoriesData, loading: loadingCategories } =
    useGetFaqCategoriesQuery();

  const faqs = faqData?.faqs || [];
  const showFaqLoader = loading && !faqs.length;

  const faqCategories = faqCategoriesData?.faqCategories || [];
  const showCategoriesLoader = loadingCategories && !faqCategories.length;

  const category = faqCategories.find((faqCategory) => {
    return faqCategory.id === id;
  });

  const renderFAQListItem = (listData: { item: Faq }) => {
    const { item } = listData;
    const content = item.answer && JSON.parse(item.answer);

    return (
      <List.Item
        key={item.id}
        title={item.question}
        content={<RTEContentRender richTextDocument={content} />}
        type="question"
      />
    );
  };

  return (
    <DefaultBasePage
      currentRouteName={{ text: category?.name }}
      isLoading={showFaqLoader || showCategoriesLoader}
      title={category?.name}
      contentSize="small"
    >
      <ButtonsBugAndFeedback />
      <Box>
        {showFaqLoader ? (
          <List.Loading />
        ) : (
          faqs.map((faq: Faq) => renderFAQListItem({ item: faq }))
        )}
      </Box>
    </DefaultBasePage>
  );
};

export default FaqCategory;
