import React from 'react';
import {
  useChat,
  useFeatureFlag,
  useWebSocket,
  WebSocketMessage,
} from '@fdha/common-hooks';
import { createContext, useCallback, useContext, useEffect } from 'react';

import { useCommunityNotifications } from './useCommunityNotifications';
import { useLearningNotifications } from './useLearningNotifications';

export type NotificationType = 'learning' | 'community';

interface Count {
  chat: number;
  learning: number;
  community: number;
}
interface NotificationContext {
  count: Count;
  clearNotification: (notificationType: NotificationType) => void;
  isLoading: boolean;
}

const Context = createContext<NotificationContext>({
  count: { chat: 0, learning: 0, community: 0 },
  clearNotification: () => {},
  isLoading: false,
});

export const NotificationsProvider = ({ ...props }) => {
  const { isFeatureEnabled, isLoading: loadingFeatureFlags } = useFeatureFlag();
  const showLearningContent = isFeatureEnabled('show_learning_content');
  const showCommunity = isFeatureEnabled('show_community_web_patient');

  const {
    count: learningCount,
    isLoading: loadingLearning,
    clear: clearLearning,
    refetch: refetchLearning,
  } = useLearningNotifications(!showLearningContent);

  const {
    count: communityCount,
    isLoading: loadingCommunity,
    clear: clearCommunity,
    refetch: refetchCommunity,
  } = useCommunityNotifications();

  const { unreadCount: chatCount, isLoading: loadingChat } = useChat();

  const { addListener, removeListener } = useWebSocket();

  const count = {
    chat: chatCount,
    learning: learningCount,
    community: communityCount,
  };

  const isLoading =
    loadingChat || loadingFeatureFlags || loadingLearning || loadingCommunity;

  const invalidateCache = useCallback(() => {
    refetchCommunity();
    refetchLearning();
  }, [refetchCommunity, refetchLearning]);

  const handleClearNotification = useCallback(
    (type: NotificationType) => {
      switch (type) {
        case 'learning':
          if (showLearningContent) {
            clearLearning();
          }
          break;
        case 'community':
          if (showCommunity) {
            clearCommunity();
          }
      }
    },
    [clearLearning, showLearningContent, clearCommunity, showCommunity]
  );

  const updateNotification = useCallback(
    (message: WebSocketMessage) => {
      if (message.eventType === 'invalidate') {
        invalidateCache();
      }

      if (message.eventType === 'sync') {
        if (message.dataType === 'learning') {
          refetchLearning();
        }
        if (message.dataType === 'community') {
          refetchCommunity();
        }
      }
    },
    [invalidateCache, refetchLearning, refetchCommunity]
  );

  useEffect(() => {
    addListener(updateNotification);

    return () => {
      removeListener(updateNotification);
    };
  }, [addListener, removeListener, updateNotification]);

  return (
    <Context.Provider
      value={{
        count,
        isLoading,
        clearNotification: handleClearNotification,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useNotifications = () => {
  const { count, isLoading, clearNotification } = useContext(Context);

  return { count, isLoading, clearNotification };
};
